import { httpJson } from '@/utils/adminRequest'
/**
 * 
 * @returns 销售的个人所有成绩
 */
export const getMyCountAll = () => {
  return httpJson.get('/myCount/all')
}
export const getPanels = () => {
  return httpJson.get('/docPanel/body/1')
}
export const getDocList = (panelId) => {
  return httpJson.get('/doc/list/' + panelId + '/40');
}
export const getDocInfo = (docId) => {
  return httpJson.get('/doc/info/' + docId);
}


