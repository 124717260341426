import { render, staticRenderFns } from "./CustomerSet.vue?vue&type=template&id=322b3cc9&scoped=true&"
import script from "./CustomerSet.vue?vue&type=script&lang=js&"
export * from "./CustomerSet.vue?vue&type=script&lang=js&"
import style0 from "./CustomerSet.vue?vue&type=style&index=0&id=322b3cc9&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "322b3cc9",
  null
  
)

export default component.exports