<!--其它费用记录-->
<template>
  <div>
    <el-table :data="this.inArray" style="width: 100%" height="240" size="mini">
      <el-table-column prop="inRmbDate" label="收款日期" width="100">
      </el-table-column>
      <el-table-column prop="inRmbName" label="收款名称" width="100" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="inRmb" label="收款金额" align="right" width="140">
      </el-table-column>
      <el-table-column prop="note" label="备注" align="center">
      </el-table-column>
      <el-table-column label="操作" width="100" v-if="this.allowOperate">
        <template slot="header">
          <el-button type="success" icon="el-icon-plus" size="mini" @click="newContractIn()">收款</el-button>
        </template>
        <template slot-scope="scope">
          <el-button @click="modifyContractIn(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="delContractIn(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-dialogDrag class="customFileListCss" :title="contractInDialogParam.title" :visible.sync="contractInDialogParam.visible"
      :width="contractInDialogParam.width" :top="contractInDialogParam.top" :close-on-click-modal='false' :append-to-body='true'>
      <el-form ref="contractInFormRef" :model="contractInData" :rules="contractInDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="收款名称" prop="inRmbName">
          <el-input v-model="contractInData.inRmbName" placeholder="请输入收款名称"></el-input>
        </el-form-item>
        <el-row style="padding:0px;">
          <el-col :span="12">
            <el-form-item label="收款金额" prop="inRmb">
              <el-input-number v-model="contractInData.inRmb" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00"
                placeholder="请输入收款金额" style="width:100%;"></el-input-number>
            </el-form-item>
            <el-form-item label="收款日期" prop="inRmbDate">
              <el-date-picker v-model="contractInData.inRmbDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择到款时间"
                style="width:100%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12"><span style="margin-left:20px;line-height:36px ;">{{ contractInData.inRmb|numFilter }}</span>
          </el-col>
        </el-row>
        <el-form-item label="备注" prop="note">
          <el-input type="textarea" :rows="2" resize="none" placeholder="请输入备注" v-model="contractInData.note">
          </el-input>
        </el-form-item>
        <el-form-item label="短信" prop="sendMsg">
          <el-checkbox v-model="contractInData.sendMsg">同时发送短信给销售</el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="contractInDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="contractInDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script> 
import {
  doContractInNew, doContractInModify, doContractInDelete, getInByContractId,
} from '@/api/utils/contract/ContractIn'; //数据源,如果没有使用,为了美观请删除
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  props: ["contractData", "contractId", "allowOperate"],
  data () {
    return {
      //-----------------------认证合同收款---------------------------
      contractInDialogParam: //收款弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        action: '',
        width: '30%',
        top: '5vh',
      },

      contractInDialogRules:
      {
        inRmbName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        inRmbCondition: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signRmbDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
      },
      contractInData: {
        proId: 0,
        projectName: '',
        cmpId: 0,
        cmpName: '',
        contractId: 0,
        contractCode: '',
        inRmbName: '',
        inRmb: 0.00,
        inRmbDate: '',
        createId: 0,
        createMan: '',
        createTime: '',
        note: '',
        sendMsg: false,
      },
      contractInRawData: {},
      inArray: []
    };
  },
  methods: {
    //-----------------------认证合同的销售收款功能---------------------------
    newContractIn () { //新建收款
      this.contractInData = JSON.parse(JSON.stringify(this.contractInRawData)); //深拷贝一份原始数据到contractInData,就相当于重置表单了
      this.contractInData.cmpId = JSON.stringify(this.contractData.cmpId);
      this.contractInData.cmpName = JSON.parse(JSON.stringify(this.contractData.cmpName));
      this.contractInData.createId = JSON.stringify(this.contractData.createId);
      this.contractInData.createMan = JSON.parse(JSON.stringify(this.contractData.createMan));
      this.contractInData.proId = JSON.stringify(this.contractData.proId);
      this.contractInData.projectName = JSON.parse(JSON.stringify(this.contractData.projectName));
      this.contractInData.contractId = JSON.stringify(this.contractData.id);
      this.contractInData.contractCode = JSON.parse(JSON.stringify(this.contractData.contractCode))
      this.contractInDialogParam.title = '新建';
      this.contractInDialogParam.action = 'new';
      this.contractInDialogParam.visible = true;
    },
    modifyContractIn (row) { //修改收款
      this.contractInData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到contractInData,就相当于重置表单了
      this.contractInDialogParam.title = '修改';
      this.contractInDialogParam.action = 'modify';
      this.contractInDialogParam.visible = true;
    },
    delContractIn (row) { //删除收款

      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const postData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到contractInData,就相当于重置表单了
        doContractInDelete(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractInArray(postData.contractId, response.data.data);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
      //
    },
    getContractInArray (selectContractId, updateData) {
      let param = { contractId: selectContractId }
      getInByContractId(param).then(response => {
        if (response.data.success) {
          this.inArray = response.data.data;
          if (updateData) { //如果数据不为空,就表示是本身组件的更新,所以更新父组件
            this.$emit('resultUpdate', selectContractId, updateData.contract.inRmb, updateData.project.inRmb);//传入父组件
          }

        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    contractInDataValidate () { //验证收款
      let result = true;
      this.$refs.contractInFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    contractInDataSubmit () {
      if (!this.contractInDataValidate()) {
        this.notifyError("验证未通过");
        return;
      }

      this.contractInDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.contractInData));
      if (this.contractInDialogParam.action == 'new') {
        doContractInNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractInArray(postData.contractId, response.data.data);

          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.contractInDialogParam.action == 'modify') {
        doContractInModify(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractInArray(postData.contractId, response.data.data);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
    },
  },
  watch: {
    contractId: function () {
      this.getContractInArray(this.contractId);
    }
  },
  mounted () {
    this.getContractInArray(this.contractId);
  },
}
</script>