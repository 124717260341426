<template>
  <div style="padding:20px;">
    <div style="padding-left: 38px;margin-bottom: 20px;">
      <el-radio-group v-model="reverse">
        <el-radio :label="true">倒序</el-radio>
        <el-radio :label="false">正序</el-radio>
      </el-radio-group>
      <el-button size="mini" type="primary" @click="trackArrayNew()" style="margin-left:40px">+ 新建</el-button>
    </div>
    <el-timeline :reverse="reverse">
      <el-timeline-item v-for="(activity, index) in this.trackArray" :key="index" :timestamp="activity.doTime" placement="top" type='primary'>
        <div style="border:1px solid #f3f3f3;padding:15px;">
          <el-button type="primary" size="mini" icon="el-icon-edit" circle style="position: absolute; top:-5px;left:160px;"
            @click="trackArrayEdit(activity)"></el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete" circle style="position: absolute; top:-5px;right:15px;"
            @click="trackArrayDelete(activity)"></el-button>
          <p style="line-height:24px"><span style="color: #303133;font-weight:bold;">事件:</span>{{activity.do}}</p>
          <p style="line-height:24px"><span style="color: #409EFF;font-weight:bold;">结果:</span>{{activity.result}}</p>
          <p>附件:
            <file-view :fileList="activity.addFile"> </file-view>
          </p>
        </div>
      </el-timeline-item>
    </el-timeline>

    <el-dialog v-dialogDrag :title="trackArrayDialogParam.title" :visible.sync="trackArrayDialogParam.visible" :width="trackArrayDialogParam.width"
      :close-on-click-modal='false' append-to-body>
      <el-form :model="trackArrayData" label-width="100px" size="small" ref="trackArrayForm" :rules="trackArrayDataRules">
        <!-- 这里修改表单项 -->
        <el-form-item label="发生时间" prop="doTime">
          <el-date-picker v-model="trackArrayData.doTime" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择发生时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="事件" prop="do">
          <el-input v-model="trackArrayData.do" type="textarea" :rows="3" resize="none" placeholder="请输入事件,例如电话回访,从何路径得到客户电话,联系客户,回访,写方案等,都可记录"></el-input>
        </el-form-item>
        <el-form-item label="结果" prop="result">
          <el-input v-model="trackArrayData.result" type="textarea" :rows="3" resize="none" placeholder="请输入结果"></el-input>
        </el-form-item>

        <el-form-item label="附件" prop="addFile">
          <upload-json-file :inputArray="trackArrayData.addFile" label="附件" childDir="contract" @success="addFileSuccessUpload"></upload-json-file>
        </el-form-item>

        <!-- 这里修改表单项 -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="trackArrayDialogParam.visible = false">取 消</el-button>
        <el-button @click="trackArrayPush()" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script> 
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
import UploadJsonFile from '@/components/utils/form/UploadJsonFile.vue';
import FileView from '@/components/utils/FileView.vue';
export default {
  props: { jsonArray: { type: Array, required: true } },
  components: { FileView, UploadJsonFile },
  data () {
    return {
      reverse: true,
      trackArray: this.jsonArray,
      /*-------------------------------------------------json trackArray 参数-----------------------------------------------*/
      trackArrayDialogParam: {
        visible: false,
        title: '新建',
        width: '50%',
        top: '15vh',
      },
      /*--json 字段参数--*/
      trackArrayRawData: {
        key: '',
        doTime: '',
        do: '',
        result: '',
        addFile: []
      },
      trackArrayData: {
        key: '',
        doTime: '',
        do: '',
        result: '',
        addFile: []
      },
      trackArrayDataRules:
      {
        key: [required],
        doTime: [required],
        do: [required],
        result: [required],
      },
    };
  },
  methods: {
    /*-------------------------------------------------json trackArray 方法-----------------------------------------------*/
    /**
     * 随机取数
     * @param {*} a
     * @param {*} b
     */
    cusRandom (a, b) {
      var n = Math.round(Math.random() * (a - b) + b);
      return n;
    },
    addFileSuccessUpload (data) { //上传附件成功,更新数据
      this.trackArrayData.addFile = data;
    },
    trackArrayNew () {
      this.trackArrayData = JSON.parse(JSON.stringify(this.trackArrayRawData))//重置
      this.trackArrayData.doTime = this.printDate();
      let strData = "";
      //如果觉得12个数太少也可以多放点，将i<12修改即可
      for (var i = 0; i < 12; i++) {
        let num = this.cusRandom(0, 9);                        //数字
        let upper = String.fromCharCode(this.cusRandom(65, 90));     //大写字母
        let lower = String.fromCharCode(this.cusRandom(97, 122));    //小写字母
        strData = strData + num + upper + lower;                  //将所有结果放进strData中
      }
      let str = "";
      for (let i = 0; i < 12; i++) {
        str += strData[this.cusRandom(0, strData.length - 1)];         //在strData里面随机抽取四个数
      }
      this.trackArrayData.key = str; //增加一个UID的标识为了修改
      this.trackArrayDialogParam.title = "新建";
      this.trackArrayDialogParam.visible = true;
    },
    trackArrayPush () {
      this.$refs.trackArrayForm.validate((valid) => {
        if (valid) {
          let index = this.trackArray.findIndex((item) => item.key === this.trackArrayData.key);
          if (index > -1)//表示找到了
          {
            this.trackArray.splice(index, 1, this.trackArrayData);
          }
          else {
            this.trackArray.push(this.trackArrayData);//插到最后面
          }
          this.trackArrayDialogParam.visible = false;//不显示
          this.$emit('success', this.trackArray);
        }
        else {
          this.trackArrayDialogParam.visible = true;//不显示
        }


      });
    },
    trackArrayDelete (key) {
      let index = this.trackArray.indexOf(key);//查出文件在哪
      // eslint-disable-next-line vue/no-mutating-props
      this.trackArray.splice(index, 1);
      this.$emit('success', this.trackArray);
    },
    trackArrayEdit (key) {
      let index = this.trackArray.indexOf(key);//查出文件在哪
      this.trackArrayData = this.trackArray[index];
      this.trackArrayDialogParam.title = "修改";
      this.trackArrayDialogParam.visible = true;
    },
    printDate () {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth() > 8 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
      const date = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
      const hours = d.getHours() > 9 ? d.getHours() : "0" + d.getHours();
      const minutes = d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes();
      //const seconds = d.getSeconds() > 9 ? d.getSeconds() : "0" + d.getSeconds();
      return `${year}-${month}-${date} ${hours}:${minutes}`;
    }
  },
  watch: {
    jsonArray: function () {
      this.trackArray = this.jsonArray;
    }
  },
}
</script>
