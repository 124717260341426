<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width: 100%;" @change="tableChangeSearchKey" clearable>
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row v-if="checkDocRight(direcParam.id,'new')||checkDocRight(direcParam.id,'modify')||checkDocRight(direcParam.id,'del')
      ||checkDocRight(direcParam.id,'public')||checkDocRight(direcParam.id,'top')||checkDocRight(direcParam.id,'trans')||checkDocRight(direcParam.id,'open')">

      <el-button-group>
        <el-button @click="formDataNew" type="primary" v-if="checkDocRight(direcParam.id,'new')">新建</el-button>
        <el-button @click="formDataModify" type="primary" v-if="checkDocRight(direcParam.id,'modify')">修改</el-button>
        <el-button @click="formDataDelete" type="primary" v-if="checkDocRight(direcParam.id,'del')">删除</el-button>
        <el-tooltip class="item" effect="dark" content="发布" placement="top-start" v-if="checkDocRight(direcParam.id,'public')">
          <el-button @click="formDataPublic" type="primary" icon="el-icon-finished"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="置顶" placement="top-start" v-if="checkDocRight(direcParam.id,'top')">
          <el-button @click="formDataTop" type="primary" icon="el-icon-edit"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="转发" placement="top-start" v-if="checkDocRight(direcParam.id,'trans')">
          <el-button @click="formDataTrans" type="primary" icon="el-icon-share"></el-button>
        </el-tooltip>
        <!-- <el-tooltip class="item" effect="dark" content="设为弹窗" placement="top-start" v-if="checkDocRight(direcParam.id,'open')">
          <el-button @click="formDataOpen" type="primary" icon="el-icon-copy-document"></el-button>
        </el-tooltip> -->
      </el-button-group>
    </el-row>

    <el-table ref="docTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="title" label="文章标题" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :style="{fontWeight:scope.row.titleStyle.fontWeight,color:scope.row.titleStyle.color,fontStyle:scope.row.titleStyle.fontStyle}"
            v-html="tableLightByKey(scope.row.title)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="authorWriter" label="作者" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.authorWriter)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="author" label="供稿单位" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.author)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="checkMan" label="审核" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.checkMan)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="hits" label="点击" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" prop="writeDate" label="发布日期" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" label="附件" width="200">
        <template slot-scope="scope">
          <span v-for="(appendix,index) in scope.row.addFile" :key="index" style="padding-left: 4px;">
            <el-link type="primary" :href="appendix.serverPath" target="_blank" :download="appendix.fileName" :underline="false">
              <el-tooltip class="item" effect="light" :content="`点击下载:${appendix.fileName}.${appendix.extName}`" placement="top-start">
                <i class="el-icon-paperclip" style="font-size:20px;"></i>
              </el-tooltip>
            </el-link>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="状态" width="120">
        <template slot-scope="scope">
          <span style="align-items: bottom;">
            <el-link :href="scope.row.fileUrl" target="_blank" :download="scope.row.title" :underline="false">
              <el-tooltip class="item" effect="light" content="点击下载源文件" placement="top-start">
                <i class="el-icon-connection" style="font-size:24px;color: #409EFF;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-if="scope.row.isPublic">
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="已发布" placement="top-start">
                <i class="el-icon-folder-checked" style="font-size:24px;color: #409EFF;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-else>
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="未发布" placement="top-start">
                <i class="el-icon-edit-outline" style="font-size:24px;color: #999999;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-if="scope.row.isTop">
            <el-link>
              <el-tooltip class="item" effect="light" content="已置顶" placement="top-start">
                <i class="el-icon-upload2" style="font-size:24px;color: #f56c6c;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="sort" label="排序" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" prop="belongId" label="统计所属" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" prop="hits" label="内容" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" label="操作" width="100" v-if="checkDocRight(direcParam.id,'modify')">
        <template slot-scope="scope">
          <el-button @click="tableBtEditClick(scope.row)" type="success" size="small">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" width="90%" top="5vh"
      :close-on-click-modal='false'>
      <el-row>
        <el-col :span="8">
          <el-form ref="formRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small">
            <el-form-item label="文章标题" prop="title">
              <el-input v-model="formData.title" class="input-with-select">
                <template slot="append">
                  <el-upload ref="docUpload" action="" :http-request="docUploadSubmit" :before-upload="docUploadBefore" :on-error="notifyError"
                    :show-file-list="false">
                    <i class="el-icon-upload" style="font-size: 25px;color:#409EFF"></i>
                  </el-upload>
                </template>
              </el-input>
            </el-form-item>

            <el-row>
              <el-col :span="12">
                <el-form-item label="列表样式">
                  <el-checkbox v-model="formData.titleStyle.fontWeight" true-label="bold" false-label="0">加粗</el-checkbox>
                  <el-checkbox v-model="formData.titleStyle.fontStyle" true-label="italic" false-label="0">斜体</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="标题颜色">
                  <el-color-picker v-model="formData.titleStyle.color" :predefine="predefineColors" size="mini"></el-color-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="作者">
                  <el-input v-model="formData.authorWriter" prop="authorWriter" placeholder="请输入作者"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="供稿单位">
                  <el-input v-model="formData.author" prop="author" placeholder="请输入供稿单位"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="审核">
                  <el-input v-model="formData.checkMan" prop="checkMan" placeholder="请输入审核"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发布时间">
                  <el-date-picker type="date" placeholder="选择日期" v-model="formData.writeDate" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="点击次数">
                  <el-input v-model="formData.hits" placeholder="请输入审核"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="排列顺序">
                  <el-input v-model="formData.sort" placeholder="请输入审核"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="padding-left:20px">
              <el-tabs activeName="first">
                <el-tab-pane label="简介" name="first">
                  <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="formData.note">
                  </el-input>
                </el-tab-pane>
                <el-tab-pane label="图片" name="second">
                  <upload-json-img :inputArray="formData.imageUrl" :childDir="''+formData.classId"></upload-json-img>
                </el-tab-pane>
                <el-tab-pane label="附件" name="third">
                  <upload-json-file :inputArray="formData.addFile" :childDir="''+formData.classId"></upload-json-file>
                </el-tab-pane>
                <el-tab-pane label="扩展参数" name="four">
                  <ext-param :inputArray="formData.extParam"></ext-param>
                </el-tab-pane>
              </el-tabs>
            </el-row>
          </el-form>

        </el-col>
        <el-col :span="16" style="padding-left:20px;">
          <doc-editor :editHtml="editorHtmlData" @input="childResultHtml"></doc-editor>
          <!-- editorHtmlData: 父传子;childResultHtml:子传父,直接把方法传过去,让对方传回来,其实他妈的还是父传子-->
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="30%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="title" label="选定的记录">
        </el-table-column>
        <el-table-column prop="isPublic" label="状态" width="100">
          <template slot-scope="scope">
            <span style="padding-left: 4px;" v-if="scope.row.isPublic">
              <el-link :underline="false">
                <el-tooltip class="item" effect="light" content="已发布" placement="top-start">
                  <i class="el-icon-folder-checked" style="font-size:24px;color: #409EFF;font-weight: 400;"></i>
                </el-tooltip>
              </el-link>
            </span>
            <span style="padding-left: 4px;" v-else>
              <el-link :underline="false">
                <el-tooltip class="item" effect="light" content="未发布" placement="top-start">
                  <i class="el-icon-edit-outline" style="font-size:24px;color: #999999;font-weight: 400;"></i>
                </el-tooltip>
              </el-link>
            </span>
            <span style="padding-left: 4px;" v-if="scope.row.isTop">
              <el-link>
                <el-tooltip class="item" effect="light" content="已置顶" placement="top-start">
                  <i class="el-icon-upload2" style="font-size:24px;color: #f56c6c;font-weight: 400;"></i>
                </el-tooltip>
              </el-link>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-col :span="14">
          <label v-if="formChooseDialogParam.transSelectVisible" style="font-size:1.2vh;color:#409EFF;margin-right:5px;">转发到栏目:</label>
          <el-select v-if="formChooseDialogParam.transSelectVisible" v-model="formChooseDialogParam.transSelectValue" placeholder="请选择目的板块">
            <el-option v-for="item in allowTransPanel" :key="item.id" :label="item.viewName" :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<style scoped>
.customFileListCss >>> .el-progress-circle {
  margin-left: 20px;
}
.customFileListCss >>> .el-upload-list__item {
  width: 150px;
  height: 100px;
}

.customFileListCss >>> .el-upload--picture-card {
  width: 150px;
  height: 100px;
  line-height: 100px;
}
.customFileListCss >>> .el-upload-list--picture-card {
  width: 150px;
  height: 100px;
}
.customFileListCss >>> .el-upload-list__item-thumbnail {
  width: 150px;
  height: 100px;
}
.customFileListCss >>> .el-upload-list__item-name {
  position: absolute;
  overflow: visible;
  display: block;
  top: 5px;
  width: 140px;
  height: 50px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #333333;
  margin: 0px auto;
  word-break: break-all;
  padding: 0px 5px 0px 5px;
  text-overflow: clip;
  white-space: inherit;
}
.customFileListCss >>> .el-upload-list__item-size {
  position: absolute;
  overflow: visible;
  display: block;
  top: 70px;
  left: 45px;
  width: 50px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  margin: 0px auto;
  word-break: break-all;
  padding: 0px 5px 0px 5px;
  text-overflow: clip;
  white-space: inherit;
  background-color: #fff;
  color: #333333;
}
</style>

<script> 
import store from '@/store';
import { getTableData, postCkFile, postFile, postImg, getEditorHtml, getAllowTransPanel, doNew, doModify, doDelete, doPublic, doPubTop, doTrans, doOpen } from '@/api/wbkj/DocInfo'; //数据源
import { required } from "@/utils/formRules" //校验规则
import DocEditor from '@/components/wbkj/DocEditor' //自定义组件
import ExtParam from '@/components/utils/form/ExtParam.vue';
import UploadJsonImg from '@/components/utils/form/UploadJsonImg.vue';
import UploadJsonFile from '@/components/utils/form/UploadJsonFile.vue';
export default {
  components: { DocEditor, ExtParam, UploadJsonImg, UploadJsonFile },
  data () {
    return {
      predefineColors: [
        '#409EFF',
        '#67C23A',
        '#E6A23C',
        '#F56C6C',
        '#909399',
        '#000000',
        '#303133',
        '#606266',
        '#C0C4CC',
      ],
      /*文件附件上传的进度条状态 */
      fileCardProgressParam: {
        visible: false,
        percentage: 0
      },
      /*图片附件上传的进度条状态 */
      imageCardProgressParam: {
        visible: false,
        percentage: 0
      },
      /*图片展示窗口状态 */
      imageCardDialogParam: { //图片显示对话框的参数
        viewUrl: '',//图片的URL
        imageShow: false,//是否显示
        disabled: false,//是否禁用按钮(新建的时候无图片所以没有显示)
      },
      editorHtmlData: '无',//html的表单内容
      /*导航栏状态 */
      direcParam: {
        'id': this.$route.params.id,
        'directName': ''
      },
      /*表格参数 */
      tableParam: {
        'classId': 0,
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: ''
      },
      allowTransPanel: [],//允许转发的板块
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        transSelectVisible: false,
        transSelectValue: 0,
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        id: 0,
        title: '',
        titleStyle: {},
        classId: 0,
        fileUrl: '',
        imageUrl: [],
        author: '',
        authorWriter: '',
        checkMan: '',
        writeDate: '',
        hits: 0,
        isPublic: false,
        docType: 'word2htm',
        note: '',
        sort: 192450,
        addFile: [],
        extParam: [],
        belongId: 1,
        isTop: false,
        showType: 0,
        stampNum: 0,
        agreeNum: 0,
        writeNum: 0,
        date2: '',
        htmlData: '',
      },
      formDialogRules:
      {
        title: [required],
        author: [required],
        authorWriter: [required],
      }
    }
  },
  methods: {
    /*-------------------------------------------------特殊事件 -----------------------------------------------*/
    /*子传父-DocEditor子组件传过来的值 */
    childResultHtml (html) {
      this.editorHtmlData = html;
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.editorHtmlData = '';
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        var realUrl = this.formData.fileUrl;
        realUrl = realUrl.substring(0, realUrl.lastIndexOf(".")).replaceAll("fileUpload/", "") + '.htm';
        getEditorHtml({ fileUrl: realUrl }).then(response => {
          this.editorHtmlData = response.data;
          this.formDialogParam.formDataAction = 'modify';
          this.formDialogParam.title = '修改';
          this.formDialogParam.visible = true;
        })
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
        this.formChooseDialogParam.transSelectVisible = false;
      }
    },
    /*表单(form)-发布 */
    formDataPublic () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能发布");
      }
      else {
        this.formChooseDialogParam.title = "将选定的记录,发布/取消发布"
        this.formChooseDialogParam.formChooseAction = 'public';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '取消发布';
        this.formChooseDialogParam.action2 = true;
        this.formChooseDialogParam.action2View = '发 布';
        this.formChooseDialogParam.visible = true;
        this.formChooseDialogParam.transSelectVisible = false;
      }
    },
    formDataTop () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能置顶");
      }
      else {
        this.formChooseDialogParam.title = "将选定的记录,置顶/取消置顶"
        this.formChooseDialogParam.formChooseAction = 'pubTop';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '取消置顶';
        this.formChooseDialogParam.action2 = true;
        this.formChooseDialogParam.action2View = '置 顶';
        this.formChooseDialogParam.visible = true;
        this.formChooseDialogParam.transSelectVisible = false;
      }
    },
    formDataTrans () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能转发");
      }
      else {
        this.formChooseDialogParam.transSelectVisible = true;
        this.formChooseDialogParam.title = "将选定的记录,转发"
        this.formChooseDialogParam.formChooseAction = 'trans';
        this.formChooseDialogParam.action1 = false;
        this.formChooseDialogParam.action1View = '取 消';
        this.formChooseDialogParam.action2 = true;
        this.formChooseDialogParam.action2View = '转 发';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataOpen () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能转发");
      }
      else {
        this.formChooseDialogParam.transSelectVisible = false;
        this.formChooseDialogParam.title = "将选定的记录,设为弹窗"
        this.formChooseDialogParam.formChooseAction = 'open';
        this.formChooseDialogParam.action1 = false;
        this.formChooseDialogParam.action1View = '取 消';
        this.formChooseDialogParam.action2 = true;
        this.formChooseDialogParam.action2View = '确 定';
        this.formChooseDialogParam.visible = true;
      }
    },




    formDataSubmit () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.doDataSubmit();
          return;
        } else {
          this.notifyWarning('表单验证未通过');
          return;
        }
      });

    },
    /*表单(form)-提交 */
    doDataSubmit () {
      this.formDialogParam.visible = false;
      this.formData.htmlData = this.editorHtmlData;
      //深Copy一份,本来是不用的,但是由于MysqlJson的原因,所以..
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.classId = this.tableParam.classId;
      postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      postData.titleStyle = JSON.stringify(this.formData.titleStyle);//由于MysqlJson的原因
      postData.extParam = JSON.stringify(this.formData.extParam);//由于MysqlJson的原因
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit (result) {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('classId', this.tableParam.classId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
      else if (this.formChooseDialogParam.formChooseAction == 'public') {

        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          canId.push(this.tableMultipleSelection[i].id);
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('classId', this.tableParam.classId);
        data.append('isPublic', result);
        doPublic(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
      else if (this.formChooseDialogParam.formChooseAction == 'pubTop') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          canId.push(this.tableMultipleSelection[i].id);
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('classId', this.tableParam.classId);
        data.append('isTop', result);
        doPubTop(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })

      }
      else if (this.formChooseDialogParam.formChooseAction == 'trans') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          canId.push(this.tableMultipleSelection[i].id);
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('classId', this.tableParam.classId);
        data.append('transId', this.formChooseDialogParam.transSelectValue);
        doTrans(data).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })


      }
      else if (this.formChooseDialogParam.formChooseAction == 'open') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          canId.push(this.tableMultipleSelection[i].id);
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('classId', this.tableParam.classId);
        doOpen(data).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }

    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      this.formData = row;
      this.$refs.docTableRef.clearSelection(); //清除选中
      this.$refs.docTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-编辑点击事件 */
    tableBtEditClick () {
      //this.formData = row;
      this.$nextTick(this.formDataModify());

    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }


    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    /*-------------------------------------------------DOC上传 -----------------------------------------------*/

    /*上传Doc文件  上传判断是否是Doc文件*/
    docUploadBefore (file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'))
      if (fileType.toLowerCase() != '.doc' && fileType.toLowerCase() != '.docx' && fileType.toLowerCase() != '.pdf') {
        this.notifyWarning('上传失败,请上传doc,docx或pdf文件')
        return false;
      }
      else {
        return true;
      }
    },
    docUploadSubmit (param) {
      const docFileData = new FormData()
      docFileData.append('file', param.file);
      docFileData.append('childFile', this.tableParam.classId);
      postCkFile(docFileData).then(response => {

        if (response.data.success) {
          const jsonData = response.data.data;
          this.formData.fileUrl = jsonData.serverPath;
          this.formData.imageUrl = jsonData.fileImages;
          this.formData.title = jsonData.fileName;
          this.formData.note = jsonData.fileNote;
          this.editorHtmlData = jsonData.fileContent;
          param.onSuccess();  // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
      })
    },
    /*-------------------------------------------------展示图片上传 -----------------------------------------------*/
    imageCardSuccess () { //成功后清除控制条
      this.imageCardProgressParam.visible = false; //将进度条清空
      this.imageCardProgressParam.percentage = 0;//将进度条百分比置0
    },
    /*上传图片文件  上传判断是否是jpg,png文件*/
    imageCardRemove (file) {
      let index = this.formData.imageUrl.indexOf(file);//查出文件在哪
      if (index > -1) {
        this.formData.imageUrl.splice(index, 1);
      }
      return true;
      // 从数组中添加 / 删除项目，然后返回被删除的项目
    },
    imageCardPreview (file) {
      this.imageCardDialogParam.viewUrl = file.serverPath;
      this.imageCardDialogParam.imageShow = true;
    },
    imageCardBeforeUp (file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'))
      if (fileType.toLowerCase() != '.jpg' && fileType.toLowerCase() != '.png' && fileType.toLowerCase() != '.gif') {
        this.notifyWarning("不支持该扩展名的文件,请上传扩展名为png,jpg,gif的图片文件");
        return false;
      }
      else {
        return true;
      }
    },
    imageCardSubmit (param) {
      const imageCardData = new FormData()
      imageCardData.append('file', param.file);
      imageCardData.append('childFile', this.tableParam.classId);
      postImg(imageCardData, {
        onUploadProgress: (progressEvent) => {
          this.imageCardProgressParam.visible = true;
          let percent =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          this.imageCardProgressParam.percentage = percent;
        }
      }).then(response => {
        if (response.data.success) {
          const jsonData = response.data.data;
          jsonData.uid = param.file.uid;
          this.formData.imageUrl.unshift(jsonData);//从前插入数据
          param.onSuccess(); // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
        // 但是我们上传成功了图片， docList 里面的值却没有改变，还好有on-change指令可以使用
      })
    },
    /*-------------------------------------------------附件上传-----------------------------------------------*/
    fileCardSuccess () { //成功后清除控制条
      this.fileCardProgressParam.visible = false; //将进度条清空
      this.fileCardProgressParam.percentage = 0;//将进度条百分比置0
    },
    fileCardRemove (file) {
      let index = this.formData.addFile.indexOf(file);//查出文件在哪
      this.formData.addFile.splice(index, 1);  // 从数组中添加 / 删除项目，然后返回被删除的项目
    },
    fileCardSubmit (param) {
      const fileCardData = new FormData()
      fileCardData.append('file', param.file);
      fileCardData.append('childFile', this.tableParam.classId);
      postFile(fileCardData, {
        onUploadProgress: (progressEvent) => {
          this.fileCardProgressParam.visible = true;
          let percent =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          this.fileCardProgressParam.percentage = percent;
        }
      }).then(response => {
        if (response.data.success) {
          const jsonData = response.data.data;
          jsonData.uid = param.file.uid; //没有UID更新时会跳动画
          this.formData.addFile.push(jsonData);//插到最后面
          param.onSuccess();  // 将进度条清空
        }
        else {
          param.onError(response.data.msg);
        }
      })
    },

  },
  computed: {
    activeCus () {
      return this.$store.state.adminState.activeCus;
    }
  },
  watch: {
    '$route.params.id': {
      handler: function () {
        if (this.$route.params.id) {
          this.direcParam = JSON.parse(store.getters.getActiveCus());
          this.formChooseDialogParam.transSelectValue = this.direcParam.id;
          this.tableParam.classId = this.direcParam.id;
          this.tableParam.key = '';
          this.tableParam.page = 1;
          this.tableDateRefresh();
        }
      },
      deep: true,
      immediate: true//立即执行
    }
  },
  /*监听active模块的变化,如果变化了就重新去取数据 */
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.tableParam.classId = this.direcParam.id;
    this.tableParam.key = '';
    this.tableParam.page = 1;
    this.tableDateRefresh();
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    getAllowTransPanel().then(response => {
      if (response.data.success) {
        this.allowTransPanel = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
  }
}
</script>