<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" @change="tableChangeSearchKey" clearable>
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row v-if="checkCusRight(direcParam.id,'new')||checkCusRight(direcParam.id,'modify')||checkCusRight(direcParam.id,'delete')">
      <el-col :span="18">
        <el-button-group>
          <el-button @click="formDataNew" type="primary" v-if="checkCusRight(direcParam.id,'new')">新建</el-button>
          <el-button @click="formDataModify" type="primary" v-if="checkCusRight(direcParam.id,'modify')">修改</el-button>
          <el-button @click="formDataDelete" type="primary" v-if="checkCusRight(direcParam.id,'delete')">删除</el-button>
        </el-button-group>
      </el-col>
    </el-row>
    <el-table ref="customerTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="cmpCnName" label="客户名称" width="400">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmpCnName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="cmpShortName" label="简称" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmpShortName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="cmpAccounts" label="统一社会信用号" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmpAccounts)"></span>
        </template>
      </el-table-column>

      <el-table-column header-align="center" prop="linkMan" label="常用联系人" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :key="index" v-for="(item,index) in scope.row.linkMan" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px;">姓名:{{item.name}}<br />类型:{{item.type}}<br />手机:{{item.mobile}}<br />邮件:{{item.email}}<br /></div>
              <el-tag>{{item.name}}</el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <!-- <el-table-column header-align="center" prop="bankInfo" label="开户银行">
        <template slot-scope="scope">
          <span :key="index" v-for="(item,index) in scope.row.bankInfo" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px;">开户行:{{item.bank}}<br />开户名称:{{item.name}}<br />账号:{{item.code}}<br />备注:{{item.note}}<br /></div>
              <el-tag>{{item.bank}}</el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <el-table-column header-align="center" prop="invoiceInfo" label="开票信息" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :key="index" v-for="(item,index) in scope.row.invoiceInfo" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px">税号:{{item.invoice}}<br />名称:{{item.name}}<br />类型:{{item.type}}<br />备注:{{item.note}}<br /></div>
              <el-tag>{{item.type}}</el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column> -->

      <el-table-column header-align="center" label="附件">
        <template slot-scope="scope">
          <file-view :fileList="scope.row.addFile"> </file-view>
        </template>
      </el-table-column>
      <el-table-column prop="isPublic" label="状态">
        <template slot-scope="scope">
          <span style="padding-left: 4px;" v-if="scope.row.isPublic">
            <el-tooltip class="item" effect="light" :content="'社会信用代码:'+scope.row.cmpAccounts" placement="top-start">
              <i class="el-icon-c-scale-to-original" style="font-size:24px;color: #409EFF;font-weight: 400;"></i>
            </el-tooltip>
          </span>
          <span style="padding-left: 4px;" v-if="scope.row.isPublic">
            <el-tooltip class="item" effect="light" content="正在使用" placement="top-start">
              <i class="el-icon-folder-checked" style="font-size:24px;color: #409EFF;font-weight: 400;"></i>
            </el-tooltip>
          </span>
          <span style="padding-left: 4px;" v-else>
            <el-tooltip class="item" effect="light" content="未启用" placement="top-start">
              <i class="el-icon-edit-outline" style="font-size:24px;color: #999999;font-weight: 400;"></i>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width"
      :top="formDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="customerFormRef" :model="formData" :rules="formDialogRules" label-width="90px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-tabs v-model="tabPaneParam.activeName">
          <el-tab-pane label="基本信息" name="first" active>
            <el-row :gutter="20" style="padding-bottom:0px;;">
              <el-col :span="12">
                <el-form-item label="客户名称" prop="cmpCnName">
                  <el-input v-model="formData.cmpCnName" placeholder="请输入客户名称" id="cmpCnName"></el-input>
                </el-form-item>
                <el-form-item label="注册地址" prop="cmpRegAddress">
                  <el-input v-model="formData.cmpRegAddress" placeholder="请输入注册地址" id="cmpRegAddress"></el-input>
                </el-form-item>
                <el-form-item label="信用号码" prop="cmpAccounts">
                  <el-input v-model="formData.cmpAccounts" placeholder="请输入统一社会信用号" id="cmpAccounts"></el-input>
                </el-form-item>

                <el-row style="padding-bottom: 0px;">
                  <el-col :span="10">
                    <el-form-item label="客户状态">
                      <el-checkbox v-model="formData.isPublic" id="isPublic">正常</el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="14">
                    <el-form-item label="创建时间" placeholder="请选择创建时间" prop="creatDate">
                      <el-date-picker v-model="formData.creatDate" value-format="yyyy-MM-dd" style="width:100%" type="date" placeholder="请选择创建时间"
                        id="creatDate"></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-form-item label="简称" prop="cmpShortName">
                  <el-input v-model="formData.cmpShortName" placeholder="请输入简称" id="cmpShortName"></el-input>
                </el-form-item>
                <el-form-item label="通讯地址" prop="cmpAddress">
                  <el-input v-model="formData.cmpAddress" placeholder="请输入通讯地址" id="cmpAddress"></el-input>
                </el-form-item>
                <el-form-item label="法人" prop="cmpManager">
                  <el-input v-model="formData.cmpManager" placeholder="请输入法人" id="cmpManager"></el-input>
                </el-form-item>
                <el-form-item label="客户所属" prop="createId"><!--如果是查看公司级自然能选所有销售,否则就是自己邦定的员工帐号-->
                  <el-select v-model="formData.createId" filterable placeholder="请选择" style="width:100%;" id="createId">
                    <el-option v-for="item in this.allOnPosEmp" :key="'allOnPosEmp'+item.id" :label="item.cmpName+'-'+item.depName+'-'+item.empName"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="备注" prop="cmpNote">
              <el-input v-model="formData.cmpNote" type="textarea" :rows="4" resize="none" placeholder="请输入备注" id="cmpNote"></el-input>
            </el-form-item>
            <el-form-item label="附件">
              <upload-json-file :inputArray="formData.addFile" label="营业执照、资质证明等相关材料" childDir="customer" @success="addFileSuccessUpload"
                id="addFile"></upload-json-file>
              <div>营业执照、资质证明等相关材料</div>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="常用联系人" name="second">
            <link-param :inputArray="formData.linkMan"></link-param>
          </el-tab-pane>
          <el-tab-pane label="开户银行" name="third">
            <bank-param :inputArray="formData.bankInfo"></bank-param>
          </el-tab-pane>
          <el-tab-pane label="开票信息" name="fourth">
            <tax-param :inputArray="formData.invoiceInfo"></tax-param>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="40%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="cmpCnName" label="选定的客户">
          <template slot-scope="scope">
            <span v-if=scope.row.isPublic style="color:#cccc">{{ scope.row.cmpCnName}}</span>
            <span v-else>{{ scope.row.cmpCnName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if=scope.row.isPublic style="color:#ef0000">请先禁用,才能删除</span>
            <span v-else>可以删除</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script> 
import store from '@/store';
import FileView from '@/components/utils/FileView.vue';
import UploadJsonFile from '@/components/utils/form/UploadJsonFile.vue';
import { getAllOnPosEmp } from '@/api/utils/Employee';
import { getTableData, doNew, doModify, doDelete } from '@/api/wbkj/Customer'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import LinkParam from '@/components/utils/customer/LinkParam.vue';
import BankParam from '@/components/utils/customer/BankParam.vue';
import TaxParam from '@/components/utils/customer/TaxParam.vue';
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { FileView, UploadJsonFile, LinkParam, BankParam, TaxParam },
  data () {
    return {
      /*导航栏状态 */
      direcParam: { id: 0, allow: {} },
      tabPaneParam: {
        activeName: 'first',
        refreshMath: 0,
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '60%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        cmpCnName: '',
        cmpShortName: '',
        cmpSecretName: '',
        cmpRegAddress: '',
        cmpAddress: '',
        cmpAccounts: '',
        cmpManager: '',
        workScope: '',
        linkMan: [],
        bankInfo: [],
        invoiceInfo: [],
        cmpNote: '',
        addFile: [],
        createId: '',
        creatDate: new Date(),
        isPublic: false,

      },
      formDialogRules:
      {
        cmpCnName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpShortName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpRegAddress: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpAddress: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpAccounts: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpManager: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        createId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        creatDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isPublic: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
      },
      allOnPosEmp: [],
    }
  },
  methods: {
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.tabPaneParam.activeName = 'first';
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;

    },
    /*表单(form)-修改 */
    formDataModify () {
      this.tabPaneParam.activeName = 'first';
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "删除客户同时会删除客户所有的关联数据:项目,合同等,是否确定?"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.customerFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.linkMan = JSON.stringify(this.formData.linkMan);//由于MysqlJson的原因
      postData.bankInfo = JSON.stringify(this.formData.bankInfo);//由于MysqlJson的原因
      postData.invoiceInfo = JSON.stringify(this.formData.invoiceInfo);//由于MysqlJson的原因
      postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //if () postData.createId =
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        if (canId.length < 1) {
          this.notifyWarning("没有符合条件的记录,请重新选择或先将客户禁用");
        }
        else {
          const data = new FormData()
          data.append('id', canId);
          doDelete(data).then(response => {
            if (response.data.success) {
              this.tableParam.key = '';
              this.tableParam.page = 1;
              this.notifySuccess(response.data.msg);
              this.tableDateRefresh();
            }
            else {
              this.notifyWarning(response.data.msg);
              this.tableDateRefresh();
            }
          })
        }

      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.customerTableRef.clearSelection(); //清除选中
      this.$refs.customerTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    /*JsonArray-invoiceInfo*/
    addFileSuccessUpload (data) { //上传附件成功,更新数据
      this.formData.addFile = data;
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    let param = {};
    if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
      param.saleId = store.getters.getLoginUser().empId;
      console.log(param.saleId);
    }
    getAllOnPosEmp(param).then(response => {
      if (response.data.success) {
        this.allOnPosEmp = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
  }
}
</script>
