<!--此组件是为生成权限使用-->
<template>
  <div>
    <el-table :data="extJsonList" height="250" border :cell-style="{height:'24px',padding:0,margin:0}" size="mini">
      <el-table-column prop="name" label="姓名" width="120">
      </el-table-column>
      <el-table-column prop="type" label="类型" width="200">
      </el-table-column>
      <el-table-column prop="mobile" label="手机" width="120">
      </el-table-column>
      <el-table-column prop="email" label="E-mail"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot="header">
          <el-button type="success" icon="el-icon-plus" size="mini" @click="extJsonListNew()">新建</el-button>
        </template>
        <template slot-scope="scope">
          <el-button @click="extJsonListEdit(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="extJsonListDelete(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="extJsonListDialogParam.title" :visible.sync="extJsonListDialogParam.visible" :width="extJsonListDialogParam.width"
      :close-on-click-modal='false' append-to-body>
      <el-form :model="extJsonListData" label-width="100px" size="mini" ref="extJsonListForm" :rules="extJsonListDataRules">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="extJsonListData.name" placeholder="请输入权限ID,不可与其它重复"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-input v-model="extJsonListData.type" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input v-model="extJsonListData.mobile" placeholder="请输入英文名称"></el-input>
        </el-form-item>
        <el-form-item label="E-mail" prop="email">
          <el-input v-model="extJsonListData.email" placeholder="请输入邮件地址"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="extJsonListDialogParam.visible = false">取 消</el-button>
        <el-button @click="extJsonListPush()" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script> 
import { postFile } from '@/api/utils/UploadFileOrImg'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  props: { inputArray: { type: Array, required: true } },
  data () {
    return {
      extJsonList: this.inputArray,
      /*-------------------------------------------------json extJsonList 参数-----------------------------------------------*/
      extJsonListDialogParam: {
        visible: false,
        title: '新建',
        width: '50%',
        top: '15vh',
      },
      /*--json 字段参数--*/
      extJsonListRawData: {
        key: '',
        name: '',
        type: '',
        mobile: '',
        email: '',
      },
      extJsonListData: {
        key: '',
        name: '',
        type: '',
        mobile: '',
        email: '',
      },
      extJsonListDataRules:
      {
        id: [required],
        name: [required],
        type: [required],
        mobile: [required],
        email: [required]
      },
    };
  },
  methods: {

    /*-------------------------------------------------特殊事件 -----------------------------------------------*/
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },
    notifyError (error) { this.$notify.error({ title: '错误', message: error }); },
    /*特殊事件-下载文件 */
    downloadFile (downUrl, downName) { //下载文件
      let a = document.createElement('a')
      a.href = downUrl;
      a.download = downName;
      a.target = '_blank'//在新窗口打开
      a.click();
    },
    /*-------------------------------------------------链接路径可自定义,可选上传 -----------------------------------------------*/
    fileUrlUploadSubmit (param) {
      const docFileData = new FormData()
      docFileData.append('file', param.file);
      docFileData.append('childFile', 'extParam');
      postFile(docFileData).then(response => {
        if (response.data.success) {
          const jsonData = response.data.data;
          this.extJsonListData.keyValue = jsonData.serverPath;
          param.onSuccess();  // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
      }).catch(error => {
        param.onError(error); //会去调用出错的解决办法
      })
    },
    /*-------------------------------------------------链接路径可自定义,可选上传 -----------------------------------------------*/

    /*-------------------------------------------------json extJsonList 方法-----------------------------------------------*/
    /**
     * 随机取数
     * @param {*} a
     * @param {*} b
     */
    cusRandom (a, b) {
      var n = Math.round(Math.random() * (a - b) + b);
      return n;
    },
    extJsonListNew () {
      this.extJsonListData = JSON.parse(JSON.stringify(this.extJsonListRawData))//重置
      let strData = "";
      //如果觉得12个数太少也可以多放点，将i<12修改即可
      for (var i = 0; i < 12; i++) {
        let num = this.cusRandom(0, 9);                        //数字
        let upper = String.fromCharCode(this.cusRandom(65, 90));     //大写字母
        let lower = String.fromCharCode(this.cusRandom(97, 122));    //小写字母
        strData = strData + num + upper + lower;                  //将所有结果放进strData中
      }
      let str = "";
      for (let i = 0; i < 12; i++) {
        str += strData[this.cusRandom(0, strData.length - 1)];         //在strData里面随机抽取四个数
      }
      this.extJsonListData.key = str; //增加一个UID的标识为了修改
      this.extJsonListDialogParam.title = "新建";
      this.extJsonListDialogParam.visible = true;
    },
    extJsonListPush () {
      this.$refs.extJsonListForm.validate((valid) => {
        if (valid) {
          let index = this.extJsonList.findIndex((item) => item.key === this.extJsonListData.key);
          if (index > -1)//表示找到了
          {
            this.extJsonList.splice(index, 1, this.extJsonListData);
          }
          else {
            this.extJsonList.push(this.extJsonListData);//插到最后面
          }
        }
        this.extJsonListDialogParam.visible = false;//不显示
        this.$emit('success', this.extJsonList);
      });
    },
    extJsonListDelete (key) {
      let index = this.extJsonList.indexOf(key);//查出文件在哪
      // eslint-disable-next-line vue/no-mutating-props
      this.extJsonList.splice(index, 1);
      this.$emit('success', this.extJsonList);
    },
    extJsonListEdit (key) {
      let index = this.extJsonList.indexOf(key);//查出文件在哪
      this.extJsonListData = this.extJsonList[index];
      this.extJsonListDialogParam.title = "修改";
      this.extJsonListDialogParam.visible = true;
    },
  },
  filters: {},
  watch: {
    inputArray: function () {
      this.extJsonList = this.inputArray;
    }
  },
  mounted () {
  },
}
</script>
