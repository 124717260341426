import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminMain from '@/views/AdminMain.vue'
import HomeIndex from '@/views/home/AdminHome.vue' //管理员主页
import SaleIndex from '@/views/home/SaleHome.vue' //销售人员主页
import DocInfo from '@/views/wbkj/DocInfo.vue'
import Downloads from '@/views/wbkj/DownloadsSet.vue'
import Menus from '@/views/wbkj/MenusSet.vue'
import Departs from '@/views/wbkj/DepartsSet.vue'
import Links from '@/views/wbkj/LinksSet.vue'
import Images from '@/views/wbkj/ImagesSet.vue'
import UserLogin from '@/views/UserLogin.vue'
import DocPanel from '@/views/wbkj/DocPanelSet.vue'
import CusPanel from '@/views/wbkj/CusPanelSet.vue'
import Role from '@/views/wbkj/RoleSet.vue'
import IpPermit from '@/views/wbkj/IpPermitSet.vue'
import AdminSet from '@/views/wbkj/AdminSet.vue' //后台账号管理
import UserInfo from '@/views/wbkj/UserInfo.vue' //
import Company from '@/views/wbkj/CompanySet.vue'
import Employee from '@/views/wbkj/EmployeeSet.vue'
import Project from '@/views/wbkj/ProjectSet.vue'
import Customer from '@/views/wbkj/CustomerSet.vue' //客户管理
import ProjectInfo from '@/views/wbkj/ProjectInfo.vue' //项目详情
import Contract from '@/views/wbkj/ContractSet.vue' //认证合同
import ContractFund from '@/views/wbkj/ContractFund.vue' //认证合同收款
import ContractTrack from '@/views/wbkj/ContractTrack.vue' //销售跟踪
import ProjectCount from '@/views/wbkj/ProjectCount.vue' //项目统计
import ProjectCusCount from '@/views/wbkj/ProjectCusCount.vue' //客户统计
import SaleStatistics from '@/views/wbkj/SaleStatistics.vue' //销售人员业绩统计
import YearCount from '@/views/wbkj/YearCount.vue' //公司项目年报
import BackUp from '@/views/wbkj/BackUpSet.vue'//备份管理
import SettingInfo from '@/views/wbkj/SettingInfo.vue'//网站配置项
//import TechCostOut from '@/views/tech/CostOutSet.vue' //费用审批


Vue.use(VueRouter)
//2.将路由组用引入到路由中去,说白了就是路径和容器对应
const routes = [
  { path: '/', redirect: '/admin' }, //默认项
  { path: '/login', name: 'login', component: UserLogin },//登录
  {
    path: '/admin',
    component: AdminMain, //主容器
    redirect: '/homeIndex',
    children: [
      { path: '/homeIndex', name: 'homeIndex', component: HomeIndex },//首页
      { path: '/saleIndex', name: 'saleIndex', component: SaleIndex },//首页
      { path: '/role', name: 'role', component: Role }, //角色管理
      { path: '/docInfo/:id', name: 'docInfo', component: DocInfo },
      { path: '/downloads', name: 'downloads', component: Downloads },
      { path: '/menus', name: 'menus', component: Menus },
      { path: '/departs', name: 'departs', component: Departs },
      { path: '/links', name: 'links', component: Links },
      { path: '/images', name: 'images', component: Images },
      { path: '/docPanel', name: 'docPanel', component: DocPanel },
      { path: '/cusPanel', name: 'cusPanel', component: CusPanel },
      { path: '/ipPermit', name: 'ipPermit', component: IpPermit },
      { path: '/adminSet', name: 'adminSet', component: AdminSet },
      { path: '/userInfo', name: 'userInfo', component: UserInfo },
      { path: '/company', name: 'company', component: Company }, //公司设定
      { path: '/employee', name: 'employee', component: Employee }, //员工设定
      { path: '/project', name: 'project', component: Project }, //员工设定
      { path: '/customer', name: 'customer', component: Customer }, //客户管理
      { path: '/projectInfo', name: 'projectInfo', component: ProjectInfo }, //客户管理
      { path: '/contract', name: 'contract', component: Contract }, //认证合同
      { path: '/projectCount', name: 'projectCount', component: ProjectCount },//项目统计
      { path: '/projectCusCount', name: 'projectCusCount', component: ProjectCusCount },//客户统计
      { path: '/saleStatistics', name: 'saleStatistics', component: SaleStatistics },//销售人员业绩统计
      { path: '/yearCount', name: 'yearCount', component: YearCount },//销售人员业绩统计
      { path: '/contractTrack', name: 'contractTrack', component: ContractTrack },
      { path: '/contractFund', name: 'contractFund', component: ContractFund },
      { path: '/backUp', name: 'backUp', component: BackUp }, //请假
      { path: '/settingInfo', name: 'settingInfo', component: SettingInfo },

    ]
  }
]
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
// 4.把这个暴露出来,其它js才可以去使用它
export default router