<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="checkCusRight(direcParam.id,'allData')?10:16">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="3">
        <el-select v-model="tableParam.year" placeholder="选择年份" style="width:100%;" @change="tableChangeSearchYear">
          <el-option label="全部年份" :value="0"></el-option>
          <el-option v-for="item in years" :key="item.value" :label="item.key" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" v-if="checkCusRight(direcParam.id,'allData')">
        <el-select v-model="tableParam.saleId" filterable placeholder="请选择" style="width:100%;" @change="tableChangeSearchSale">
          <el-option label="全部销售人员" :value="0"></el-option>
          <el-option v-for="item in this.allOnPosEmp" :key="'allOnPosEmp'+item.id" :label="item.cmpName+'-'+item.depName+'-'+item.empName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input clearable placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%;" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20"
      v-if="checkCusRight(direcParam.id,'new')||checkCusRight(direcParam.id,'modify')||checkCusRight(direcParam.id,'delete')||checkCusRight(direcParam.id,'change')">
      <el-col :span="12">
        <el-button-group>
          <el-button @click="formDataNew" type="primary" v-if="checkCusRight(direcParam.id,'new')">新建</el-button>
          <el-button @click="formDataModify" type="primary" v-if="checkCusRight(direcParam.id,'modify')">修改</el-button>
          <el-button @click="formDataDelete" type="primary" v-if="checkCusRight(direcParam.id,'delete')">删除</el-button>
          <el-button @click="formDataContract" type="success" v-if="checkCusRight(direcParam.id,'change')">生成认证合同</el-button>
        </el-button-group>
      </el-col>
      <el-col :span="12">
        <el-tag effect="plain" v-if="this.$store.getters.getProjectOperateData.length>0">历史操作</el-tag><el-tag
          v-for="(item,index) in this.$store.getters.getProjectOperateData" :key="index" @click="tableChangeSearchKey(item)"
          style="cursor: pointer;margin-left:10px;">{{item}}</el-tag>
      </el-col>
    </el-row>

    <el-table ref="projectTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40">
        <template slot-scope="scope">
          <router-link v-if="scope.row.linkProId" :to="{name: 'projectInfo', query: {proId:scope.row.linkProId}}">
            <el-tooltip placement="top" :content="scope.row.linkProCode+'|'+scope.row.linkProName">
              <el-link type="primary">联</el-link>
            </el-tooltip>
          </router-link>
          <span v-else>{{ scope.$index+1}}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="项目名称" show-overflow-tooltip>
        <template slot-scope="scope">
          <router-link :to="{name: 'projectInfo', query: {proId:scope.row.id}}">
            <span v-html="tableLightByKey(scope.row.projectName)"></span></router-link>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="projectCode" label="项目编号" width="200px">
        <template slot-scope="scope">

          <el-link :underline="false" v-if="scope.row.colorMark" :style="'padding:3px;color:white;backgroundColor:'+scope.row.colorMark">
            <span>{{ scope.row.projectCode }}{{ scope.row.markCode }}</span></el-link>
          <el-link :underline="false" v-else>
            <span>{{ scope.row.projectCode }}{{ scope.row.markCode }}</span></el-link>

          <el-dropdown trigger="click" size="small" @command="formDoMark" v-if="checkCusRight(direcParam.id,'mark')">
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{id:scope.row.id,color:'',isMark:false}">默认
                &nbsp;<i v-if="!scope.row.colorMark" class="el-icon-check"></i>
              </el-dropdown-item>
              <el-dropdown-item :command="{id:scope.row.id,color:'#409EFF',isMark:true}">
                <el-tag effect="dark" size="mini">&nbsp;&nbsp;</el-tag>
                &nbsp;<i v-if="scope.row.colorMark=='#409EFF'" class="el-icon-check"></i>
              </el-dropdown-item>
              <el-dropdown-item :command="{id:scope.row.id,color:'#67c23a',isMark:true}">
                <el-tag effect="dark" size="mini" type="success">&nbsp;&nbsp;</el-tag>
                &nbsp;<i v-if="scope.row.colorMark=='#67c23a'" class="el-icon-check"></i>
              </el-dropdown-item>
              <el-dropdown-item :command="{id:scope.row.id,color:'#909399',isMark:true}">
                <el-tag effect="dark" size="mini" type="info">&nbsp;&nbsp;</el-tag>
                &nbsp;<i v-if="scope.row.colorMark=='#909399'" class="el-icon-check"></i>
              </el-dropdown-item>
              <el-dropdown-item :command="{id:scope.row.id,color:'#F56C6C',isMark:true}">
                <el-tag effect="dark" size="mini" type="danger">&nbsp;&nbsp;</el-tag>
                &nbsp;<i v-if="scope.row.colorMark=='#F56C6C'" class="el-icon-check"></i>
              </el-dropdown-item>
              <el-dropdown-item :command="{id:scope.row.id,color:'#e6a23c',isMark:true}">
                <el-tag effect="dark" size="mini" type="warning">&nbsp;&nbsp;</el-tag>
                &nbsp;<i v-if="scope.row.colorMark=='#e6a23c'" class="el-icon-check"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="年份" width="60" prop="projectYear">

      </el-table-column>

      <el-table-column header-align="center" prop="cmpName" label="项目所属" width="140">
        <template slot-scope="scope">
          <el-tooltip placement="top" :content="scope.row.cmpName"><span>{{scope.row.cmpName|cmpFilter}}</span></el-tooltip>
          <el-tooltip v-if="(scope.row.cmpName!=scope.row.signName)" placement="top" :content="scope.row.signName"><span
              style="color:#409EFF"><br />{{scope.row.signName|cmpFilter}}</span></el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="cusName" label="客户|代理公司" width="310">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cusName)"></span>
          <span v-if="(scope.row.cusName!=scope.row.cusProxyCmp)"><br /><span style="color:#409EFF">代理:</span>
            <el-tooltip placement="top" :content="scope.row.cusProxyCmp"><span>{{scope.row.cusProxyCmp|cmpFilter}}</span></el-tooltip>
          </span>
        </template>
      </el-table-column>

      <el-table-column header-align="center" prop="saleName" label="销售" width="100" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top" :content="scope.row.saleName">
            <el-tag>{{scope.row.saleName|saleNameFilter}}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column header-align="center" prop="factRmb" align="right" label="项目总额" width="100">
        <template slot-scope="scope">
          <el-tooltip placement="top" :content="scope.row.inContractRmb+''">
            <el-tag>{{scope.row.inContractRmb|numFilter}}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="inRmb" align="right" label="已收款" width="100">
        <template slot-scope="scope">
          <el-tooltip placement="top" :content="scope.row.inRmb+''">
            <el-tag>{{scope.row.inRmb|numFilter}}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="isFinish" label="状态" width="80">
        <template slot-scope="scope">
          <span style="padding-left: 4px;" v-if="scope.row.isFinish">
            <el-tooltip class="item" effect="light" content="项目结束" placement="top-start">
              <i class="el-icon-folder-checked" style="font-size:24px;color: #409EFF;font-weight: 400;"></i>
            </el-tooltip>
          </span>
          <span style="padding-left: 4px;" v-else>
            <el-tooltip class="item" effect="light" content="未结束" placement="top-start">
              <i class="el-icon-edit-outline" style="font-size:24px;color: #999999;font-weight: 400;"></i>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>
    <!--编辑的表单-->
    <el-dialog v-dialogDrag class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width"
      :top="formDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="projectFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-row :gutter="20" style="padding:0px;">
          <el-col :span="12">
            <el-form-item label="项目名称" prop="projectName"><el-input v-model="formData.projectName" placeholder="请输入项目名称" id="projectName"></el-input>
            </el-form-item>
            <el-form-item label="项目所属" prop="cmpId">
              <el-select v-model="formData.cmpId" filterable placeholder="请选择项目所属主体公司" style="width:100%;" id="cmpId" ref="selectCmp" @change="changeCmp">
                <el-option v-for="item in this.allCompany" :key="'allCompanyMain'+item.id" :label="item.cmpCnName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属销售" prop="saleId">
              <el-select v-model="formData.saleId" filterable placeholder="请选择" style="width:100%;" id="saleId" ref="selectSale" @change="changeSale">
                <el-option v-for="item in this.allOnPosEmp" :key="'allOnPosEmp'+item.id" :label="item.cmpName+'-'+item.depName+'-'+item.empName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-row style="padding:0px;">
              <el-col :span="12">
                <el-form-item label="创建日期" prop="createDate">
                  <el-date-picker v-model="formData.createDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择创建日期" id="createDate"
                    style="width:150px"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否结束">
                  <el-checkbox v-model="formData.isFinish">结束</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">

            <el-row style="padding:0px;">
              <el-col :span="9">
                <el-form-item label="项目年份" prop="projectYear">
                  <el-input-number v-model="formData.projectYear" controls-position="right" :min="2000" :max="2200" id="projectYear"
                    placeholder="请输入项目年份"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="项目编号" prop="projectCode">
                  <el-input v-model="formData.projectCode" placeholder="请输入项目编号" id="projectCode"></el-input>
                </el-form-item></el-col>
              <el-col :span="6">
                <el-form-item label="编号标记" prop="markCode">
                  <el-input v-model="formData.markCode" placeholder="尾注" id="markCode"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="最终客户" prop="cusId">
              <el-select v-model="formData.cusId" filterable placeholder="请选择最终客户" style="width:100%;" ref="selectCus" @change="changeCustomer" id="cusId">
                <el-option v-for="item in this.allCustomer" :key="'allCustomer'+item.id" :label="item.cmpCnName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户代理" prop="cusProxyCmp">
              <el-row style="padding:0px;">
                <el-col :span="20"><el-input id="cusProxyCmp" v-model="formData.cusProxyCmp" placeholder="客户代理公司"></el-input></el-col>
                <el-col :span="4"><el-tooltip content="客户方的代理公司,代为执行签约及收付款工作,默认为客户本身,也可以不填" placement="top" effect="light"><i class="el-icon-info"
                      style="color:#409EFF;margin-left:20px;"></i></el-tooltip></el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注" prop="note">
          <el-input type="textarea" id="note" :rows="4" resize="none" placeholder="请输入详细备注" v-model="formData.note">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="30%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="projectName" label="选定的项目">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-col :span="6" v-if="formChooseDialogParam.colorSelectVisible">
          <span style="line-height:40px;margin-right:3px;color:#409EFF;font-size:16px;">请点击选择颜色</span>
        </el-col>
        <el-col :span="1" v-if="formChooseDialogParam.colorSelectVisible"><el-color-picker v-if="formChooseDialogParam.colorSelectVisible"
            v-model="formChooseDialogParam.colorValue" :predefine="predefineColors" :value="'#FDF5E6'"></el-color-picker>
        </el-col>
        <el-button v-if="!formChooseDialogParam.colorSelectVisible" @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>

    <el-dialog v-dialogDrag class="customFileListCss" :title="contractDialogParam.title" :visible.sync="contractDialogParam.visible"
      :width="contractDialogParam.width" :top="contractDialogParam.top" :close-on-click-modal='false'>

      <el-form ref="contractFormRef" :model="contractData" :rules="contractDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-row :gutter="20" style="padding-bottom: 0px;">
          <el-col :span="12">
            <el-form-item label="所属项目">{{contractData.projectName}}</el-form-item>
            <el-form-item label="签约公司" prop="signName">
              <el-input v-model="contractData.signName" placeholder="请输入签约公司" id="signName"></el-input></el-form-item>
            <el-form-item label="合同编号" prop="contractCode">
              <el-input v-model="contractData.contractCode" placeholder="请输入合同编号" id="contractCode"></el-input>
            </el-form-item>
            <el-form-item label="签订日期" prop="signDate">
              <el-date-picker v-model="contractData.signDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择签订日期" id="signDate"></el-date-picker>
              <span style="margin-left:10px;" v-if="contractData.projectYear">项目年份:{{contractData.projectYear}}</span>
              <el-tooltip content="合同签订年必须大等于项目的年份" placement="top" effect="light"><i class="el-icon-info"
                  style="color:#409EFF;margin-left:10px;"></i></el-tooltip>
            </el-form-item>
            <el-form-item label="备注" prop="note">
              <el-input type="textarea" :rows="3" resize="none" id="note" placeholder="请输入详细备注" v-model="contractData.note">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同所属">{{contractData.cmpName}}
            </el-form-item>
            <el-form-item label="交易对手" prop="cusOrProxyCmp">
              <el-input v-model="contractData.cusOrProxyCmp" placeholder="客户方或客户指定的代理公司" id="cusOrProxyCmp"></el-input>
            </el-form-item>
            <el-form-item label="合同金额" prop="factRmb">
              <el-row style="padding:0px;">
                <el-col :span="8">
                  <el-input-number v-model="contractData.factRmb" controls-position="right" id="factRmb" :precision="2" :step="1" :min="0" :max="2147483647.00"
                    placeholder="请输入实际结算金额"></el-input-number> </el-col>
                <el-col :span="16">
                  {{ contractData.factRmb|numFilter }}
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="审核组长" prop="checkId">
              <el-select v-model="contractData.checkId" ref="selectCheckMan" filterable placeholder="请选择审核组长" style="width:100%;" @change="changeCheckMan">
                <el-option label="未指定" :value="0"></el-option>
                <el-option v-for="item in this.allOnPosEmp" :key="'allOnPosEmp'+item.id" :label="item.cmpName+'-'+item.depName+'-'+item.empName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="合同附件">
              <upload-json-file :inputArray="contractData.addFile" label="合同附件" childDir="contract" @success="addFileSuccessUpload"></upload-json-file>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="contractDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="contractDataSubmit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script> 
import store from '@/store';
import { getCompanyAll, getCompanyMain } from '@/api/utils/Company'
import { getAllOnPosEmp } from '@/api/utils/Employee';
import { getTableData, doNew, doNewContract, doModify, doDelete, doMark, getAllCustomer, findProByCmpId } from '@/api/wbkj/Project'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import { required, len, projectCode, contractCode } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
import UploadJsonFile from '@/components/utils/form/UploadJsonFile.vue';

export default {
  components: { UploadJsonFile },
  data () {
    let checkSignDate = (rule, value, callback) => { //转认证合同时需要判断合同时间需要和项目年份一样
      if (value.substring(0, 4) >= this.formData.projectYear) {
        console.log(value.substring(0, 4))
        callback();
      }
      else {
        callback(new Error('合同签定的的时间必须大于项目年份'));
      }

    };
    var validatLinkProId = (rule, value, callback) => {
      if (this.linkProjectsSelectStatus) {
        callback();
      }
      else if (value === '') {
        callback(new Error('中标公司与项目所属公司不同时,必须选择关联项目'));
      }
    };
    return {
      /*导航栏状态 */

      direcParam: { id: 0, allow: {} },
      //自定义颜色
      predefineColors: [
        '#FDF5E6',
        '#f0f9eb',
        '#409EFF',
        '#67C23A',
        '#E6A23C',
        '#F56C6C',
        '#909399',
        '#000000',
        '#303133',
        '#606266',
        '#909399',
        '#C0C4CC',
      ],
      linkProjectsSelectStatus: false,
      linkProjects: [], //关联项目
      allOnPosEmp: [], //所有的在职公司
      allCompany: [], //所有的公司,包括了关联公司
      allCompanyMain: [], //所以的公司,不包启关联公司,仅主体公司
      allCustomer: [], //公司的所有客户信息
      years: [],
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal',
        'year': 0,
        'saleId': 0
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '70%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: '',
        colorSelectVisible: false,
        colorValue: '',
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        projectCode: '',
        markCode: '',
        colorMark: '',
        projectYear: 0,
        projectName: '',
        cmpId: '',
        cmpName: '',
        cusId: '',
        cusName: '',
        cusProxyCmp: '',
        saleId: '',
        saleName: '',
        signId: '',
        signName: '',
        factRmb: 0.00,
        createDate: new Date(),
        inRmb: 0.00,
        inContractRmb: 0.00,
        outSupplyRmb: 0.00,
        outRmb: 0.00,
        outFundRate: 0.0005,
        outFundRmb: 0.00,
        outOtherRmb: 0.00,
        endOfSupply: false,
        isFinish: false,
        sort: 0.00,
        note: '',
      },
      formDialogRules:
      {
        projectCode: [required, len(20, 8), projectCode],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        projectYear: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        projectName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cusId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cusName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        saleId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        saleName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        factRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        createDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        inRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        outRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        outFundRate: [required],
        outFundRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        outOtherRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        linkProId: [{
          required: true,
          validator: validatLinkProId,
          trigger: 'change'
        }],
        sort: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
      contractDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '70%',
        top: '5vh',
      },
      /*合同原始数据 */
      contractRawData: {
        proId: '',
        projectName: '',
        projectYear: '',
        contractCode: '',
        cusOrProxyCmp: '',
        cmpId: 0,
        cmpName: '',
        signName: '',
        signRmb: 0.00,
        factRmb: 0.00,
        signDate: '',
        supplyInfo: [],
        mayRmb: 0.00,
        inRmb: 0.00,
        addFile: [],
        createId: 0,
        createMan: '',
        checkId: 0,
        checkMan: '',
        track: false,
        trackArray: [],
        resultInfo: {},
        note: '',
      },
      //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将contractData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      contractData: {
        proId: '',
        projectName: '',
        projectYear: '',
        contractCode: '',
        cusOrProxyCmp: '',
        cmpId: 0,
        cmpName: '',
        signName: '',
        signRmb: 0.00,
        factRmb: 0.00,
        signDate: '',
        supplyInfo: [],
        mayRmb: 0.00,
        inRmb: 0.00,
        addFile: [],
        createId: 0,
        createMan: '',
        track: false,
        trackArray: [],
        resultInfo: {},
        note: '',
      },
      contractDialogRules:
      {
        proId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        projectName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        contractCode: [required, contractCode, len(20, 8)],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        factRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signDate: [required],
        // signDate: [required, { validator: checkSignDate, trigger: 'blur' }],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        mayRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        inRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        createId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        createMan: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {




    upDateSupplyInfo (data) {  //上传supplyInfo,更新数据
      this.contractData.supplyInfo = data;
    },

    addFileSuccessUpload (data) { //上传附件成功,更新数据
      this.contractData.addFile = data;
    },
    changeCmp () {
      this.formData.sourceProId = '';
      this.formData.linkProId = '';
      this.formData.linkProCode = '';
      this.formData.linkProName = '';
      this.$nextTick(() => {
        this.formData.cmpName = this.$refs.selectCmp.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
    },
    changeSale () {
      this.$nextTick(() => {
        this.formData.saleName = this.$refs.selectSale.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
    },
    changeCustomer () {
      this.$nextTick(() => {
        this.formData.cusName = this.$refs.selectCus.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
        this.formData.cusProxyCmp = this.$refs.selectCus.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },

    formDataContract () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条项目记录,才能生成认证合同");
      }
      else {
        this.contractData = JSON.parse(JSON.stringify(this.contractRawData));
        let selectProject = JSON.parse(JSON.stringify(this.tableMultipleSelection[0]))
        this.contractData.signName = selectProject.cmpName;
        this.contractData.proId = selectProject.id;
        this.contractData.projectName = selectProject.projectName;
        this.contractData.contractCode = selectProject.projectCode;
        if (selectProject.cusProxyCmp && selectProject.cusProxyCmp.length > 3) {
          this.contractData.cusOrProxyCmp = selectProject.cusProxyCmp;
        }
        else {
          this.contractData.cusOrProxyCmp = selectProject.cusName;
        }
        this.contractData.cmpId = selectProject.cmpId;
        this.contractData.cmpName = selectProject.cmpName;
        this.contractData.factRmb = selectProject.factRmb;
        this.contractData.signRmb = selectProject.signRmb;
        this.contractData.createId = selectProject.saleId
        this.contractData.createMan = selectProject.saleName
        this.contractDialogParam.formDataAction = 'modify';
        this.contractDialogParam.title = '生成认证合同';
        this.contractDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.colorSelectVisible = false;
        this.formChooseDialogParam.colorValue = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    /**历史记录操作功能 */
    pushOperateData (value) {
      const operateData = this.$store.getters.getProjectOperateData;
      if (!operateData.includes(value)) {
        if (operateData.length > 2) {
          operateData.splice(0, 1)
        }
        operateData.push(value);
        this.$store.commit("setProjectOperateData", operateData);
      }
    },


    /*表单(form)-发布示例 */
    /*formDataPublic () {
        if (this.tableMultipleSelection.length < 1) {
            this.notifyInfo("您必须最少选择一条记录,才能发布");
        }
        else {
            this.formChooseDialogParam.title = "将选定的记录,发布/取消发布"
            this.formChooseDialogParam.formChooseAction = 'public';
            this.formChooseDialogParam.action1 = true;
            this.formChooseDialogParam.action1View = '取消发布';
            this.formChooseDialogParam.action2 = true;
            this.formChooseDialogParam.action2View = '发 布';
            this.formChooseDialogParam.visible = true;
        }
    },*/
    formDataValidate () {
      let result = true;
      this.$refs.projectFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.pushOperateData(JSON.parse(JSON.stringify(this.formData.projectCode))); //更新操作
            this.tableDateRefresh();
          }
          else {
            this.formDialogParam.visible = true;
            this.notifyWarning(response.data.msg);

          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.pushOperateData(JSON.parse(JSON.stringify(this.formData.projectCode))); //更新操作
            this.tableDateRefresh();
          }
          else {

            this.formDialogParam.visible = true;
            this.notifyWarning(response.data.msg);

          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formDoMark (colorMark) {
      const data = new FormData()
      data.append('id', colorMark.id);
      data.append('isMark', colorMark.isMark);
      data.append('color', colorMark.color);
      doMark(data).then(response => {
        if (response.data.success) {
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.projectTableRef.clearSelection(); //清除选中
      this.$refs.projectTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();
    },
    tableChangeSearchYear () {
      this.tableParam.page = 1;
      this.tableDateRefresh();
    },
    tableChangeSearchSale () {
      this.tableParam.page = 1;
      this.tableDateRefresh();
    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
        this.tableParam.createId = store.getters.getLoginUser().empId;
      }
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
        this.$store.commit("setProjectSearchData", this.tableParam);

      })
    },
    changeCheckMan () {
      this.$nextTick(() => {
        this.contractData.checkMan = this.$refs.selectCheckMan.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
    },
    /*JsonArray-supplyInfo*/
    contractDataValidate () {
      let result = true;
      this.$refs.contractFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    contractDataSubmit () {
      if (!this.contractDataValidate()) return;
      this.contractDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.contractData));
      postData.supplyInfo = JSON.stringify(this.contractData.supplyInfo);//由于MysqlJson的原因
      postData.addFile = JSON.stringify(this.contractData.addFile);//由于MysqlJson的原因
      postData.trackArray = JSON.stringify(this.contractData.trackArray);//由于MysqlJson的原因
      postData.resultInfo = JSON.stringify(this.contractData.resultInfo);//由于MysqlJson的原因
      doNewContract(postData).then(response => {
        if (response.data.success) {
          this.notifySuccess("认证合同转换成功!");
          this.pushOperateData(JSON.parse(JSON.stringify(this.formData.projectCode))); //更新操作
          this.tableDateRefresh();
        }
        else {
          this.contractDialogParam.visible = true;
          this.notifyWarning(response.data.msg);
        }
      })

    },
    getYears () {
      const currentYear = new Date().getFullYear()
      this.formData.projectYear = currentYear;
      for (let year = currentYear + 1; year >= 2000; year--) {
        this.years.push({ value: year.toString(), key: year.toString() + '年' });
      }
    },
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    let param = {};
    if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
      param.saleId = store.getters.getLoginUser().empId;
    }
    getAllOnPosEmp(param).then(response => {
      if (response.data.success) {
        this.allOnPosEmp = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getCompanyAll().then(response => {
      if (response.data.success) {
        this.allCompany = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getCompanyMain().then(response => {
      if (response.data.success) {
        this.allCompanyMain = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getAllCustomer(param).then(response => {
      if (response.data.success) {
        this.allCustomer = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    this.getYears();
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    if (this.$store.getters.getProjectSearchData) {
      this.tableParam = this.$store.getters.getProjectSearchData;
    }
    this.tableDateRefresh();
  }

}
</script>
