
<template>
  <div>
    <el-row>
      <el-col :span="6">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card class="box-card" body-style="padding:0px" style="margin-bottom:20px" v-for="contract in this.allContract" :key="'allContract'+contract.id">
      <el-row :gutter="20" style="padding-bottom:0px;margin-bottom:0px;">
        <el-col :span="10">
          <div style="height:50px;line-height:50px;padding-left:20px;border-bottom: 1px solid #EBEEF5;"><i class="el-icon-notebook-2" style="color:#67C23A"></i>
            认证合同|{{contract.contractCode}}| {{contract.signDate}}
            <span style="color:#909399;">{{contract.createMan|saleNameFilter}}</span>

            <span style="float:right;margin-right:20px" v-if="checkCusRight(direcParam.id,'trackCancle')">
              <el-button size="mini" type="warning" @click="cancleCheck(contract)" icon="el-icon-star-off"></el-button>&nbsp;
              <span v-if="contract.resultInfo.checkDate">
                <el-tooltip class="item" effect="light" content="结果已确认,提醒短信和邮件已排期,详情请查看[项目跟进]" placement="top-start">
                  <el-button size="mini" type="success" @click="resultInfoEditor(contract)" icon="el-icon-finished"
                    v-if="checkCusRight(direcParam.id,'checkOk')"></el-button>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip class="item" effect="light" content="结果未确认" placement="top-start">
                  <el-button size="mini" type="warning" @click="resultInfoEditor(contract)" icon="el-icon-edit"
                    v-if="checkCusRight(direcParam.id,'checkOk')"></el-button>
                </el-tooltip>
              </span>
            </span>
          </div>
          <contract-view :contractData="contract"></contract-view> <!-- 合同看板 -->
        </el-col>
        <el-col :span="14">
          <el-tabs value="pane1" tab-position="top" style="height: 328px; margin-top:10px;">
            <el-tab-pane name="pane1"><span slot="label"><i class="el-icon-data-analysis" style="color:#ef0000;" title="合同统计"> 合同统计</i></span>
              <contract-echart :contractId="contract.id" :ref="'contractEchart'+contract.id"></contract-echart> <!-- 认证合同Echart -->
            </el-tab-pane>
            <el-tab-pane name="pane2"><span slot="label"><i class="el-icon-copy-document" style="color:#409EFF;" title="收款条件"> 合同条款</i></span>
              <contract-clause :contractData="contract" :contractId="contract.id" @resultUpdate="contractClauseUpdate"
                :allowOperate="checkCusRight(direcParam.id,'contractTk')"></contract-clause>
            </el-tab-pane>
            <el-tab-pane name="pane3"><span slot="label"><i class="el-icon-money" style="color:#67C23A;" title="收款记录"> 收款记录</i></span>
              <contract-in :contractData="contract" :contractId="contract.id" @resultUpdate="contractInUpdate"
                :allowOperate="checkCusRight(direcParam.id,'contractSk')"></contract-in>
            </el-tab-pane>
            <el-tab-pane name="pane4"><span slot="label"><i class="el-icon-postcard" style="color:#E6A23C" title="开票记录"> 开票记录</i></span>
              <contract-invoice :contractData="contract" :contractId="contract.id" @resultUpdate="contractInvoiceUpdate"
                :allowOperate="checkCusRight(direcParam.id,'contractKp')"></contract-invoice>
            </el-tab-pane>
            <el-tab-pane name="pane5"><span slot="label"><i class="el-icon-phone-outline" style="color:#67C23A" title="跟踪记录"> 跟踪记录</i></span>
              <track-array-field :allowOperate="checkCusRight(direcParam.id,'contractTa')" :jsonArray="contract.trackArray" :upDataId="contract.id"
                @success="upDataContractTrack"></track-array-field>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-card>
    <contract-result :resultInfoParam="resultInfoParam" :resultEdit="resultInfoParam.resultInfo" :empId="resultInfoParam.empId" :proId="resultInfoParam.proId"
      :contractId="resultInfoParam.contractId" @success="contractResultInfoRefresh()"></contract-result>
  </div>
</template>
<script> import store from '@/store';


//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getAllContractTrack } from '@/api/wbkj/Track'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import ContractView from '@/components/utils/contract/ContractView.vue';
import ContractEchart from '@/components/utils/contract/ContractEchart.vue';
import ContractClause from '@/components/utils/contract/ContractClause.vue';
import ContractIn from '@/components/utils/contract/ContractIn.vue';
import ContractInvoice from '@/components/utils/contract/ContractInvoice.vue';
import ContractResult from '@/components/utils/contract/ContractResult.vue';
import TrackArrayField from '@/components/utils/contract/TrackArrayField.vue';
import { doContractTrackArray, doMark } from '@/api/wbkj/Contract.js'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { ContractView, ContractEchart, ContractClause, ContractIn, ContractInvoice, ContractResult, TrackArrayField },
  data () {
    //这里存放数据
    return {
      resultInfoParam: //执行结果信息
      {
        visible: false,
        title: '确定执行结果',
        formDataAction: '',
        width: '50%',
        top: '5vh',
        empId: 0,
        proId: 0,
        contractId: 0,
        resultInfo: {}

      },
      direcParam: {
        'directName': '项目销售收款跟踪'
      },
      allContract: [], //所有认证合同
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
  },
  //方法集合
  methods: {

    cancleCheck (contract) {
      this.$confirm('是否确认取消跟踪,取消后该列表将不再显示此数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = new FormData()
        data.append('id', contract.id);
        data.append('track', false);
        doMark(data).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            let param = {};
            getAllContractTrack(param).then(response => {
              if (response.data.success) {
                this.allContract = response.data.data;

              }
              else {
                this.notifyWarning(response.data.msg);
              }
            });
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
      //
    },
    resultInfoEditor (contract) {
      let row = JSON.parse(JSON.stringify(contract));
      this.resultInfoParam.resultInfo = JSON.parse(JSON.stringify(row.resultInfo));
      this.resultInfoParam.resultInfo.contractId = JSON.stringify(row.id);
      this.resultInfoParam.empId = row.createId;
      this.resultInfoParam.proId = row.proId;
      this.resultInfoParam.contractId = row.id;
      this.resultInfoParam.visible = true;
    },
    /*子传父-contractClause子组件传过来的值更新的认证合同综合统计*/
    contractClauseUpdate (contractId) {
      this.$refs['contractEchart' + contractId][0].updateEchart();//更新认证合同echart看板
    },
    /*子传父-contractIn子组件传过来的值,更新合同的收款和项目的总收款*/
    contractInUpdate (contractId, contractInRmb) {
      let updateContract = {};
      updateContract = this.allContract.find(item => item.id == contractId);
      updateContract.inRmb = contractInRmb;
      this.$refs['contractEchart' + contractId][0].updateEchart();//更新认证合同echart看板
    },
    /*子传父-contractInvoice子组件传过来的值,更新合同的发票*/
    contractInvoiceUpdate (contractId, contractMayRmb) {
      let updateContract = {};
      updateContract = this.allContract.find(item => item.id == contractId);
      updateContract.mayRmb = contractMayRmb;
      this.$refs['contractEchart' + contractId][0].updateEchart();//更新认证合同echart看板
    },
    /*子传父-supplyInfo子组件传过来的值,更新合同的跟踪信息*/
    upDataContractTrack (trackInfo, contractId) {
      const postData = {};
      postData.contractId = contractId;
      postData.trackArray = JSON.stringify(trackInfo);//由于MysqlJson的原因
      doContractTrackArray(postData).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

    this.direcParam = JSON.parse(store.getters.getActiveCus());
    let param = {};
    if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
      param.saleId = store.getters.getLoginUser().empId;
    }
    getAllContractTrack(param).then(response => {
      if (response.data.success) {
        this.allContract = response.data.data;

      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发

}
</script>