<!--其它费用记录-->
<template>
  <div>

    <el-table ref="msgLogTableRef" style="width: 100%;margin:0px;margin-bottom: 20px;" height="250" size="mini" :data="msgLogArray">

      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="cusName" label="客户名称" show-overflow-tooltip>
      </el-table-column>
      <el-table-column header-align="center" label="手机|邮件" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.noteType=='msg'">{{scope.row.mobile}}</span>
          <span v-else>{{scope.row.email}}</span>
        </template>

      </el-table-column>
      <el-table-column header-align="center" prop="sendNote" label="发送内容" show-overflow-tooltip>
      </el-table-column>
      <el-table-column header-align="center" prop="checkDate" label="通知审核时间" width="120"></el-table-column>
      <el-table-column header-align="center" prop="noteTime" label="计划通知时间" show-overflow-tooltip></el-table-column>

      <el-table-column header-align="center" prop="empName" label="联系人" show-overflow-tooltip>
        <template slot-scope="scope">
          {{scope.row.empName}}-{{scope.row.empWorkPhone}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="isSend" label="执行状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.isSend">
            <i class="el-icon-finished" style="color:#409EFF"> {{ scope.row.sendTime }}</i>
          </span>
          <span v-else>
            <el-button @click="nowMsgLog(scope.row)" icon="el-icon-time" type="text" size="small">立即执行</el-button>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" v-if="this.allowOperate">
        <template slot-scope="scope">
          <el-tag @click="newMsgLog(scope.row)" effect="dark" size="mini" style="margin-left: 5px;cursor: pointer;">复制新建</el-tag>
          <el-tag v-if="scope.row.noteType=='email'" @click="modifyMsgLog(scope.row)" effect="dark" size="mini"
            style="margin-left: 5px;cursor: pointer;">修改</el-tag>
          <el-tag v-if="!scope.row.isSend" @click="delMsgLog(scope.row)" type="danger" effect="dark" size="mini"
            style="margin-left: 10px;cursor: pointer;">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-dialogDrag class="customFileListCss" :title="msgLogDialogParam.title" :visible.sync="msgLogDialogParam.visible"
      :width="msgLogDialogParam.width" :top="msgLogDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="msgLogFormRef" :model="msgLogData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->

        <el-form-item label="客户名称">{{ msgLogData.cusName }}
        </el-form-item>
        <el-form-item label="手机|邮件">
          <span v-if="msgLogData.noteType=='msg'">{{msgLogData.mobile}}</span>
          <span v-else>{{msgLogData.email}}</span>
        </el-form-item>
        <el-form-item label="注意事项">短信由于模板限制发送内容不可修改,邮件可以.</el-form-item>
        <el-form-item label="发送内容" prop="sendNote">
          <el-input v-model="msgLogData.sendNote" type="textarea" :rows="5" resize="none" placeholder="请输入发送内容"
            :readonly="msgLogData.noteType=='msg'"></el-input>
        </el-form-item>
        <el-form-item label="通知时间" prop="noteTime">
          <el-date-picker v-model="msgLogData.noteTime" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择计划通知时间"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="msgLogDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="msgLogDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script> 
import { doNew, doModify, doDelete, rightNowSend, getMsgLogByProId } from '@/api/wbkj/MsgLog';
import { required, len } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  props: ["proId", "allowOperate"],
  data () {
    return {
      msgLogDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        msgLogDataAction: '',
        width: '30%',
        top: '5vh',
      },
      /*原始数据 */
      msgLogRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将msgLogData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      msgLogData: {
        proId: 0,
        projectName: '',
        cusName: '',
        mobile: '',
        email: '',
        sendNote: '',
        noteTime: '',
        noteType: '',
        checkDate: new Date(),
        empName: '',
        empWorkPhone: '',
        isSend: false,
        sendTime: '',

      },
      formDialogRules:
      {
        proId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        projectName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cusName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        mobile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        email: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        sendNote: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        noteTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        noteType: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        checkDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empWorkPhone: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isSend: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        sendTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
      msgLogArray: [],
    };
  },
  methods: {
    //-----------------------其它费用功能---------------------------
    newMsgLog (row) { //新建其它费用
      this.msgLogData = JSON.parse(JSON.stringify(row));//深拷贝一份原始数据到msgLogData,就相当于重置表单了
      this.msgLogDialogParam.title = '复制新建';
      this.msgLogDialogParam.action = 'new';
      this.msgLogDialogParam.visible = true;
    },
    modifyMsgLog (row) { //修改其它费用
      this.msgLogData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到msgLogData,就相当于重置表单了
      this.msgLogDialogParam.title = '修改';
      this.msgLogDialogParam.action = 'modify';
      this.msgLogDialogParam.visible = true;
    },
    delMsgLog (row) { //删除其它费用
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.msgLogData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到msgLogData,就相当于重置表单了
        doDelete(this.msgLogData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.refreshData();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
      //
    },
    nowMsgLog (row) { //立即发送
      this.$confirm('是否确认立即发送?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.msgLogData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到msgLogData,就相当于重置表单了
        rightNowSend(this.msgLogData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.refreshData();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
      //
    },
    msgLogDataValidate () { //验证其它费用
      let result = true;
      this.$refs.msgLogFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    msgLogDataSubmit () {
      if (!this.msgLogDataValidate()) {
        this.notifyError("验证未通过");
        return;
      }
      this.msgLogDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.msgLogData));
      if (this.msgLogDialogParam.action == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.refreshData();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.msgLogDialogParam.action == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.refreshData();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
    },
    //-----------------------刷新数据---------------------------
    refreshData () {
      getMsgLogByProId({ proId: this.proId }).then(response => {
        if (response.data.success) {
          this.msgLogArray = response.data.data;
          console.log(this.msgLogArray);
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    }
  },
  watch: {
    proId: function () {
      getMsgLogByProId({ proId: this.proId }).then(response => {
        if (response.data.success) {
          this.msgLogArray = response.data.data;
          console.log(this.msgLogArray);
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    }
  },
  mounted () {
    this.msgLogRawData = JSON.parse(JSON.stringify(this.msgLogData));
  },
}
</script>