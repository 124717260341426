import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Contract接口,数据表接口
export const getAllProject = (params) => { //得到所有项目信息
    return httpJson.get('/project/allByYear', { 'params': params })
}
export const getAllByTech = (params) => { //得到所有项目信息
    return httpJson.get('/project/allByTech', { 'params': params })
}
export const getOwnerProject = (params) => { //得到仅公司自己的所有项目信息
    return httpJson.get('/project/ownerByYear', { 'params': params })
}
export const getAllProjectByCus = (params) => { //得到客户的所有项目信息
    return httpJson.get('/project/allByYearAndCus', { 'params': params })
}
export const getAllContract = (params) => {
    return httpJson.get('/contract/allByPro', { 'params': params })
}
export const getAllSupply = (params) => {
    return httpJson.get('/supply/allByPro', { 'params': params })
}
/*认证合同统计 */
export const getContractRmbCount = (param) => {
    return httpJson.get('/contract/rmbCount', { 'params': param })
}
/*认证合同移动 */
export const doContractMove = (formData) => {
    return httpForm.post('/contract/move', formData)
}