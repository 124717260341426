<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="checkCusRight(direcParam.id,'allData')?13:19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="2" style="text-align: right;line-height:36px;font-weight:bold;">
        统计年份
      </el-col>
      <el-col :span=3>
        <el-select v-model="treeTableParam.year" placeholder="选择年份" style="width:100%;" @change="changeSelectYear">
          <el-option v-for="item in years" :key="item.value" :label="item.key" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span=6 v-if="checkCusRight(direcParam.id,'allData')">
        <el-select v-model="treeTableParam.saleId" filterable placeholder="请选择员工" style="width:100%;" @change="changeSelectSale">
          <el-option :value="0" label="全部销售人员"></el-option>
          <el-option v-for="item in this.allOnPosEmp" :key="'allOnPosEmp'+item.id" :label="item.cmpName+'-'+item.depName+'-'+item.empName+'-'+item.empCode"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>

    </el-row>
    <el-table ref="yearCountTableRef" :border="true" :height="tableHeight" :cell-style="{height:'44px',padding:0,margin:0}" :data="treeTableData"
      tooltip-effect="dark" row-key="uid" style="width: 100%;" :highlight-current-row="true" :load="treeTableLoadNode" lazy
      :tree-props="{children: 'children', hasChildren: 'isNode'}" :indent="20" :summary-method="getSummaries" show-summary>
      <el-table-column header-align="center" type="index" width="50"></el-table-column>
      <el-table-column header-align="center" prop="linkName" label="名称" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.isProject">
            <span><i class="el-icon-box" style="color:#67C23A"></i>
              {{scope.row.saleName|saleNameFilter}}-{{scope.row.projectCode}}{{scope.row.markCode}}|{{ scope.row.projectName}}
            </span>
          </span>
          <span v-else-if="scope.row.isContract">
            <i class="el-icon-notebook-2" style="color:#67C23A"></i> 认证合同:{{ scope.row.contractCode}}
          </span>
          <span v-else-if="scope.row.isCostOut">
            <i class="el-icon-wallet" style="color: rgb(245, 108, 108);"></i> 其它费用:{{ scope.row.outRmbType}}-{{ scope.row.outRmbName}}
          </span>
          <span v-else-if="scope.row.isContractRmb">发生日期:{{ scope.row.countDate}}
            <span v-if="scope.row.signRmb"><i class="el-icon-copy-document" style="color:#409EFF;margin-left: 10px;" title="条款"> 条款</i></span>
            <span v-if="scope.row.invoiceRmb"><i class="el-icon-postcard" style="color:#E6A23C;margin-left: 10px;" title="开票"> 开票</i></span>
            <span v-if="scope.row.inRmb"><i class="el-icon-money" style="color:#67C23A;margin-left: 10px;" title="收款"> 收款</i></span>
            <span v-if="scope.row.outRmb"><i class="el-icon-money" style="color:#ef0000;margin-left: 10px;" title="支出"> 支出</i></span>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" prop="inContractRmb" label="合同额" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.isProject">{{scope.row.inContractRmb|numFilter}}</span>
          <span v-else-if="scope.row.isContract">{{scope.row.mayRmb|numFilter}}</span>
          <span v-else-if="scope.row.isContractRmb">{{ scope.row.signRmb|numFilter}}</span>

        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" prop="mayRmb" label="开票额" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.isProject">{{scope.row.mayRmb|numFilter}}</span>
          <span v-else-if="scope.row.isContract">{{scope.row.mayRmb|numFilter}}</span>
          <span v-else-if="scope.row.isContractRmb">{{ scope.row.invoiceRmb|numFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" prop="inRmb" label="已收款" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.isProject">{{scope.row.inRmb|numFilter}}</span>
          <span v-else-if="scope.row.isContract">{{scope.row.inRmb|numFilter}}</span>
          <span v-else-if="scope.row.isContractRmb">{{ scope.row.inRmb|numFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" prop="outRmb" label="合同支出" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.isProject">{{scope.row.outRmb|numFilter}}</span>
          <span v-else-if="scope.row.isContract">{{scope.row.outRmb|numFilter}}</span>
          <span v-else-if="scope.row.outRmb">{{ scope.row.outRmb|numFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" prop="outOtherRmb" label="其它费用" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.isProject">{{scope.row.outOtherRmb|numFilter}}</span>
          <span v-else-if="scope.row.isContract"></span>
          <span v-else-if="scope.row.isCostOut">{{scope.row.outRmb|numFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" label="利润">
        <el-table-column header-align="center" align="right" prop="profitRmb" label="利润额" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.isProject">{{scope.row.profitRmb|numFilter}}</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="right" prop="factProfitRmb" label="当前利润" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.isProject">{{scope.row.factProfitRmb|numFilter}}</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <template slot-scope="scope">
          <span>{{scope.row.signRmb|numFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="备注" width="280" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.isProject">客户:{{ scope.row.cusName }} - 公司:{{ scope.row.cmpName }}</span>
          <span v-if="scope.row.isCostOut">{{ scope.row.checkNote }}</span>
          <span>{{ scope.row.note }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script> import store from '@/store';
import { getAllOnPosEmp } from '@/api/utils/Employee';
import { findDataBy } from '@/api/utils/YearCount.js'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
export default {
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '项目年报'
      },
      allOnPosEmp: [],
      /*表格参数 */
      treeTableParam: {
        level: 0,
        year: '0',
        parentId: 0,
        saleId: 0,
        type: 'project',
      },//表格的传参
      /*表格数据 */
      treeTableData: [],
      years: [],
      tableHeight: window.innerHeight - 160
    }
  },

  updated () {
    this.$nextTick(() => {
      this.$refs.yearCountTableRef.doLayout();
    })
  },
  methods: {
    changeSelectYear () {
      this.treeTableDateInit(0);
    },
    changeSelectSale () {
      this.treeTableDateInit(0);
    },
    getSummaries (param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if (index === 1) {
          sums[index] = '全年合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.numFilter(sums[index]);


        } else {
          sums[index] = '';
        }
      });

      return sums;
    },

    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    treeTableSelectionChange (val) {
      this.treeTableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    treeTableRowClick (row) {
      this.formData = JSON.parse(JSON.stringify(row));
      this.$refs.yearCountTableRef.clearSelection(); //清除选中
      this.$refs.yearCountTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-数据初使化 */
    treeTableDateInit (parentId) {
      if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
        this.treeTableParam.saleId = store.getters.getLoginUser().empId;
      }
      this.treeTableParam.parentId = parentId;
      this.treeTableParam.level = 0;
      this.treeTableParam.type = 'project';
      findDataBy(this.treeTableParam).then((response) => {
        if (response.data.success) {
          this.treeTableData = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    //load事件,展开事件调用此方法,当展开时记录当前的结点的resolve,利用Hash的方法记录结点ID,以便新建修改,删除时,刷新父结点
    treeTableLoadNode (tree, treeNode, resolve) {
      // 请求api接口获取数据
      if (tree.isProject) {
        this.treeTableParam.level = 1;
        this.treeTableParam.type = 'project';
        this.treeTableParam.parentId = tree.id;
      }
      else if (tree.isContract) {
        this.treeTableParam.level = 2;
        this.treeTableParam.type = 'contract';
        this.treeTableParam.parentId = tree.id;
      }
      else if (tree.isSupply) {
        this.treeTableParam.level = 2;
        this.treeTableParam.type = 'supply';
        this.treeTableParam.parentId = tree.id;
      }
      findDataBy(this.treeTableParam).then((response) => {
        if (response.data.success) {
          resolve(response.data.data);
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })

    },

    numFilter (value) {
      if (value) {
        if (Math.abs(parseFloat(value / 1000)).toFixed(2) > 5) {
          return parseFloat(value / 10000).toFixed(2) + '万'
        }
        else {
          return parseFloat(value).toFixed(2);
        }
      }
      else {
        return "0.00";
      }
    },
    getYears () {
      const currentYear = new Date().getFullYear()
      this.treeTableParam.year = currentYear.toString();
      this.years.push({ value: '0', key: '全部' });
      for (let year = currentYear + 1; year >= 2000; year--) {

        this.years.push({ value: year.toString(), key: year.toString() + '年' });
      }
    },


  },
  watch: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.getYears();
    getAllOnPosEmp().then(response => {
      if (response.data.success) {
        this.allOnPosEmp = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    this.treeTableDateInit(0);
    this.tableHeight = window.innerHeight;
  }
}
</script>
