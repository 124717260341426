<!--  -->
<template>
  <el-card class="box-card" style="margin-bottom:20px;">
    <div slot="header" class="clearfix" style="font-weight: bold;">
      <el-row style="margin-bottom:0px;padding-bottom:0px;">
        <el-col :span="10"><span v-if="(saleCount.task)&&!isNaN(saleCount.task.taskYear)">
            {{saleCount.task.taskYear}}年</span>{{saleCount.saleName}}的业绩
          项目总额:{{saleCount.inContractRmb|numFilter}}
          <span style="color:#909399;font-size:14px">&nbsp;{{saleCount.inContractRmb|nullNumFilter}}</span>&nbsp;&nbsp;
          利润额:{{(saleCount.inContractRmb-saleCount.outOtherRmb)|numFilter}}
          <span style="color:#909399;font-size:14px">&nbsp;{{(saleCount.inContractRmb-saleCount.outOtherRmb)|nullNumFilter}}</span>

        </el-col>
        <el-col :span="3">&nbsp;</el-col>
      </el-row>
    </div>
    <div class="text item">
      <el-form ref="projectFormRef" label-width="80px" size="mini">
        <el-row style="margin-bottom:0px;padding-bottom:0px;">
          <el-col :span="3">
            <el-form-item label="项目">
              <el-link :underline="false">
                <span style="color: #409EFF;">{{saleCount.num }}</span> 个</el-link>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="合同总额">
              <el-tooltip content="认证合同的实际签约额" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF">&nbsp;</i></el-tooltip>
              <el-tooltip :content="saleCount.inContractRmb|nullNumFilter" placement="top" effect="light">
                <span style="color: #67C23A;">{{saleCount.inContractRmb|numFilter}}</span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="已收款">
              <el-tooltip :content="saleCount.inRmb|nullNumFilter" placement="top" effect="light">
                <span style="color: #67C23A;">{{saleCount.inRmb|numFilter}}</span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="未收款">
              <el-tooltip :content="(saleCount.inContractRmb-saleCount.inRmb)|nullNumFilter" placement="top" effect="light">
                <span style="color: #67C23A;">{{(saleCount.inContractRmb-saleCount.inRmb)|numFilter}}</span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="收款率">{{countPercentage(saleCount.inRmb,saleCount.inContractRmb)}}%
            </el-form-item>

          </el-col>
          <el-col :span="3">
            <el-form-item label="合同支出">
              <el-tooltip :content="saleCount.outRmb|nullNumFilter" placement="top" effect="light">
                <span style="color: #F56C6C;">{{saleCount.outRmb|numFilter}}</span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="其它费用">
              <el-tooltip :content="saleCount.outOtherRmb|nullNumFilter" placement="top" effect="light">
                <span style="color: #F56C6C;">{{saleCount.outOtherRmb|numFilter}}</span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="当前利润" style="font-size:24px;">
              <el-tooltip content="合同收款-合同支出-其它费用" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF">&nbsp;</i></el-tooltip>
              <el-tooltip :content="(saleCount.inRmb-saleCount.outRmb-saleCount.outOtherRmb)|nullNumFilter" placement="top" effect="light">
                <span style="color: #409EFF;">{{(saleCount.inRmb-saleCount.outRmb-saleCount.outOtherRmb)|numFilter}}</span>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
</template>

<script> 
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  props: ["saleCount"],
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>