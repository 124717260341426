import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Customer接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/customer/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/customer/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/customer/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/customer/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/customer/delete', formData)
}



