
<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="checkCusRight(direcParam.id,'allData')?7:13">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="6" style="text-align: right;line-height:36px;font-weight:bold;">&nbsp;
        {{projectCusTotal.saleName}}
      </el-col>
      <el-col :span="2" style="text-align: right;line-height:36px;font-weight:bold;">
        统计年份
      </el-col>
      <el-col :span="3">
        <el-select v-model="projectCusTotal.selectYear" placeholder="选择年份" style="width:100%" @change="changeProjectYear">
          <el-option v-for="item in years" :key="item.value" :label="item.key" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" v-if="checkCusRight(direcParam.id,'allData')">
        <el-select v-model="projectCusTotal.saleId" filterable placeholder="请选择" style="width:100%;" ref="selectSale" @change="changeProjectSale">
          <el-option label="全部销售人员" :value="0"></el-option>
          <el-option v-for="item in this.allOnPosEmp" :key="'allOnPosEmp'+item.id" :label="item.cmpName+'-'+item.depName+'-'+item.empName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-card class="box-card" style="margin-bottom:20px;">
      <div slot="header" class="clearfix">
        <span v-if="this.projectCusTotal.selectYear<2000" style="color:#409EFF;font-weight: bold;font-size:20px;">全部</span>
        <span v-else style="color:#409EFF;font-weight: bold;font-size:20px;">{{this.projectCusTotal.selectYear}}年</span>
        <span style="font-weight: bold;font-size:20px;margin-right:30px;">签约项目:{{ this.projectCusTotal.total_num}}个</span>
        <span style="font-weight: bold;font-size:20px;margin-right:30px;">
          签约总额:{{ this.projectCusTotal.total_inContractRmb|numFilter}}
          <span style="color:#909399;font-size:16px">
            &nbsp;{{projectCusTotal.total_inContractRmb|nullNumFilter}}
          </span>
        </span>
        <span style="font-weight: bold;font-size:20px;margin-right:30px;">
          <el-tooltip content="合同支出+其它费用" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF"></i></el-tooltip>
          支出总额:
          {{(this.projectCusTotal.total_outRmb+this.projectCusTotal.total_outOtherRmb)|numFilter}}
          <span style="color:#909399;font-size:16px">
            &nbsp;{{(this.projectCusTotal.total_outRmb+this.projectCusTotal.total_outOtherRmb)|nullNumFilter}}
          </span>
        </span>
        <span style="font-weight: bold;font-size:20px;margin-right:30px;">
          <el-tooltip content="签约总额-支出总额" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF"></i></el-tooltip>
          利润总额:{{ (this.projectCusTotal.total_inContractRmb-this.projectCusTotal.total_outOtherRmb)|numFilter}}
          <span style="color:#909399;font-size:16px">
            &nbsp;{{(this.projectCusTotal.total_inContractRmb-this.projectCusTotal.total_outOtherRmb)|nullNumFilter}}
          </span>
        </span>
      </div>
      <div class="text item">
        <el-row>
          <el-col :span="3">
            <span style="font-weight: bold;">合同总额: </span>
            <el-tooltip :content="this.projectCusTotal.total_inContractRmb|nullNumFilter" placement="top" effect="light">
              <span style="color: #67c23a;">{{ this.projectCusTotal.total_inContractRmb|numFilter }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="3">
            <span>合同收款: </span>
            <el-tooltip :content="this.projectCusTotal.total_inRmb|nullNumFilter" placement="top" effect="light">
              <i class="el-icon-notebook-2" style="color: #67c23a;"> {{ this.projectCusTotal.total_inRmb|numFilter }}</i>
            </el-tooltip>
          </el-col>
          <el-col :span="3">
            <span>合同未收: </span>
            <el-tooltip :content="(this.projectCusTotal.total_inContractRmb-this.projectCusTotal.total_inRmb)|nullNumFilter" placement="top" effect="light">
              <span style="color: #67c23a;">
                {{ (this.projectCusTotal.total_inContractRmb-this.projectCusTotal.total_inRmb)|numFilter }}
              </span>
            </el-tooltip>
          </el-col>
          <el-col :span="3">收款率:{{ this.projectCusTotal.total_inRmb*100/this.projectCusTotal.total_inContractRmb.toFixed(2)|numFilter}}%</el-col>
          <el-col :span="3">
            <span style="font-weight: bold;">合同支出: </span>
            <el-tooltip :content="this.projectCusTotal.total_outRmb|nullNumFilter" placement="top" effect="light">
              <span style="color: #F56C6C;">{{ this.projectCusTotal.total_outRmb|numFilter }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="3">
            <span style="font-weight: bold;">其它费用: </span>
            <el-tooltip :content="this.projectCusTotal.total_outOtherRmb|nullNumFilter" placement="top" effect="light">
              <span style="color: #F56C6C;">{{ this.projectCusTotal.total_outOtherRmb|numFilter }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="5">
            <span style="font-weight:bold;font-size:18px;">当前总利润:</span>
            <el-tooltip content="合同收款-其它费用" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF"></i></el-tooltip>
            {{ this.projectCusTotal.total_inRmb-(this.projectCusTotal.total_outOtherRmb)|numFilter}}
            <span style="color:#909399;font-size:14px">
              &nbsp;{{this.projectCusTotal.total_inRmb-(this.projectCusTotal.total_outOtherRmb)|nullNumFilter}}</span>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <pro-cus-view v-for="projectData in this.allCusProject" :project="projectData" :key="'projectView'+projectData.id"></pro-cus-view>
  </div>
</template>
<script> import store from '@/store';


//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getAllProjectByCus } from '@/api/utils/ProjectInfo.js'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import { getAllOnPosEmp } from '@/api/utils/Employee';
import ProCusView from '@/components/utils/pro/ProCusView.vue';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { ProCusView },
  data () {
    //这里存放数据
    return {
      direcParam: {
        'directName': '客户分析统计'
      },
      colors: [
        { color: '#f56c6c', percentage: 20.0 },
        { color: '#e6a23c', percentage: 40.0 },
        { color: '#5cb87a', percentage: 60.0 },
        { color: '#1989fa', percentage: 80.0 },
        { color: '#6f7ad3', percentage: 100.0 },
      ],
      years: [],
      allCusProject: [],
      allOnPosEmp: [], //所有的在职公司
      /*初使化数据 */
      projectCusTotal: {
        selectYear: '',
        saleId: 0,
        saleName: '',
        total_num: 0,
        total_count: 0,
        total_factRmb: 0.00,
        total_inRmb: 0.00,
        total_inContractRmb: 0.00,
        total_outSupplyRmb: 0.00,
        total_outRmb: 0.00,
        total_outFundRmb: 0.00,
        total_outOtherRmb: 0.00,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {
  },
  //监控data中的数据变化
  watch: {
  },
  //方法集合
  methods: {
    /*图表引入 */
    changeProjectYear (year) {
      if (year > 20) {
        this.projectCusTotal.selectYear = year;
      }
      else {
        this.projectCusTotal.selectYear = 0;
      }
      this.getProjects();
    },
    changeProjectSale () {
      this.$nextTick(() => {
        this.projectCusTotal.saleName = this.$refs.selectSale.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
      this.getProjects();
    },
    getProjects () {
      let param = { year: JSON.parse(this.projectCusTotal.selectYear), saleId: JSON.parse(this.projectCusTotal.saleId) };
      if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
        param.saleId = store.getters.getLoginUser().empId;
      }
      getAllProjectByCus(param).then(response => {
        if (response.data.success) {
          this.allCusProject = response.data.data;
          this.projectCusTotal.total_num = 0;
          this.projectCusTotal.total_factRmb = 0;
          this.projectCusTotal.total_inRmb = 0;
          this.projectCusTotal.total_inContractRmb = 0;
          this.projectCusTotal.total_outSupplyRmb = 0;
          this.projectCusTotal.total_outRmb = 0;
          this.projectCusTotal.total_outOtherRmb = 0;
          this.projectCusTotal.total_outFundRmb = 0;
          //this.projectCusTotal.selectYear = selectYear;
          for (let key in this.allCusProject) {

            this.projectCusTotal.total_num += this.allCusProject[key].num;
            this.projectCusTotal.total_factRmb += this.allCusProject[key].factRmb;
            this.projectCusTotal.total_inRmb += this.allCusProject[key].inRmb;
            this.projectCusTotal.total_inContractRmb += this.allCusProject[key].inContractRmb;
            this.projectCusTotal.total_outSupplyRmb += this.allCusProject[key].outSupplyRmb;
            this.projectCusTotal.total_outRmb += this.allCusProject[key].outRmb;
            this.projectCusTotal.total_outOtherRmb += this.allCusProject[key].outOtherRmb;
            this.projectCusTotal.total_outFundRmb += this.allCusProject[key].outFundRmb;
          }

        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });

    },
    getYears () {
      const currentYear = new Date().getFullYear();
      this.projectCusTotal.selectYear = currentYear.toString();
      this.years.push({ value: 0, key: '全部' });
      for (let year = currentYear + 1; year >= 2000; year--) {
        this.years.push({ value: year.toString(), key: year.toString() + '年' });
      }
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.getYears();
    this.getProjects(this.projectCusTotal.selectYear);
    let param = {};
    if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
      param.saleId = store.getters.getLoginUser().empId;
    }
    getAllOnPosEmp(param).then(response => {
      if (response.data.success) {
        this.allOnPosEmp = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发

}
</script>