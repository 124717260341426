import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个MsgLog接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/msgLog/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/msgLog/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/msgLog/new', formData)
}
export const rightNowSend = (formData) => {
    return httpForm.post('/msgLog/rightNow', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/msgLog/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/msgLog/delete', formData)
}
export const getMsgLogByProId = (param) => {
    return httpJson.get('/msgLog/listByProId', { 'params': param })
}




