<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="checkCusRight(direcParam.id,'allData')?6:13">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="2" style="text-align: right;"><el-button @click="changeOrderBy"><i class="el-icon-sort"></i>
          <span v-if="treeTableParam.orderBy=='DESC'">倒序</span>
          <span v-if="treeTableParam.orderBy=='ASC'">正序</span>
        </el-button></el-col>
      <el-col :span="3">

        <el-select v-model="selectYear" placeholder="选择年份" style="width:100%" @change="changeSelectYear">
          <el-option v-for="item in years" :key="item.value" :label="item.key" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-select v-model="treeTableParam.cmpId" filterable placeholder="请选择" style="width:100%;" @change="changeSelectCmpId">
          <el-option label="全部签约公司" :value="0"></el-option>
          <el-option v-for="item in this.mainCmp" :key="'company'+item.id" :label="item.cmpCnName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="7" v-if="checkCusRight(direcParam.id,'allData')">
        <el-select v-model="treeTableParam.createId" filterable placeholder="请选择" style="width:100%;" @change="changeSelectCreateId">
          <el-option label="全部销售人员" :value="0"></el-option>
          <el-option v-for="item in this.allOnPosEmp" :key="'allOnPosEmp'+item.id" :label="item.cmpName+'-'+item.depName+'-'+item.empName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-table ref="contractFundRef" :border="true" :height="tableHeight" :cell-style="{height:'44px',padding:0,margin:0}" :data="treeTableData"
      :span-method="arraySpanMethod" tooltip-effect="dark" row-key="uid" style="width: 100%;" :highlight-current-row="true" :load="treeTableLoadNode" lazy
      :tree-props="{children: 'children', hasChildren: 'isNode'}" :indent="20" :summary-method="getSummaries" show-summary>
      <el-table-column header-align="center" type="index" width="40">
        <template slot-scope="scope">
          <i v-if="scope.row.track" title="正在跟踪" style="color:#67C23A;font-weight:bold" class="el-icon-s-flag"></i>
          <span v-else>{{ scope.$index+1 }}</span>
        </template>

      </el-table-column>
      <el-table-column header-align="center" prop="projectName" label="所属项目" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" prop="contractCode" label="合同编号" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.dataType=='contract'">{{scope.row.contractCode}}</span>
          <div v-else-if="scope.row.dataType=='contractIn'" style="padding:10px"><el-tag type="success"><i class="el-icon-money" title="收款记录"></i> 收款</el-tag>
            {{scope.row.note}}
          </div>
          <div v-else-if="scope.row.dataType=='clause'" style="padding:10px"><el-tag><i class="el-icon-copy-document" title="收款条件"></i> 条款 </el-tag>
            {{scope.row.note}}</div>
          <div v-else-if="scope.row.dataType=='contractInvoice'" style="padding:10px"><el-tag type="warning"><i class="el-icon-postcard" title="开票记录"></i>
              发票</el-tag>
            {{scope.row.note}}
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="cmpName" label="签约公司" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.dataType=='contract'">
            {{scope.row.cmpName|cmpFilter}}
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="cusOrProxyCmp" label="交易对手(客户|代理公司)" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" align="center" prop="createMan" label="销售" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.dataType=='contract'">{{scope.row.createMan|saleNameFilter}}</span>
        </template>
      </el-table-column>

      <el-table-column header-align="center" prop="signDate" label="签订/发生" width="100"></el-table-column>
      <el-table-column header-align="center" align="right" prop="factRmb" label="合同金额" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.dataType=='contract'">
            <el-tooltip placement="top" :content="scope.row.factRmb+''">
              <el-tag>{{scope.row.factRmb|numFilter}}</el-tag>
            </el-tooltip>
          </div>
          <div v-if="scope.row.dataType=='clause'">
            <el-tooltip placement="top" :content="scope.row.factRmb+''">
              <el-tag>{{scope.row.factRmb|numFilter}}</el-tag>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" prop="inRmb" label="已收款" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.dataType=='contract'">
            <el-tooltip placement="top" :content="scope.row.inRmb+''">
              <el-tag>{{scope.row.inRmb|numFilter}}</el-tag>
            </el-tooltip>
          </div>
          <div v-else-if="scope.row.dataType=='contractIn'">
            <el-tooltip placement="top" :content="scope.row.inRmb+''">
              <el-tag>{{scope.row.inRmb|numFilter}}</el-tag>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" prop="inRmb" label="已开票" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.dataType=='contract'">
            <el-tooltip placement="top" :content="scope.row.mayRmb+''">
              <el-tag>{{scope.row.mayRmb|numFilter}}</el-tag>
            </el-tooltip>
          </div>
          <div v-else-if="scope.row.dataType=='contractInvoice'">
            <el-tooltip placement="top" :content="scope.row.mayRmb+''">
              <el-tag>{{scope.row.mayRmb|numFilter}}</el-tag>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" label="未收款" width="100" prop="factRmb-inRmb">
        <template slot-scope="scope">
          <div v-if="scope.row.dataType=='contract'">
            <el-tooltip placement="top" :content="(scope.row.factRmb-scope.row.inRmb).toFixed(2)+''" v-if="(scope.row.factRmb-scope.row.inRmb)>1">
              <el-tag type='danger'>{{(scope.row.factRmb-scope.row.inRmb)|numFilter}}</el-tag>
            </el-tooltip>
            <span style="color: #67C23A;" v-else>已结清</span>
          </div>
          <div v-else-if="scope.row.dataType=='clause'">
            <el-tag v-if="scope.row.isFinish" type='success' effect="dark">已结清</el-tag>
            <span v-else-if="daysFilter(scope.row.signDate)>7"><el-tag type="danger">逾期 {{daysFilter(scope.row.signDate)}} 天</el-tag></span>
            <span v-else-if="daysFilter(scope.row.signDate)>0"><el-tag type="warning">{{daysFilter(scope.row.signDate)}}天</el-tag></span>
            <span v-else-if="daysFilter(scope.row.signDate)>-7"><el-tag type="success">{{daysFilter(scope.row.signDate)}}天</el-tag></span>
            <span v-else><el-tag type="success">未到期</el-tag></span>
          </div>
        </template>
      </el-table-column>

      <el-table-column header-align="center" label="附件和备注" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.dataType=='contract'">
            <span v-if="scope.row.note">
              <el-tooltip class="item" effect="light" :content="'备注:'+scope.row.note" placement="top-start">
                <i class="el-icon-magic-stick" style="font-size:20px;color:#409EFF"></i>
              </el-tooltip></span>
            <file-view :fileList="scope.row.addFile"> </file-view>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-dialogDrag class="customFileListCss" :title="resultInfoParam.title" :visible.sync="resultInfoParam.visible" :width="resultInfoParam.width"
      :top="resultInfoParam.top" :close-on-click-modal='false'>
      <el-form ref="resultInfoFormRef" :model="resultInfo" :rules="resultInfoRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="当月能收" prop="month">
          <el-input-number v-model="resultInfo.month" controls-position="right" :precision="2" :step="1" :min="0" :max="2147483647.00"
            placeholder="请输入当月能收"></el-input-number>
          {{ resultInfo.month|numFilter}}
        </el-form-item>
        <el-form-item label="次月能收" prop="nextMonth">
          <el-input-number v-model="resultInfo.nextMonth" controls-position="right" :precision="2" :step="1" :min="0" :max="2147483647.00"
            placeholder="请输入次月能收"></el-input-number>
          {{ resultInfo.nextMonth|numFilter}}
        </el-form-item>
        <el-form-item label="反馈信息" prop="result">
          <el-input v-model="resultInfo.result" type="textarea" :rows="5" resize="none" placeholder="请输入销售或责任人反馈回款金额时间等信息"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resultInfoParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="resultInfoSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>
<script> import store from '@/store';
import FileView from '@/components/utils/FileView.vue';
import { getCompanyMain } from '@/api/utils/Company';
import { getAllOnPosEmp } from '@/api/utils/Employee';
import { findTableFund, doModify } from '@/api/wbkj/Contract.js'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { FileView },
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '销售收款提醒'
      },
      /*表格参数 */
      treeTableParam: {
        createId: '',
        year: 0,
        orderBy: 'DESC',
        parentId: 0,
        cmpId: 0,
      },//表格的传参
      /*表格数据 */
      resultInfoParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '反馈',
        formDataAction: '',
        width: '50%',
        top: '5vh',
      },
      resultInfo:
      {
        contractId: 0,
        month: '',
        nextMonth: '',
        invoice: '',
        result: '',
      },
      resultInfoRules:
      {
        month: [required],
        nextMonth: [required],
        invoice: [required],
        result: [required],
      },
      treeTableData: [],
      selectYear: '0',
      years: [],
      allOnPosEmp: [], //所有的在职公司
      mainCmp: [],
      tableHeight: window.innerHeight - 160
    }
  },

  updated () { //为了更新表格的Sum的BUG
    this.$nextTick(() => {
      this.$refs.contractFundRef.doLayout();
    })
  },
  methods: {
    /* eslint-disable */
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (row.dataType != 'contract') {
        if (columnIndex === 1) {
          return [1, 1];
        }
        else if (columnIndex === 2) {
          return [1, 4];
        }
        else if (columnIndex < 5) {
          return [0, 0];
        }
        else {
          return [1, 1];
        }
      }
    },
    /* eslint-enable */
    daysFilter (date1) {
      const date2 = new Date();
      // 计算两个日期之间的日期差异（以毫秒为单位）
      const diffInMs = Date.parse(date2) - Date.parse(date1);
      // 将毫秒数转换为天数并四舍五入
      const diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));
      return diffInDays;
    },
    resultInfoEditor (row) { //修改反馈
      this.resultInfo = JSON.parse(JSON.stringify(row.resultInfo));
      this.resultInfo.contractId = JSON.stringify(row.id);
      this.resultInfoParam.visible = true;

    },
    resultInfoValidate () {
      let result = true;
      this.$refs.resultInfoFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    resultInfoSubmit () {
      if (!this.resultInfoValidate()) return;
      this.resultInfoParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = { id: this.resultInfo.contractId, resultInfo: JSON.stringify(this.resultInfo) };
      doModify(postData).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.treeTableDateInit(0);//重新初使化
        }
        else {
          this.notifyWarning(response.data.msg);
          this.resultInfoParam.visible = true;
        }
      })
    },
    changeOrderBy () {
      if (this.treeTableParam.orderBy == "ASC") {
        this.treeTableParam.orderBy = "DESC";
        this.treeTableDateInit(0);
      }
      else {
        this.treeTableParam.orderBy = "ASC"
        this.treeTableDateInit(0);
      }

    },
    changeSelectYear (selectYear) {
      this.treeTableParam.year = selectYear;
      this.treeTableDateInit(0);
    },
    changeSelectCmpId (selectCreateId) {
      this.treeTableParam.cmpId = selectCreateId;
      this.treeTableDateInit(0);
    },
    changeSelectCreateId (selectCreateId) {
      this.treeTableParam.createId = selectCreateId;
      this.treeTableDateInit(0);
    },
    getSummaries (param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if (index === 1) {
          sums[index] = '合计';
          return;
        }
        else if (index > 6 && index < 11) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = this.numView(sums[index]);

          } else {
            sums[index] = '';
          }
        }
        else if (index === 11) {
          const values1 = data.map(item => Number(item.factRmb));
          const values2 = data.map(item => Number(item.inRmb));
          let total = 0;
          for (let i = 0; i < values1.length; i++) {
            const temp1 = isNaN(values1[i]) ? 0 : Number(values1[i])
            const temp2 = isNaN(values2[i]) ? 0 : Number(values2[i])
            total = total + (temp1 - temp2);
          }
          sums[index] = this.numView(total);
        }
        else if (index === 12) {
          const values1 = data.map(item => Number(item.resultInfo.month));
          let total = 0;
          for (let i = 0; i < values1.length; i++) {
            const temp1 = isNaN(values1[i]) ? 0 : Number(values1[i])
            total = total + temp1;
          }
          sums[index] = this.numView(total);
        }
        else if (index === 13) {
          const values1 = data.map(item => Number(item.resultInfo.nextMonth));
          let total = 0;
          for (let i = 0; i < values1.length; i++) {
            const temp1 = isNaN(values1[i]) ? 0 : Number(values1[i])
            total = total + temp1;
          }
          sums[index] = this.numView(total);
        }
      });
      return sums;
    },

    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    treeTableSelectionChange (val) {
      this.treeTableMultipleSelection = val;
    }
    ,
    /*表格-数据初使化 */
    treeTableDateInit (parentId) {
      if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
        this.treeTableParam.createId = store.getters.getLoginUser().empId;
      }
      this.treeTableParam.parentId = parentId;
      findTableFund(this.treeTableParam).then((response) => {
        if (response.data.success) {
          this.treeTableData = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    //load事件,展开事件调用此方法,当展开时记录当前的结点的resolve,利用Hash的方法记录结点ID,以便新建修改,删除时,刷新父结点
    treeTableLoadNode (tree, treeNode, resolve) {
      // 请求api接口获取数据
      this.treeTableParam.parentId = tree.id;
      findTableFund(this.treeTableParam).then((response) => {
        if (response.data.success) {
          resolve(response.data.data);
        }
        else {
          this.notifyWarning(response.data);
        }
      })

    },
    getYears () {
      const currentYear = new Date().getFullYear()
      this.years.push({ value: '0', key: '全部' });
      for (let year = currentYear + 1; year >= 2000; year--) {

        this.years.push({ value: year.toString(), key: year.toString() + '年' });
      }
    },

  },
  watch: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

    this.direcParam = JSON.parse(store.getters.getActiveCus());

    this.treeTableDateInit(0);
    this.getYears();
    getAllOnPosEmp().then(response => {
      if (response.data.success) {
        this.allOnPosEmp = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getCompanyMain().then(response => {
      if (response.data.success) {
        this.mainCmp = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    this.tableHeight = window.innerHeight;
  }
}
</script>
