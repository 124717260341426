<!--其它费用记录-->
<template>
  <div>
    <el-table :data="this.outArray" style="width: 100%" height="240" size="mini">
      <el-table-column prop="outRmbDate" label="支出日期" width="100">
      </el-table-column>
      <el-table-column prop="outRmbName" label="支出名称" width="100" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="outRmb" label="支出金额" align="right" width="140">
      </el-table-column>
      <el-table-column prop="note" label="备注" align="center">
      </el-table-column>
      <el-table-column label="操作" width="100" v-if="this.allowOperate">
        <template slot="header">
          <el-button type="success" icon="el-icon-plus" size="mini" @click="newContractOut()">支出</el-button>
        </template>
        <template slot-scope="scope">
          <el-button @click="modifyContractOut(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="delContractOut(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-dialogDrag class="customFileListCss" :title="contractOutDialogParam.title" :visible.sync="contractOutDialogParam.visible"
      :width="contractOutDialogParam.width" :top="contractOutDialogParam.top" :close-on-click-modal='false' :append-to-body='true'>
      <el-form ref="contractOutFormRef" :model="contractOutData" :rules="contractOutDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="支出名称" prop="outRmbName">
          <el-input v-model="contractOutData.outRmbName" placeholder="请输入支出名称"></el-input>
        </el-form-item>
        <el-form-item label="支出金额" prop="outRmb">
          <el-input-number v-model="contractOutData.outRmb" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00"
            placeholder="请输入支出金额"></el-input-number><span style="margin-left:20px;">{{ contractOutData.outRmb|numFilter }}</span>
        </el-form-item>
        <el-form-item label="支出日期" prop="outRmbDate">
          <el-date-picker v-model="contractOutData.outRmbDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择到款时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input type="textarea" :rows="2" resize="none" placeholder="请输入备注" v-model="contractOutData.note">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="contractOutDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="contractOutDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script> 
import {
  doContractOutNew, doContractOutModify, doContractOutDelete, getOutByContractId,
} from '@/api/utils/contract/ContractOut'; //数据源,如果没有使用,为了美观请删除
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  props: ["contractData", "contractId", "allowOperate"],
  data () {
    return {
      //-----------------------认证合同支出---------------------------
      contractOutDialogParam: //支出弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        action: '',
        width: '30%',
        top: '5vh',
      },

      contractOutDialogRules:
      {
        outRmbName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        outRmbCondition: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signRmbDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
      },
      contractOutData: {
        proId: 0,
        projectName: '',
        cmpId: 0,
        cmpName: '',
        contractId: 0,
        contractCode: '',
        outRmbName: '',
        outRmb: 0.00,
        outRmbDate: '',
        createId: 0,
        createMan: '',
        createTime: '',
        note: ''
      },
      contractOutRawData: {},
      outArray: []
    };
  },
  methods: {
    //-----------------------认证合同的销售支出功能---------------------------
    newContractOut () { //新建支出
      this.contractOutData = JSON.parse(JSON.stringify(this.contractOutRawData)); //深拷贝一份原始数据到contractOutData,就相当于重置表单了
      this.contractOutData.cmpId = JSON.stringify(this.contractData.cmpId);
      this.contractOutData.cmpName = JSON.parse(JSON.stringify(this.contractData.cmpName));
      this.contractOutData.createId = JSON.stringify(this.contractData.createId);
      this.contractOutData.createMan = JSON.parse(JSON.stringify(this.contractData.createMan));
      this.contractOutData.proId = JSON.stringify(this.contractData.proId);
      this.contractOutData.projectName = JSON.parse(JSON.stringify(this.contractData.projectName));
      this.contractOutData.contractId = JSON.stringify(this.contractData.id);
      this.contractOutData.contractCode = JSON.parse(JSON.stringify(this.contractData.contractCode))
      this.contractOutDialogParam.title = '新建';
      this.contractOutDialogParam.action = 'new';
      this.contractOutDialogParam.visible = true;
    },
    modifyContractOut (row) { //修改支出
      this.contractOutData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到contractOutData,就相当于重置表单了
      this.contractOutDialogParam.title = '修改';
      this.contractOutDialogParam.action = 'modify';
      this.contractOutDialogParam.visible = true;
    },
    delContractOut (row) { //删除支出

      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const postData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到contractOutData,就相当于重置表单了
        doContractOutDelete(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractOutArray(postData.contractId, response.data.data);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
      //
    },
    getContractOutArray (selectContractId, updateData) {
      let param = { contractId: selectContractId }
      getOutByContractId(param).then(response => {
        if (response.data.success) {
          this.outArray = response.data.data;
          if (updateData) { //如果数据不为空,就表示是本身组件的更新,所以更新父组件
            this.$emit('resultUpdate', selectContractId, updateData.contract.outRmb, updateData.project.outRmb);//传入父组件
          }

        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    contractOutDataValidate () { //验证支出
      let result = true;
      this.$refs.contractOutFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    contractOutDataSubmit () {
      if (!this.contractOutDataValidate()) {
        this.notifyError("验证未通过");
        return;
      }

      this.contractOutDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.contractOutData));
      if (this.contractOutDialogParam.action == 'new') {
        doContractOutNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractOutArray(postData.contractId, response.data.data);

          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.contractOutDialogParam.action == 'modify') {
        doContractOutModify(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractOutArray(postData.contractId, response.data.data);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
    },
  },
  watch: {
    contractId: function () {
      this.getContractOutArray(this.contractId);
    }
  },
  mounted () {
    this.getContractOutArray(this.contractId);
  },
}
</script>