<!-- 首页  -->
<template>
  <div>
    <el-row>
      <el-col :span="4">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>载止到{{new Date().toLocaleDateString()}}您已取得</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="2">
        <router-link :to="{ name: 'customer' }">
          <el-button type="primary" size="mini" plain style="line-height:20px;">我的客户{{ this.allCountData.customerNum }}个</el-button>
        </router-link>
      </el-col>
      <el-col :span="2">
        <router-link :to="{ name: 'project' }">
          <el-button type="primary" size="mini" plain style="line-height:20px;">我的项目{{ this.allCountData.projectNum }}个</el-button>
        </router-link>
      </el-col>
      <el-col :span="2">
        <router-link :to="{ name: 'contract' }">
          <el-button type="success" size="mini" plain style="line-height:20px;">认证合同{{ this.allCountData.contractNum }}份</el-button>
        </router-link>
      </el-col>
      <el-col :span="14">&nbsp;</el-col>
    </el-row>
    <sale-count :saleCount="allStatistics"></sale-count>
    <el-row :gutter="20">
      <el-col :span="24/panelNum" v-for="item in panels" :key="'panel'+item.id">
        <el-card class="box-card" body-style="padding:0px">
          <div slot="header" class="clearfix">
            <span>{{item.panelName}}</span>
          </div>
          <div class="text item">
            <ul>
              <li v-for="doc in item.bodyItems" :key="'doc_'+doc.id" style="line-height:30px;">
                <el-link @click="showHtml(doc)">{{doc.title}} </el-link>
                <file-view :fileList="doc.addFile" :key="doc.id" style="float: right ;margin-right: 20px;"></file-view>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog v-dialogDrag class="customFileListCss" :title="this.showDoc.title" :visible.sync="docVisible" width="60%" top="5vh" :close-on-click-modal='true'
      :append-to-body='true'>
      <div v-html="this.showDoc.htmlContent" style="padding-left:30px;padding-right:30px;margin:20px;border: 1px solid #ffffff;background-color:#fff;"></div>
    </el-dialog>
  </div>
</template>
<style>
.my-label {
  background-color: #f56c6c;
  padding: 0 3px;
  color: #fff;
  font-size: 16px;
}
</style>
<style lang="less" scoped>
.text {
  font-size: 14px;
  line-height: 24px;
}

.item {
  margin: 0px;
  padding-top: 0px;
}
</style>
<script>
import FileView from '@/components/utils/FileView.vue';
import store from '@/store';
import { getSaleCount } from '@/api/utils/count/SaleStatistics.js'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import { getMyCountAll, getPanels, getDocList, getDocInfo } from '@/api/wbkj/SaleHome';
import SaleCount from '@/components/count/SaleCount.vue';

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SaleCount, FileView },
  data () {
    return {
      selectYear: '',
      years: [],
      allCountData: {},
      allStatistics: {},
      panels: [],
      panelNum: 1,
      docVisible: false,
      showDoc: { htmlContent: '' }
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},

  //方法集合
  methods: {
    showHtml (doc) {
      getDocInfo(doc.id).then(response => {
        if (response.data.success) {
          console.log(response.data.data);
          this.showDoc = response.data.data;
          this.docVisible = true;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },

    getStatistics () {
      let param = { year: 0, saleId: store.getters.getLoginUser().empId };
      getSaleCount(param).then(response => {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            this.allStatistics = response.data.data[0];
          }
          else {
            this.notifyWarning("选中的" + selectYear + "年份没有数据,请重新选择");
          }

        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });

    },
    changeSelectYear (selectYear) {
      this.getStatistics(selectYear);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getStatistics(this.selectYear);
    getMyCountAll().then(response => {
      if (response.data.success) {
        this.allCountData = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getPanels().then(response => {
      if (response.data.success) {
        this.panels = response.data.data;
        this.panelNum = this.panels.length;
        for (let i = 0; i < this.panels.length; i++) {
          getDocList(this.panels[i].id).then(response => {
            if (response.data.success) {
              this.panels[i].bodyItems = response.data.data;
            }
            else {
              this.panels[i].bodyItems = [];
            }
          });
        }
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });


  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
