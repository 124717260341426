import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Project接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/project/list', { 'params': tableParam })
}
export const findProByCmpId = (tableParam) => {
    return httpJson.get('/project/findByCmpId', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/project/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/project/new', formData)
}
export const doNewContract = (formData) => {
    return httpForm.post('/contract/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/project/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/project/delete', formData)
}
export const doMark = (formData) => {
    return httpForm.post('/project/mark', formData)
}
export const getAllCustomer = (param) => {
    return httpJson.get('/customer/allList', { 'params': param })
}

