
<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="checkCusRight(direcParam.id,'allData')?7:13">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="6" style="text-align: right;line-height:36px;font-weight:bold;">&nbsp;
        {{saleName}}
      </el-col>
      <el-col :span="2" style="text-align: right;line-height:36px;font-weight:bold;">
        统计年份
      </el-col>
      <el-col :span="3">
        <el-select v-model="selectYear" placeholder="选择年份" style="width:100%" @change="changeSelectYear">
          <el-option v-for="item in years" :key="item.value" :label="item.key" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" v-if="checkCusRight(direcParam.id,'allData')">
        <el-select v-model="saleId" filterable placeholder="请选择" style="width:100%;" ref="selectSale" @change="changeProjectSale">
          <el-option label="全部销售人员" :value="0"></el-option>
          <el-option v-for="item in this.allOnPosEmp" :key="'allOnPosEmp'+item.id" :label="item.cmpName+'-'+item.depName+'-'+item.empName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <sale-count v-for="saleData in this.allStatistics" :key="saleData.id" :saleCount="saleData"></sale-count>

  </div>
</template>
<script> 
import store from '@/store';
import { getSaleCount, } from '@/api/utils/count/SaleStatistics.js'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import SaleCount from '@/components/count/SaleCount.vue';
import { getAllOnPosEmp } from '@/api/utils/Employee';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SaleCount },
  data () {
    //这里存放数据
    return {
      direcParam: {
        'directName': '销售人员业绩统计'
      },
      selectYear: 0,
      saleId: 0,
      saleName: '',
      years: [],
      allStatistics: [],
      allOnPosEmp: [], //所有的在职公司
    };
  },
  //监听属性 类似于data概念
  computed: {
  },
  //监控data中的数据变化
  watch: {
  },
  //方法集合
  methods: {
    /*图表引入 */
    changeSelectYear (year) {
      if (year > 20) {
        this.selectYear = year;
      }
      else {
        this.selectYear = 0;
      }
      this.getStatistics();
    },
    changeProjectSale () {
      this.$nextTick(() => {
        this.saleName = this.$refs.selectSale.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
      this.getStatistics();
    },
    getStatistics () {
      let param = { year: JSON.parse(this.selectYear), saleId: JSON.parse(this.saleId) };
      if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
        param.saleId = store.getters.getLoginUser().empId;
      }
      getSaleCount(param).then(response => {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            this.allStatistics = response.data.data;
          }
          else {
            this.notifyWarning("当前条件下" + this.selectYear + ',' + this.saleName + "没有数据,请重新选择");
          }
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    getYears () {
      const currentYear = new Date().getFullYear()
      this.selectYear = currentYear.toString();
      this.years.push({ value: 0, key: '全部' });
      for (let year = currentYear + 1; year >= 2000; year--) {

        this.years.push({ value: year.toString(), key: year.toString() + '年' });
      }
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

    this.direcParam = JSON.parse(store.getters.getActiveCus());

    this.getYears();
    this.getStatistics(this.selectYear);
    let param = {};
    if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
      param.saleId = store.getters.getLoginUser().empId;
    }
    getAllOnPosEmp(param).then(response => {
      if (response.data.success) {
        this.allOnPosEmp = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发

}
</script>