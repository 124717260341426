
<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="5">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="2" style="line-height:38px;text-align: right;">选择项目</el-col>
      <el-col :span="3">
        <el-date-picker v-model="selectProject.selectYear" type="year" value-format="yyyy" placeholder="选择项目年份" style="width:100%" @change="changeProjectYear">
        </el-date-picker>
      </el-col>
      <el-col :span="14">
        <el-select v-model="projectData.id" filterable placeholder="请选择所属项目" style="width:100%;" ref="selectProject" @change="changeProject">
          <el-option v-for="(item,index) in this.allProject" :key="index" :label="item.projectCode+item.markCode+'-'+item.projectName+'|'+item.saleName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-tabs v-model="tabPaneParam.activeName" @tab-click="changeTab()">
      <!-- <link-relation :projectData="projectData"></link-relation> -->
      <el-tab-pane label="项目详情" name="first"></el-tab-pane>
      <el-tab-pane label="项目跟进" name="second"><msg-log :proId="projectData.id" :allowOperate="true"></msg-log></el-tab-pane>
    </el-tabs>

    <project-view :projectData="projectData"></project-view>
    <el-card class="box-card" body-style="padding:0px" style="margin-bottom:20px" v-for="contract in this.allContract" :key="'allContract'+contract.id">
      <el-row :gutter="20" style="padding-bottom:0px;margin-bottom:0px;">
        <el-col :span="10">
          <div style="height:50px;line-height:50px;padding-left:20px;border-bottom: 1px solid #EBEEF5;"><i class="el-icon-notebook-2" style="color:#67C23A"></i>
            认证合同|{{contract.contractCode}}<span style="float:right;margin-right:20px" v-if="checkCusRight(direcParam.id,'contractCheck')">
              <span v-if="contract.resultInfo.checkDate">
                <el-tooltip class="item" effect="light" content="结果已确认,提醒短信和邮件已排期,详情请查看[项目跟进]" placement="top-start">
                  <el-button size="mini" type="success" @click="resultInfoEditor(contract)" icon="el-icon-finished"></el-button>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip class="item" effect="light" content="结果未确认" placement="top-start">
                  <el-button size="mini" type="warning" @click="resultInfoEditor(contract)" icon="el-icon-edit"></el-button>
                </el-tooltip>
              </span>
            </span></div>
          <contract-view :contractData="contract"></contract-view> <!-- 合同看板 -->
        </el-col>
        <el-col :span="14">
          <el-tabs value="pane1" tab-position="top" style="height: 328px; margin-top:10px;">
            <el-tab-pane name="pane1"><span slot="label"><i class="el-icon-data-analysis" style="color:#ef0000;" title="合同统计"> 合同统计</i></span>
              <contract-echart :contractId="contract.id" :ref="'contractEchart'+contract.id"></contract-echart> <!-- 认证合同Echart -->
            </el-tab-pane>
            <el-tab-pane name="pane2"><span slot="label"><i class="el-icon-copy-document" style="color:#409EFF;" title="合同条款"> 合同条款</i></span>
              <contract-clause :contractData="contract" :contractId="contract.id" @resultUpdate="contractClauseUpdate"
                :allowOperate="checkCusRight(direcParam.id,'contractTk')"></contract-clause>
            </el-tab-pane>
            <el-tab-pane name="pane3"><span slot="label"><i class="el-icon-money" style="color:#67C23A;" title="收款记录"> 收款记录</i></span>
              <contract-in :contractData="contract" :contractId="contract.id" @resultUpdate="contractInUpdate"
                :allowOperate="checkCusRight(direcParam.id,'contractSk')"></contract-in>
            </el-tab-pane>
            <el-tab-pane name="pane4"><span slot="label"><i class="el-icon-postcard" style="color:#E6A23C" title="开票记录"> 开票记录</i></span>
              <contract-invoice :contractData="contract" :contractId="contract.id" @resultUpdate="contractInvoiceUpdate"
                :allowOperate="checkCusRight(direcParam.id,'contractKp')"></contract-invoice>
            </el-tab-pane>
            <el-tab-pane name="pane5"><span slot="label"><i class="el-icon-money" style="color:#ef0000;" title="支出记录"> 支出记录</i></span>
              <contract-out :contractData="contract" :contractId="contract.id" @resultUpdate="contractOutUpdate"
                :allowOperate="checkCusRight(direcParam.id,'contractZc')"></contract-out>
            </el-tab-pane>
            <el-tab-pane name="pane6"><span slot="label"><i class="el-icon-phone-outline" style="color:#67C23A" title="跟踪记录"> 跟踪记录</i></span>
              <track-array-field :allowOperate="checkCusRight(direcParam.id,'contractTa')" :jsonArray="contract.trackArray" :upDataId="contract.id"
                @success="upDataContractTrack"></track-array-field>
            </el-tab-pane>
          </el-tabs>
        </el-col>

      </el-row>
    </el-card>

    <cost-out :projectData="projectData" @resultUpdate="costOutResultUpdate" :allowOperate="checkCusRight(direcParam.id,'other')"></cost-out>

    <el-dialog v-dialogDrag top="3vh" :title="pdfViewParam.title" :visible.sync="pdfViewParam.visible" width="80%" :close-on-click-modal='false'>
      <pdf-view :pdfUrl="this.pdfViewParam.pdfUrl" style="height: 800px;"></pdf-view>
    </el-dialog>
    <contract-result :resultInfoParam="resultInfoParam" :resultEdit="resultInfoParam.resultInfo" :empId="resultInfoParam.empId" :proId="resultInfoParam.proId"
      :contractId="resultInfoParam.contractId" @success="contractResultInfoRefresh()"></contract-result>
  </div>
</template>
<script> import store from '@/store';


//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getAllProject, getAllContract, //所有项目,认证合同,采购合同
} from '@/api/utils/ProjectInfo.js'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响

import { findByProId } from '@/api/wbkj/pro/LinkRelation';
import PdfView from '@/components/utils/PdfView.vue';
import CostOut from '@/components/utils/pro/CostOut.vue';
import ProjectView from '@/components/utils/pro/ProjectView.vue';
import MsgLog from '@/components/utils/pro/MsgLog.vue';

import ContractView from '@/components/utils/contract/ContractView.vue';
import ContractEchart from '@/components/utils/contract/ContractEchart.vue';
import ContractClause from '@/components/utils/contract/ContractClause.vue';
import ContractIn from '@/components/utils/contract/ContractIn.vue';
import ContractInvoice from '@/components/utils/contract/ContractInvoice.vue';
import ContractOut from '@/components/utils/contract/ContractOut.vue';
import ContractResult from '@/components/utils/contract/ContractResult.vue';

import TrackArrayField from '@/components/utils/contract/TrackArrayField.vue';

import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
import { doContractTrackArray } from '@/api/wbkj/Contract.js'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    PdfView, ProjectView, MsgLog, ContractView, ContractEchart, ContractClause, ContractIn, ContractOut, ContractInvoice, ContractResult, TrackArrayField, CostOut,
  },
  data () {
    //这里存放数据
    return {
      resultInfoParam: //执行结果信息
      {
        visible: false,
        title: '确定执行结果',
        formDataAction: '',
        width: '50%',
        top: '5vh',
        empId: 0,
        proId: 0,
        contractId: 0,
        resultInfo: {}

      },
      linkProjects: [],
      pdfViewParam: {
        url: '',
        title: '',
        visible: false
      },
      tabPaneParam: {
        activeName: 'first',
        refreshMath: 0,
      },

      direcParam: {
        'directName': '项目详情'
      },
      allProject: [], //所有项目
      allContract: [], //所有认证合同
      allSupply: [], //所有采购合同
      /*初使化数据 */
      selectProject: {
        id: '',
        selectYear: '',
      },
      projectData: {
        id: '',
        projectCode: '未选',
        colorMark: '',
        projectYear: '2022',
        projectName: '未选',
        cmpId: '',
        cmpName: '',
        cusId: '',
        cusName: '',
        saleId: '',
        saleName: '',
        signId: '',
        signName: '',
        signRmb: 0.00,
        factRmb: 0.00,
        createDate: '2023-02-01',
        inRmb: 0.00,
        inContractRmb: 0.00,
        outSupplyRmb: 0.00,
        outRmb: 0.00,
        outFundRmb: 0.00,
        outOtherRmb: 0.00,
        endOfSupply: false,
        isFinish: false,
        sort: 0.00,

      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
  },
  //方法集合
  methods: {
    /*表单(form)-提交 */
    resultInfoEditor (contract) {
      let row = JSON.parse(JSON.stringify(contract));
      this.resultInfoParam.resultInfo = JSON.parse(JSON.stringify(row.resultInfo));
      this.resultInfoParam.resultInfo.contractId = JSON.stringify(row.id);
      this.resultInfoParam.empId = row.createId;
      this.resultInfoParam.proId = row.proId;
      this.resultInfoParam.contractId = row.id;
      this.resultInfoParam.visible = true;
    },
    contractResultInfoRefresh () {
      this.getList(this.projectData.id, this.projectData.linkProId);
    },
    showPdf (title, pdfUrl, visible) {
      this.pdfViewParam.title = title;
      this.pdfViewParam.pdfUrl = pdfUrl + '?downToken=' + store.getters.getToken();
      this.pdfViewParam.visible = visible

    },
    changeTab () {
      this.tabPaneParam.refreshMath = Math.random();
    },
    /*子传父-FoundDay子组件传过来的值 */
    foundDayResultUpdate (totalValue) {
      this.projectData.outFundRmb = totalValue;
    },
    /*子传父-costOut子组件传过来的值 */
    costOutResultUpdate (totalValue) {
      this.projectData.outOtherRmb = totalValue;
    },
    /*子传父-contractClause子组件传过来的值更新的认证合同综合统计*/
    contractClauseUpdate (contractId) {
      this.$refs['contractEchart' + contractId][0].updateEchart();//更新认证合同echart看板
    },




    /*子传父-contractIn子组件传过来的值,更新合同的收款和项目的总收款*/
    contractInUpdate (contractId, contractInRmb, projectInRmb) {
      let updateContract = {};
      updateContract = this.allContract.find(item => item.id == contractId);
      updateContract.inRmb = contractInRmb;
      this.projectData.inRmb = projectInRmb;
      this.$refs['contractEchart' + contractId][0].updateEchart();//更新认证合同echart看板
    },
    /*子传父-contractIn子组件传过来的值,更新合同的支出和项目的支出成本*/
    contractOutUpdate (contractId, contractOutRmb, projectOutRmb) {
      let updateContract = {};
      updateContract = this.allContract.find(item => item.id == contractId);
      updateContract.outRmb = contractOutRmb;
      this.projectData.outRmb = projectOutRmb;
    },
    /*子传父-contractInvoice子组件传过来的值,更新合同的发票*/
    contractInvoiceUpdate (contractId, contractMayRmb) {
      let updateContract = {};
      updateContract = this.allContract.find(item => item.id == contractId);
      updateContract.mayRmb = contractMayRmb;
      this.$refs['contractEchart' + contractId][0].updateEchart();//更新认证合同echart看板
    },
    /*子传父-supplyInfo子组件传过来的值,更新合同的跟踪信息*/
    upDataContractTrack (trackInfo, contractId) {
      const postData = {};
      postData.contractId = contractId;
      postData.trackArray = JSON.stringify(trackInfo);//由于MysqlJson的原因
      doContractTrackArray(postData).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },


    /*图表引入 */
    changeProjectYear (selectYear) {
      this.getProjects(selectYear);
    },
    changeProject (val) {
      let selectProject = this.allProject.find(item => item.id == val);
      this.$router.push({
        path: "/projectInfo",
        query: {
          proId: selectProject.id,
        },
      });
      this.projectData = JSON.parse(JSON.stringify(selectProject));
      this.getList(this.projectData.id, this.projectData.linkProId);
    },
    relationChange (val) { //点击
      if (this.projectData.id != val) {
        let selectProject = this.allProject.find(item => item.id == val);
        this.projectData = JSON.parse(JSON.stringify(selectProject));
        this.$router.push({
          path: "/projectInfo",
          query: {
            proId: selectProject.id,
          },
        });
        this.getList(this.projectData.id, this.projectData.linkProId);
      }
    },
    getProjects (selectYear) {
      let param = { year: selectYear };
      if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
        param.saleId = store.getters.getLoginUser().empId;
      }
      getAllProject(param).then(response => {
        let selectProId = this.$route.query.proId;
        this.projectData.id = selectProId;
        if (response.data.success) {
          this.allProject = response.data.data;
          if (this.projectData.id) {
            let selectProject = {};
            selectProject = this.allProject.find(item => item.id == this.projectData.id);
            this.projectData = JSON.parse(JSON.stringify(selectProject));
          }
          else if (this.allProject.length > 0) {
            this.projectData = JSON.parse(JSON.stringify(this.allProject[0]));
          }
          this.getList(this.projectData.id, this.projectData.linkProId);
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });

    },
    /**得到项目下的所有明细 */
    getList (selectProId, linkProId) {
      let param = { proId: selectProId, sourceProId: linkProId }
      /*得到项目的所有关联项目 */
      findByProId(param).then(response => {
        if (response.data.success) {
          this.linkProjects = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
      /**得到所有的认证合同*/
      getAllContract(param).then(response => {
        if (response.data.success) {
          this.allContract = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.getProjects(0);
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发

}
</script>