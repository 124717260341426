<!--其它费用记录-->
<template>
  <div>
    <el-table :data="this.invoiceArray" style="width: 100%" height="240" size="mini">
      <el-table-column prop="invoiceDate" label="开票日期" width="100">
      </el-table-column>
      <el-table-column prop="invoiceType" label="发票类型" width="200">
        <template slot-scope="scope"><span v-if="scope.row.note">{{scope.row.invoiceType}}({{ scope.row.note }})</span>
          <span v-else>{{scope.row.invoiceType}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceTax" label="发票税率" align="right" width="100">
        <template slot-scope="scope">
          <span style="padding-left: 4px;">
            {{ scope.row.invoiceTax+'%' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceRmb" label="开票金额" align="right" width="140">
      </el-table-column>
      <el-table-column header-align="center" label="附件">
        <template slot-scope="scope">
          <file-view :fileList="scope.row.addFile"></file-view>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" v-if="this.allowOperate">
        <template slot="header">
          <el-button type="warning" icon="el-icon-plus" size="mini" @click="newContractInvoice()">开票</el-button>
        </template>
        <template slot-scope="scope">
          <el-button @click="modifyContractInvoice(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="delContractInvoice(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-dialogDrag class="customFileListCss" :title="contractInvoiceDialogParam.title" :visible.sync="contractInvoiceDialogParam.visible"
      :width="contractInvoiceDialogParam.width" :top="contractInvoiceDialogParam.top" :close-on-click-modal='false' :append-to-body='true'>
      <el-form ref="contractInvoiceFormRef" :model="contractInvoiceData" :rules="contractInvoiceDialogRules" label-width="80px"
        size="small"><!-- `prop` 为绑定的规则 -->
        <el-row style="padding:0px;">
          <el-col :span="12">
            <el-form-item label="发票类型" prop="invoiceType">
              <el-select v-model="contractInvoiceData.invoiceType" placeholder="请选择发票类型">
                <el-option value="普通发票"></el-option>
                <el-option value="增值税专用发票"></el-option>
                <el-option value="无票仅收据"></el-option>
                <el-option value="其它发票"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开票日期" prop="invoiceDate">
              <el-date-picker v-model="contractInvoiceData.invoiceDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择开票日期"
                style="width:100%"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="税率" prop="invoiceTax">
              <el-input-number v-model="contractInvoiceData.invoiceTax" controls-position="right" :precision="0" :step="1" :min="0" :max="45" style="width:100%"
                placeholder="税率1~45"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div style="margin-left:20px;line-height:36px ;">{{ contractInvoiceData.invoiceTax|rateFilter }}</div>
          </el-col>
        </el-row>
        <el-row style="padding:0px;">
          <el-col :span="12">
            <el-form-item label="开票金额" prop="invoiceRmb">
              <el-input-number v-model="contractInvoiceData.invoiceRmb" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00"
                placeholder="请输入开票金额" style="width: 100%;"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12"><span style="margin-left:20px;line-height:36px ;">{{ contractInvoiceData.invoiceRmb|numFilter }}</span>
          </el-col>
        </el-row>
        <el-form-item label="备注" prop="note">
          <el-input type="textarea" :rows="2" resize="none" placeholder="请输入详细备注" v-model="contractInvoiceData.note">
          </el-input>
        </el-form-item>
        <el-form-item label="发票附件" prop="addFile">
          <upload-json-file :inputArray="contractInvoiceData.addFile" label="发票附件" childDir="contractInvoice"
            @success="addFileSuccessUpload"></upload-json-file>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="contractInvoiceDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="contractInvoiceDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag top="3vh" :title="pdfViewParam.title" :visible.sync="pdfViewParam.visible" width="80%" :close-on-click-modal='false' append-to-body>
      <pdf-view :pdfUrl="this.pdfViewParam.pdfUrl" style="height: 800px;"></pdf-view>
    </el-dialog>
  </div>
</template>
<script> 
import store from '@/store';
import FileView from '@/components/utils/FileView.vue';
import UploadJsonFile from '@/components/utils/form/UploadJsonFile.vue';
import {
  doContractInvoiceNew, doContractInvoiceModify, doContractInvoiceDelete, getInvoiceByContractId,
} from '@/api/utils/contract/ContractInvoice'; //数据源,如果没有使用,为了美观请删除
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { FileView, UploadJsonFile },
  props: ["contractData", "contractId", "allowOperate"],
  data () {
    return {
      pdfViewParam: {
        url: '',
        title: '',
        visible: false
      },
      //-----------------------认证合同发票---------------------------
      contractInvoiceDialogParam: //条款弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        action: '',
        width: '30%',
        top: '5vh',
      },

      contractInvoiceDialogRules:
      {
        invoiceType: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        invoiceTax: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        invoiceDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        invoiceRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
      },
      contractInvoiceData: {
        proId: 0,
        projectName: '',
        cmpId: 0,
        cmpName: '',
        contractId: 0,
        contractCode: '',
        invoiceType: '',
        invoiceTax: 0.00,
        invoiceDate: '',
        invoiceRmb: 0.00,
        createId: 0,
        createMan: '',
        createTime: '',
        addFile: [],
        note: ''
      },
      contractInvoiceRawData: {},
      invoiceArray: []
    };
  },
  methods: {
    showPdf (title, pdfUrl, visible) {
      this.pdfViewParam.title = title;
      this.pdfViewParam.pdfUrl = pdfUrl + '?downToken=' + store.getters.getToken();
      this.pdfViewParam.visible = visible
    },
    addFileSuccessUpload (data) { //上传附件成功,更新数据
      this.contractInvoiceData.addFile = data;
    },
    //-----------------------认证合同的发票功能---------------------------
    newContractInvoice () { //新建发票
      this.contractInvoiceData = JSON.parse(JSON.stringify(this.contractInvoiceRawData)); //深拷贝一份原始数据到contractInvoiceData,就相当于重置表单了
      this.contractInvoiceData.cmpId = JSON.stringify(this.contractData.cmpId);
      this.contractInvoiceData.cmpName = JSON.parse(JSON.stringify(this.contractData.cmpName));
      this.contractInvoiceData.createId = JSON.stringify(this.contractData.createId);
      this.contractInvoiceData.createMan = JSON.parse(JSON.stringify(this.contractData.createMan));
      this.contractInvoiceData.proId = JSON.stringify(this.contractData.proId);
      this.contractInvoiceData.projectName = JSON.parse(JSON.stringify(this.contractData.projectName));
      this.contractInvoiceData.contractId = JSON.stringify(this.contractData.id);
      this.contractInvoiceData.contractCode = JSON.parse(JSON.stringify(this.contractData.contractCode));
      this.contractInvoiceDialogParam.title = '新建';
      this.contractInvoiceDialogParam.action = 'new';
      this.contractInvoiceDialogParam.visible = true;
    },
    modifyContractInvoice (row) { //修改发票
      this.contractInvoiceData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到contractInvoiceData,就相当于重置表单了
      this.contractInvoiceDialogParam.title = '修改';
      this.contractInvoiceDialogParam.action = 'modify';
      this.contractInvoiceDialogParam.visible = true;
    },
    delContractInvoice (row) { //删除发票
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const postData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到contractInvoiceData,就相当于重置表单了
        doContractInvoiceDelete(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractInvoiceArray(postData.contractId, response.data.data);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
      //
    },
    getContractInvoiceArray (selectContractId, updateData) {
      let param = { contractId: selectContractId }
      getInvoiceByContractId(param).then(response => {
        if (response.data.success) {
          this.invoiceArray = response.data.data;
          if (updateData) {
            this.$emit('resultUpdate', selectContractId, updateData.contract.mayRmb);//传入父组件
          }
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    contractInvoiceDataValidate () { //验证发票
      let result = true;
      this.$refs.contractInvoiceFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    contractInvoiceDataSubmit () {
      if (!this.contractInvoiceDataValidate()) {
        this.notifyError("验证未通过");
        return;
      }
      this.contractInvoiceDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.contractInvoiceData));
      postData.addFile = JSON.stringify(this.contractInvoiceData.addFile);//由于MysqlJson的原因
      if (this.contractInvoiceDialogParam.action == 'new') {
        doContractInvoiceNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractInvoiceArray(postData.contractId, response.data.data);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.contractInvoiceDialogParam.action == 'modify') {
        doContractInvoiceModify(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractInvoiceArray(postData.contractId, response.data.data);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
    },
  },
  watch: {
    contractId: function () {
      this.getContractInvoiceArray(this.contractId);
    }
  },
  mounted () {
    this.contractInvoiceRawData = JSON.parse(JSON.stringify(this.contractInvoiceData));
    this.getContractInvoiceArray(this.contractId);
  },
}
</script> 