import { httpForm, httpJson } from '@/utils/adminRequest'

export const getTableData = (tableParam) => {
    return httpJson.get('/contract/list', { 'params': tableParam })
}
export const getEmpAndCusInfo = (tableParam) => {
    return httpJson.get('/contract/getEmpAndCusInfo', { 'params': tableParam })
}
export const getMsgModel = () => {
    return httpJson.get('/model/msgModel')
}
export const getEmailModel = () => {
    return httpJson.get('/model/emailModel')
}
export const findTableFund = (tableParam) => {
    return httpJson.get('/contract/findFund', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/contract/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/contract/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/contract/modify', formData)
}
export const doModifyResultInfo = (formData) => {
    return httpForm.post('/contract/modifyResultInfo', formData)
}
export const doContractTrackArray = (formData) => {
    return httpForm.post('/contract/modifyTrackArray', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/contract/delete', formData)
}
export const doMark = (formData) => {
    return httpForm.post('/contract/mark', formData)
}
export const doColorMark = (formData) => {
    return httpForm.post('/contract/colorMark', formData)
}



