import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个ContractOut接口,数据表接口
export const doContractOutNew = (formData) => {
    return httpForm.post('/contractOut/new', formData)
}
export const doContractOutModify = (formData) => {
    return httpForm.post('/contractOut/modify', formData)
}
export const doContractOutDelete = (formData) => {
    return httpForm.post('/contractOut/delete', formData)
}
export const getOutByContractId = (params) => {
    return httpJson.get('/contractOut/allByContractId', { 'params': params })

}


