<!--认证合同Echart-->
<template>
  <div :id="'contract_'+this.contractId" style="width: auto;width:100%,height: 300px"></div>
</template>
<script> 
import { getContractRmbCount } from '@/api/utils/ProjectInfo.js';
export default {
  props: ["contractId"],
  data () {
    return {
      pdfViewParam: {
        url: '',
        title: '',
        visible: false
      },
      myCharts: null,
    };
  },
  methods: {
    updateEchart () {
      let param = { contractId: this.contractId }
      getContractRmbCount(param).then(response => {
        if (response.data.success) {
          this.$nextTick(function () {
            this.echartInitContract('contract_' + this.contractId, response.data.data);
          });
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    echartInitContract (idName, optionData) {
      let countDate = new Array();
      let signRmb = new Array();
      let inRmb = new Array();
      let invoiceRmb = new Array();
      let signTemp = 0, inTemp = 0, invoiceTemp = 0;
      for (let i = 0; i < optionData.length; i++) {
        signTemp = signTemp + optionData[i].signRmb;
        inTemp = inTemp + optionData[i].inRmb;
        invoiceTemp = invoiceTemp + optionData[i].invoiceRmb;
        countDate.push(optionData[i].countDate);
        signRmb.push(signTemp);
        inRmb.push(inTemp);
        invoiceRmb.push(invoiceTemp);
      }

      this.myCharts.setOption({
        title: {
          text: '',
          subtext: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['计划', '收款', '开票']
        },
        toolbox: {
          show: false,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: { readOnly: false },
            magicType: { type: ['line'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: countDate
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }
        },
        series: [

          {
            name: '计划',
            type: 'line',
            color: '#409EFF',
            data: signRmb,
          },
          {
            name: '收款',
            type: 'line',
            color: '#67C23A',
            data: inRmb,
          },
          {
            name: '开票',
            type: 'line',
            color: '#E6A23C',
            data: invoiceRmb,
          },
        ]
      });
    },
  },
  watch: {},
  mounted () {
    Object.defineProperty(document.getElementById('contract_' + this.contractId), 'clientHeight', { get: function () { return 300 } })//el-tab 无法取得,所以改了这个BUG
    this.myCharts = this.$echarts.init(document.getElementById('contract_' + this.contractId));
    let param = { contractId: this.contractId }
    getContractRmbCount(param).then(response => {
      if (response.data.success) {
        this.$nextTick(function () {
          this.echartInitContract('contract_' + this.contractId, response.data.data);
        });
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
  },
}
</script>