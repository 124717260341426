<!--认证合同看板-->
<template>
  <el-form :model="contractData" label-width="70px" size="mini" style="padding:20px">
    <el-form-item label="签约公司">{{ contractData.signName }}</el-form-item>
    <el-form-item label="交易对手">{{ contractData.cusOrProxyCmp }}</el-form-item>
    <el-row :gutter="0" style="padding-bottom:0px;">
      <el-col :span="7"><el-form-item label="签订时间">{{contractData.signDate}}</el-form-item></el-col>
      <el-col :span="8"><el-form-item label="合同金额">{{contractData.factRmb|numFilter}}
          <span style="color:#909399;font-size:10px">&nbsp;{{contractData.factRmb|nullNumFilter}}</span></el-form-item></el-col>
      <el-col :span="9"><el-form-item label="合同支出" style="color:#ef0000">{{contractData.outRmb|numFilter}}
          <span style="color:#909399;font-size:10px">&nbsp;{{contractData.outRmb|nullNumFilter}}</span></el-form-item></el-col>
    </el-row>
    <el-row style="padding-bottom:0px;">
      <el-col :span="6"><el-form-item label="已收款" style="color:#67C23A">
          <el-tooltip :content="contractData.inRmb|nullNumFilter" placement="top" effect="light">
            <span>{{contractData.inRmb|numFilter}}</span>
          </el-tooltip></el-form-item></el-col>
      <el-col :span="6"><el-form-item label="已开票额" style="color:#E6A23C">
          <el-tooltip :content="contractData.mayRmb|nullNumFilter" placement="top" effect="light">
            <span>{{contractData.mayRmb|numFilter}}</span>
          </el-tooltip></el-form-item></el-col>
      <el-col :span="6"><el-form-item label="未收额" style="color:#67C23A">
          <el-tooltip content="结算金额-已收款" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF">&nbsp;</i></el-tooltip>
          <el-tooltip :content="contractData.factRmb-contractData.inRmb|nullNumFilter" placement="top" effect="light">
            <span>{{contractData.factRmb-contractData.inRmb|numFilter}}</span>
          </el-tooltip>
        </el-form-item></el-col>
      <el-col :span="6"><el-form-item label="应收款" style="color:#E6A23C">
          <el-tooltip content="已开票额-已收款" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF">&nbsp;</i></el-tooltip>
          <el-tooltip :content="contractData.mayRmb-contractData.inRmb|nullNumFilter" placement="top" effect="light">
            <span>{{contractData.mayRmb-contractData.inRmb|numFilter}}</span>
          </el-tooltip>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="证书有效" v-if=" contractData.resultInfo.checkDate">
      <el-tag type="success">{{ contractData.resultInfo.startDate|dateFilter }}至{{ contractData.resultInfo.endDate|dateFilter }}</el-tag>&nbsp;
      <el-tag>认证确定:{{ contractData.resultInfo.checkDate|dateFilter }}</el-tag>&nbsp;
      <el-tag type="info">提醒:{{ contractData.resultInfo.nextSendDate|dateTimeFilter }}</el-tag>
    </el-form-item>
    <el-row style="padding-bottom:0px;">
      <el-col :span="12">
        <el-form-item label="审核组长">
          <span v-if="contractData.checkMan">{{contractData.checkMan}}
          </span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="附件备注">
          <span v-if="contractData.note">
            <el-tooltip class="item" effect="light" :content="'备注:'+contractData.note" placement="top-start">
              <i class="el-icon-magic-stick" style="font-size:20px;color:#409EFF"></i>
            </el-tooltip></span>
          <file-view :fileList="contractData.addFile"> </file-view>
        </el-form-item></el-col>
    </el-row>

  </el-form>
</template>
<script> 
import FileView from '@/components/utils/FileView.vue';
export default {
  components: { FileView },
  props: ["contractData"],
  data () {
    return {};
  },
  methods: {
    showSupplyStatus (supplyInfo) {
      let returnValue = true;
      for (let i = 0; i < supplyInfo.length; i++) {
        if (isNaN(supplyInfo[i].status)) {
          returnValue = true;
        }
        else if (supplyInfo[i].status) {
          returnValue = true;
        }
        else {
          return false;
        }
      }
      return returnValue;
    }
  },
  mounted () {
  },
}
</script>