<!--项目客户看板-->
<template>
  <el-card class="box-card" style="margin-bottom: 20px;">
    <div slot="header" class="clearfix">
      <span style="font-weight: bold;"><i class="el-icon-box" style="color:#67C23A"></i> {{project.cusName }} |
        项目:{{project.num }}个 签约总额:{{project.factRmb |numFilter }}
        <span style="color:#909399;font-size:14px">
          &nbsp;{{project.factRmb|nullNumFilter}}
        </span>
      </span>
    </div>
    <div class="text item" style="padding:0px;margin:0px;">
      <el-form :ref="'projectFormRight'+project.id" label-width="100px" size="mini" style="padding:0px;margin:0px;">
        <el-row style="padding:0px">
          <el-col :span="4" style="font-weight: bold;">
            <el-form-item label="合同总额">
              <el-tooltip :content="this.project.inContractRmb|nullNumFilter" placement="top" effect="light">
                <span style="color: #67c23a;">{{ this.project.inContractRmb|numFilter }}</span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="合同收款">
              <el-tooltip :content="this.project.inRmb|nullNumFilter" placement="top" effect="light">
                <i class="el-icon-notebook-2" style="color: #67c23a;"> {{ this.project.inRmb|numFilter }}</i>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="合同未收">
              <el-tooltip :content="(this.project.inContractRmb-this.project.inRmb)|nullNumFilter" placement="top" effect="light">
                <span style="color: #67c23a;">
                  {{ (this.project.inContractRmb-this.project.inRmb)|numFilter }}
                </span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="合同支出" style="font-weight: bold;">
              <el-tooltip :content="this.project.outRmb|nullNumFilter" placement="top" effect="light">
                <span style="color: #F56C6C;">{{ this.project.outRmb|numFilter }}</span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="其它费用" style="font-weight: bold;">
              <el-tooltip :content="this.project.outOtherRmb|nullNumFilter" placement="top" effect="light">
                <span style="color: #F56C6C;">{{ this.project.outOtherRmb|numFilter }}</span>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="客户利润总额" style="font-weight: bold;">
              <el-tooltip content="合同总额-合同支出-其它费用" placement="top" effect="light">
                <i class="el-icon-info" style="color:#409EFF"></i>
              </el-tooltip>
              {{ project.inContractRmb-(project.outRmb+project.outOtherRmb)|numFilter}}
              <span style="color:#909399;font-size:12px">
                &nbsp;{{project.inContractRmb-(project.outOtherRmb)|nullNumFilter}}</span>
            </el-form-item>

          </el-col>
        </el-row>
        <el-row style="margin: 0px;padding:0px;">
          <el-col :span="4"><el-form-item label="合同收款比">{{project.inRmb|numFilter}}/{{project.inContractRmb|numFilter}}</el-form-item>
          </el-col>
          <el-col :span="4" style="padding-right:60px;"><el-progress :text-inside="true" :stroke-width="24" text-color="#fff"
              :percentage="countPercentage(project.inRmb,project.inContractRmb)" :color="colors"></el-progress>
          </el-col>
          <el-col :span="4">
            <el-form-item label="客户当前利润" style="font-weight: bold;">
              <el-tooltip content="合同收款-合同支出-其它费用" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF"></i></el-tooltip>
              {{ this.project.inRmb-(project.outRmb+this.project.outOtherRmb)|numFilter}}
              <span style="color:#909399;font-size:12px">
                &nbsp;{{this.project.inRmb-(project.outRmb+this.project.outOtherRmb)|nullNumFilter}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="padding-right:60px;">&nbsp;
          </el-col>
          <el-col :span="3">&nbsp;
          </el-col>
          <el-col :span="5">
            <el-form-item label="客户利润率" style="font-weight: bold;">
              <el-tooltip content="客户当前利润/客户利润总额" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF"></i></el-tooltip>
              {{ countPercentage(project.inRmb-(project.outRmb+project.outOtherRmb),project.inContractRmb-(project.outRmb+project.outOtherRmb))}}%
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>
  </el-card>
</template>
<script> 
export default {
  props: ["project"],
  data () {
    return {
      colors: [
        { color: '#f56c6c', percentage: 20.0 },
        { color: '#e6a23c', percentage: 40.0 },
        { color: '#5cb87a', percentage: 60.0 },
        { color: '#1989fa', percentage: 80.0 },
        { color: '#6f7ad3', percentage: 100.0 },
      ],
    }
  },
  methods: {},
  mounted () { },
}
</script>