<!--认证合同条款-->
<template>
  <div>
    <el-table :data="this.clauseArray" style="width: 100%" height="240" size="mini">
      <el-table-column prop="signRmbDate" label="应收日期" width="100">
      </el-table-column>
      <el-table-column prop="inRmbName" label="条款名称" width="100" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="inRmbCondition" label="收款条件">
      </el-table-column>
      <el-table-column prop="signRmb" label="应收金额" align="right" width="140"></el-table-column>
      <el-table-column prop="isFinish" label="状态及原因" width="100">
        <template slot-scope="scope">
          <span style="padding-left: 4px;" v-if="scope.row.isFinish">
            <el-tooltip class="item" effect="light" content="收完款" placement="top-start">
              <i class="el-icon-check" style="color: #409EFF;font-weight: 400;"></i>
            </el-tooltip>
          </span>
          <span style="padding-left: 4px;" v-else>
            <el-tooltip class="item" effect="light" content="未收完" placement="top-start">
              <div slot="content" style="line-height:20px">原因:{{scope.row.restReason}}<br />预计收款日期:{{scope.row.restRmbDate}}<br /></div>
              <i class="el-icon-time" style="color:#F56C6C;font-weight: 400;"></i>
            </el-tooltip>
            {{scope.row.restReason}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" v-if="this.allowOperate">
        <template slot="header">
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="newContractClause()">条款</el-button>
        </template>
        <template slot-scope="scope">
          <el-button @click="modifyContractClause(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="delContractClause(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-dialogDrag class="customFileListCss" :title="contractClauseDialogParam.title" :visible.sync="contractClauseDialogParam.visible"
      :width="contractClauseDialogParam.width" :top="contractClauseDialogParam.top" :close-on-click-modal='false' :append-to-body='true'>
      <el-form ref="contractClauseFormRef" :model="contractClauseData" :rules="contractClauseDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="条款名称" prop="inRmbName">
          <el-input v-model="contractClauseData.inRmbName" placeholder="请输入收款名称"></el-input>
        </el-form-item>

        <el-form-item label="收款条件" prop="inRmbCondition">
          <el-input v-model="contractClauseData.inRmbCondition" placeholder="请输入收款条件"></el-input>
        </el-form-item>

        <el-row style="padding:0px;">
          <el-col :span="12">
            <el-form-item label="应收金额" prop="signRmb">
              <el-input-number v-model="contractClauseData.signRmb" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00"
                placeholder="请输入应收金额" style="width:100%;"></el-input-number>
            </el-form-item>
            <el-form-item label="应收日期" prop="signRmbDate">
              <el-date-picker v-model="contractClauseData.signRmbDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择应收日期"
                style="width:100%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12"><span style="margin-left:20px;line-height:36px ;">{{ contractClauseData.signRmb|numFilter }}</span>
          </el-col>
        </el-row>
        <el-form-item label="收完款" prop="isFinish">
          <el-checkbox v-model="contractClauseData.isFinish">是</el-checkbox>
        </el-form-item>
        <el-form-item label="未收原因" prop="restReason">
          <el-input v-model="contractClauseData.restReason" placeholder="请输入未收到款原因"></el-input>
        </el-form-item>
        <el-form-item label="预计日期" prop="restRmbDate">
          <el-date-picker v-model="contractClauseData.restRmbDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择预计收款日期"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="contractClauseDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="contractClauseDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script> 
import {
  doContractClauseNew, doContractClauseModify, doContractClauseDelete, getClauseByContractId,
} from '@/api/utils/contract/ContractClause'; //数据源,如果没有使用,为了美观请删除
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  props: ["contractData", "contractId", "allowOperate"],
  data () {
    return {
      //-----------------------认证合同条款---------------------------
      contractClauseDialogParam: //条款弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        action: '',
        width: '30%',
        top: '5vh',
      },

      contractClauseDialogRules:
      {
        inRmbName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        inRmbCondition: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signRmbDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
      },
      contractClauseData: {
        proId: 0,
        projectName: '',
        cmpId: 0,
        cmpName: '',
        contractId: 0,
        contractCode: '',
        inRmbName: '',
        inRmbCondition: '',
        signRmb: 0.00,
        signRmbDate: '',
        isFinish: false,
        restReason: '',
        restRmbDate: '',
        createId: 0,
        createMan: '',
        createTime: '',
      },
      contractClauseRawData: {},
      clauseArray: []
    };
  },
  methods: {
    //-----------------------认证合同的条款功能---------------------------
    newContractClause () { //新建条款
      this.contractClauseData = JSON.parse(JSON.stringify(this.contractClauseRawData)); //深拷贝一份原始数据到contractClauseData,就相当于重置表单了
      this.contractClauseData.cmpId = JSON.stringify(this.contractData.cmpId);
      this.contractClauseData.cmpName = JSON.parse(JSON.stringify(this.contractData.cmpName));
      this.contractClauseData.createId = JSON.stringify(this.contractData.createId);
      this.contractClauseData.createMan = JSON.parse(JSON.stringify(this.contractData.createMan));
      this.contractClauseData.proId = JSON.stringify(this.contractData.proId);
      this.contractClauseData.projectName = JSON.parse(JSON.stringify(this.contractData.projectName));
      this.contractClauseData.contractId = JSON.parse(JSON.stringify(this.contractData.id));
      this.contractClauseData.contractCode = JSON.parse(JSON.stringify(this.contractData.contractCode));
      this.contractClauseDialogParam.title = '新建';
      this.contractClauseDialogParam.action = 'new';
      this.contractClauseDialogParam.visible = true;
    },
    modifyContractClause (row) { //修改条款
      this.contractClauseData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到contractClauseData,就相当于重置表单了
      this.contractClauseDialogParam.title = '修改';
      this.contractClauseDialogParam.action = 'modify';
      this.contractClauseDialogParam.visible = true;
    },
    delContractClause (row) { //删除条款

      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const postData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到contractClauseData,就相当于重置表单了
        doContractClauseDelete(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractClauseArray(postData.contractId, true); //传入true的主要原因是为了更新统计数据
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
      //
    },
    getContractClauseArray (selectContractId, selfDo) {
      let param = { contractId: selectContractId }
      getClauseByContractId(param).then(response => {
        if (response.data.success) {
          this.clauseArray = response.data.data;
          if (selfDo) { //如果是本身的操作,需要向上传递,更新图表
            this.$emit('resultUpdate', selectContractId);//传入父组件
          }
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    contractClauseDataValidate () { //验证条款
      let result = true;
      this.$refs.contractClauseFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    contractClauseDataSubmit () {
      if (!this.contractClauseDataValidate()) {
        this.notifyError("验证未通过");
        return;
      }

      this.contractClauseDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.contractClauseData));
      if (this.contractClauseDialogParam.action == 'new') {
        doContractClauseNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractClauseArray(postData.contractId, true);//传入true的主要原因是为了更新统计数据
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.contractClauseDialogParam.action == 'modify') {
        doContractClauseModify(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getContractClauseArray(postData.contractId, true);//传入true的主要原因是为了更新统计数据
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
    },
  },
  watch: {
    contractId: function () {
      /**得到所有的认证合同下的条款信息 **/
      this.getContractClauseArray(this.contractId);
    }
  },
  mounted () {
    this.getContractClauseArray(this.contractId);
  },
}
</script>