import { httpForm, httpJson } from '@/utils/adminRequest'
/*认证合同开票,新建修改,删除,更新列表 */

export const doContractInvoiceNew = (formData) => {
  return httpForm.post('/contractInvoice/new', formData)
}
export const doContractInvoiceModify = (formData) => {
  return httpForm.post('/contractInvoice/modify', formData)

}
export const doContractInvoiceDelete = (formData) => {
  return httpForm.post('/contractInvoice/delete', formData)
}
export const getInvoiceByContractId = (params) => {
  return httpJson.get('/contractInvoice/allByContractId', { 'params': params })

}