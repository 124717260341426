<template>
  <div>
    <el-table :data="trackJson" height="250" style="width: 100%" size="mini">
      <el-table-column prop="doTime" label="发生时间" header-align="center" width="180">
      </el-table-column>
      <el-table-column prop="do" label="事件" header-align="center" show-overflow-tooltip align="left">
      </el-table-column>
      <el-table-column prop="result" label="结果" header-align="center" show-overflow-tooltip align="left">
      </el-table-column>
      <el-table-column prop="addFile" label="附件" header-align="center" align="left">
        <template slot-scope="scope">
          <file-view :fileList="scope.row.addFile"> </file-view>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" v-if="this.allowOperate">
        <template slot="header">
          <el-button type="success" icon="el-icon-plus" size="mini" @click="trackJsonNew()">新建</el-button>
        </template>
        <template slot-scope="scope">
          <el-button @click="trackJsonEdit(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="trackJsonDelete(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-dialogDrag :title="trackArrayDialogParam.title" :visible.sync="trackArrayDialogParam.visible" :width="trackArrayDialogParam.width"
      :close-on-click-modal='false' append-to-body>
      <el-form :model="trackJsonData" label-width="100px" size="small" ref="trackJsonForm" :rules="trackJsonDataRules">
        <!-- 这里修改表单项 -->
        <el-form-item label="发生时间" prop="doTime">
          <el-date-picker v-model="trackJsonData.doTime" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择发生时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="事件" prop="do">
          <el-input v-model="trackJsonData.do" type="textarea" :rows="3" resize="none" placeholder="请输入事件,例如电话回访,从何路径得到客户电话,联系客户,回访,写方案等,都可记录"></el-input>
        </el-form-item>
        <el-form-item label="结果" prop="result">
          <el-input v-model="trackJsonData.result" type="textarea" :rows="3" resize="none" placeholder="请输入结果"></el-input>
        </el-form-item>
        <el-form-item label="附件" prop="addFile">
          <upload-json-file :inputArray="trackJsonData.addFile" label="附件" childDir="contract" @success="addFileSuccessUpload"></upload-json-file>
        </el-form-item>
        <!-- 这里修改表单项 -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="trackArrayDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="trackJsonPush()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script> 
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
import UploadJsonFile from '@/components/utils/form/UploadJsonFile.vue';
import FileView from '@/components/utils/FileView.vue';
export default {
  props: ["jsonArray", "upDataId", "allowOperate"],
  components: { FileView, UploadJsonFile },
  data () {
    return {
      trackJson: this.jsonArray,
      upId: this.upDataId,
      /*-------------------------------------------------json trackJson 参数-----------------------------------------------*/
      trackArrayDialogParam: {
        visible: false,
        title: '新建',
        width: '50%',
        top: '15vh',
      },
      /*--json 字段参数--*/
      trackJsonRawData: {
        key: '',
        doTime: '',
        do: '',
        result: '',
        addFile: []
      },
      trackJsonData: {
        key: '',
        doTime: '',
        do: '',
        result: '',
        addFile: []
      },
      trackJsonDataRules:
      {
        key: [required],
        doTime: [required],
        do: [required],
        result: [required],
      },
    };
  },
  methods: {
    /*-------------------------------------------------json trackJson 方法-----------------------------------------------*/
    /**
     * 随机取数
     * @param {*} a 
     * @param {*} b 
     */
    cusRandom (a, b) {
      var n = Math.round(Math.random() * (a - b) + b);
      return n;
    },
    addFileSuccessUpload (data) { //上传附件成功,更新数据
      this.trackJsonData.addFile = data;
    },
    printDate () {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth() > 8 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
      const date = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
      const hours = d.getHours() > 9 ? d.getHours() : "0" + d.getHours();
      const minutes = d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes();
      //const seconds = d.getSeconds() > 9 ? d.getSeconds() : "0" + d.getSeconds();
      return `${year}-${month}-${date} ${hours}:${minutes}`;
    },
    trackJsonNew () {
      this.trackJsonData = JSON.parse(JSON.stringify(this.trackJsonRawData))//重置
      this.trackJsonData.doTime = this.printDate();
      let strData = "";
      //如果觉得12个数太少也可以多放点，将i<4修改即可
      for (var i = 0; i < 12; i++) {
        let num = this.cusRandom(0, 9);                        //数字
        let upper = String.fromCharCode(this.cusRandom(65, 90));     //大写字母
        let lower = String.fromCharCode(this.cusRandom(97, 122));    //小写字母
        strData = strData + num + upper + lower;                  //将所有结果放进strData中
      }
      let str = "";
      for (let i = 0; i < 12; i++) {
        str += strData[this.cusRandom(0, strData.length - 1)];         //在strData里面随机抽取四个数
      }
      this.trackJsonData.key = str; //增加一个UID的标识为了修改
      this.trackArrayDialogParam.title = "新建";
      this.trackArrayDialogParam.visible = true;
    },
    trackJsonPush () {
      this.$refs.trackJsonForm.validate((valid) => {
        if (valid) {
          let index = this.trackJson.findIndex((item) => item.key === this.trackJsonData.key);
          if (index > -1)//表示找到了
          {
            this.trackJson.splice(index, 1, this.trackJsonData);
          }
          else {
            this.trackJson.push(this.trackJsonData);//插到最后面
          }
          this.trackArrayDialogParam.visible = false;//不显示
          this.$emit('success', this.trackJson, this.upId);
        }
        else {
          this.trackArrayDialogParam.visible = true;//不显示
        }
      });
    },
    trackJsonDelete (key) {
      let index = this.trackJson.indexOf(key);//查出文件在哪
      // eslint-disable-next-line vue/no-mutating-props
      this.trackJson.splice(index, 1);
      this.$emit('success', this.trackJson, this.upDataId);
    },
    trackJsonEdit (key) {
      let index = this.trackJson.indexOf(key);//查出文件在哪
      this.trackJsonData = this.trackJson[index];
      if (!this.trackJsonData.addFile) this.trackJsonData.addFile = [];//
      this.trackArrayDialogParam.title = "修改";
      this.trackArrayDialogParam.visible = true;
    },
  },
  watch: {
    jsonArray: function () {
      this.trackJson = this.jsonArray;
      this.upId = this.upDataId;

    }
  },
  mounted () {
  },
}
</script>