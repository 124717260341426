<!--其它费用记录-->
<template>
  <div>
    <el-card class="box-card" body-style="padding:0px" style="margin-bottom:20px">
      <el-row :gutter="20" style="padding-bottom:0px;margin-bottom:0px;">
        <el-col :span="18">
          <el-card shadow="never" body-style="padding:0px;padding-left:10px">
            <div slot="header">
              <span style="margin-bottom: 0px;"><i class="el-icon-wallet" style="color:#F56C6C"></i> 其它费用</span>
            </div>
            <div class="text item">
              <el-table ref="costOutTableRef" style="width: 100%;margin:0px;" height="250" size="mini" :data="costOutArray">
                <el-table-column prop="outRmbDate" label="付费日期" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outRmbType" label="费用类型" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outRmbName" label="费用说明" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outRmbValue" label="费用金额" show-overflow-tooltip></el-table-column>
                <el-table-column prop="addRmb" label="无票加计" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span style="padding-left: 4px;" v-if="scope.row.inVoice">
                      0
                    </span>
                    <span style="padding-left: 4px;" v-else>
                      {{ scope.row.addRmb|nullNumFilter}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="outRmb" label="实付费用" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span style="padding-left: 4px;" v-if="scope.row.isFunAdd">
                      {{ scope.row.outRmb |nullNumFilter}}
                      <el-tooltip content="计入资金占用" placement="top" effect="light"><i class="el-icon-timer" style="color:#F56C6C"></i></el-tooltip>
                    </span>
                    <span style="padding-left: 4px;" v-else>
                      {{ scope.row.outRmb }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100" v-if="this.allowOperate">
                  <template slot="header">
                    <el-button type="primary" icon="el-icon-plus" size="mini" @click="newCostOut()">新增</el-button>
                  </template>
                  <template slot-scope="scope">
                    <el-button @click="modifyCostOut(scope.row)" type="text" size="small">修改</el-button>
                    <el-button @click="delCostOut(scope.row)" type="text" size="small">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6" style="padding:10px;">
          <div id="costOutChart" style="width: auto;height: 320px"></div>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog v-dialogDrag class="customFileListCss" :title="costOutDialogParam.title" :visible.sync="costOutDialogParam.visible"
      :width="costOutDialogParam.width" :top="costOutDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="costOutFormRef" :model="costOutData" :rules="costOutDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="费用类型" prop="outRmbType">
          <el-select v-model="costOutData.outRmbType" placeholder="请选择费用类型">
            <el-option value="税费"></el-option>
            <el-option value="办公费用"></el-option>
            <el-option value="业务招待费"></el-option>
            <el-option value="运维费用"></el-option>
            <el-option value="招投标相关"></el-option>
            <el-option value="其它"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="费用说明" prop="outRmbName">
          <el-input type='textarea' resize="none" v-model="costOutData.outRmbName" placeholder="请输入费用说明"></el-input>
        </el-form-item>
        <el-form-item label="费用金额" prop="outRmbValue">
          <el-input-number v-model="costOutData.outRmbValue" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00"
            @change="changeInput" placeholder="请输入费用金额"></el-input-number><span style="margin-left:20px;">{{ costOutData.outRmbValue|numFilter }}</span>
        </el-form-item>
        <el-form-item label="是否" prop="inVoice">
          <el-checkbox v-model="costOutData.inVoice" @change="changeInput"> 有发票</el-checkbox>
        </el-form-item>
        <el-form-item label="无票加计" prop="addRmb" v-if="!costOutData.inVoice">
          <el-input-number v-model="costOutData.addRmbRate" controls-position="right" :precision="0" :step="1" :min="1" :max="45"
            @change="changeRate"></el-input-number>% =
          <el-input-number v-model="costOutData.addRmb" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00" @change="changeInput"
            placeholder="请输入无票加计金额"></el-input-number><span style="margin-left:20px;">{{ costOutData.addRmb|numFilter }}</span>
        </el-form-item>
        <el-form-item label="实付费用" prop="outRmb">
          <el-input-number v-model="costOutData.outRmb" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00"
            placeholder="请输入费用金额"></el-input-number><span style="margin-left:20px;">{{ costOutData.outRmb|numFilter }}</span>
        </el-form-item>
        <el-form-item label="付费时间" prop="outRmbDate">
          <el-date-picker v-model="costOutData.outRmbDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择付费时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="是否" prop="isFunAdd">
          <el-checkbox v-model="costOutData.isFunAdd">计入资金占用</el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="costOutDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="costOutDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script> 
import {
  doCostOutNewPass, doCostOutModify, doCostOutDelete, getCostOutByProId, //其它费用
} from '@/api/utils/pro/CostOut'; //数据源,如果没有使用,为了美观请删除
import { required, len } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  props: ["projectData", "allowOperate"],
  data () {
    return {
      myCharts: null,
      costOutDialogParam: //其它费用弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        action: '',
        width: '80%',
        top: '5vh',
      },
      costOutDialogRules:
      {
        outRmbType: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        outRmbName: [required, len(100, 1)],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        outRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        outRmbDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
      },
      costOutData: {
        proId: 0,
        projectName: '',
        cmpId: 0,
        cmpName: '',
        outRmbType: '',
        outRmbName: '',
        outRmb: 0.00,
        outRmbDate: new Date().Format('yyyy-MM-dd'),
        outRmbValue: 0.00,
        inVoice: true,
        addRmb: 0.00,
        addRmbRate: 1,
        isFunAdd: false,
        createId: 0,
        createMan: '',
        createTime: new Date().Format('yyyy-MM-dd hh:mm:ss'),
        isBefore: false,
        isPass: false,
        checkMan: '',
        checkTime: new Date().Format('yyyy-MM-dd hh:mm:ss'),
        checkNote: '',
      },
      costOutRawData: {},
      costOutArray: [],
    };
  },
  methods: {
    echartInitCostOut () { //echart图表
      var countData = [];
      let totalRmb = 0;
      for (let i = 0; i < this.costOutArray.length; i++) {

        let temp = countData.find(item => item.name == this.costOutArray[i].outRmbType); //是否存在
        if (typeof (temp) != "undefined") {
          let tempOutRmb = temp.value;
          const index = countData.indexOf(temp); // 移除元素5
          countData.splice(index, 1); //移除元素
          tempOutRmb = tempOutRmb + this.costOutArray[i].outRmb;
          totalRmb = totalRmb + this.costOutArray[i].outRmb;
          let letTemp = { value: tempOutRmb, name: this.costOutArray[i].outRmbType };
          countData.push(letTemp);
        }
        else {
          let letTemp = { value: this.costOutArray[i].outRmb, name: this.costOutArray[i].outRmbType };
          totalRmb = totalRmb + this.costOutArray[i].outRmb;
          countData.push(letTemp);
        }
      }
      let totalRmbStr = this.numView(totalRmb);
      let option = {
        title: {
          text: '费用总计:' + totalRmbStr,
          left: 'center'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        series: [
          {
            name: '费用类型',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: 'bold',
                lineHeight: 24,
                formatter:
                  function (params) {
                    if (parseFloat(params.data.value / 1000).toFixed(2) > 5) {
                      return params.data.name + '\n' + parseFloat(params.data.value / 10000).toFixed(2) + '万元';
                    }
                    else {
                      return params.data.name + '\n' + parseFloat(params.data.value).toFixed(1) + '元';
                    }
                  },
              }

            },
            labelLine: {
              show: false
            },
            data: countData
          }
        ]
      };
      this.myCharts.setOption(option);
      let dataCount = option.series[0].data.length;
      let selectedIndex = 0;
      // 鼠标进入高亮当前，取消其他高亮
      this.myCharts.on("mouseover", (e) => {
        // 根据当前option中data的length，遍历取消其他高亮
        for (let i = 0; i < dataCount; i++) {
          if (i != e.dataIndex) {
            this.myCharts.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: i,
            });
          }
          else {
            selectedIndex = e.dataIndex;
            this.myCharts.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: e.dataIndex,
            });
          }
        }
      });
      this.myCharts.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: selectedIndex,
      });
      //鼠标移出后显示之前默认高亮的那块，其他取消高亮
      this.myCharts.on("mouseout", () => {
        console.log(selectedIndex);

        this.myCharts.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: selectedIndex,
        });
      });

    },
    changeInput () {
      if (this.costOutData.inVoice) {//有票的情况下
        this.costOutData.outRmb = this.costOutData.outRmbValue;
      }
      else { //无票先算利
        this.changeRate();
      }
    },
    changeRate () {
      this.costOutData.addRmb = this.costOutData.outRmbValue * this.costOutData.addRmbRate / 100;
      this.costOutData.outRmb = this.costOutData.outRmbValue + this.costOutData.addRmb;

    },

    //-----------------------其它费用功能---------------------------
    newCostOut () { //新建其它费用
      this.costOutData = JSON.parse(JSON.stringify(this.costOutRawData)); //深拷贝一份原始数据到costOutData,就相当于重置表单了
      this.costOutData.cmpId = JSON.stringify(this.projectData.cmpId);
      this.costOutData.cmpName = JSON.parse(JSON.stringify(this.projectData.cmpName));
      this.costOutData.createId = JSON.stringify(this.projectData.saleId);
      this.costOutData.createMan = JSON.parse(JSON.stringify(this.projectData.saleName));
      this.costOutData.proId = JSON.stringify(this.projectData.id);
      this.costOutData.projectName = JSON.parse(JSON.stringify(this.projectData.projectName));
      this.costOutDialogParam.title = '新建';
      this.costOutDialogParam.action = 'new';
      this.costOutDialogParam.visible = true;
    },
    modifyCostOut (row) { //修改其它费用
      this.costOutData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到costOutData,就相当于重置表单了
      if (!this.costOutData.inVoice) {
        this.costOutData.addRmbRate = this.costOutData.addRmb / this.costOutData.outRmbValue * 100;
      }

      this.costOutDialogParam.title = '修改';
      this.costOutDialogParam.action = 'modify';
      this.costOutDialogParam.visible = true;
    },
    delCostOut (row) { //删除其它费用
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.costOutData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到costOutData,就相当于重置表单了
        doCostOutDelete(this.costOutData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getCostOutArray(this.costOutData.proId, response.data.data);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
      //
    },
    costOutDataValidate () { //验证其它费用
      let result = true;
      this.$refs.costOutFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    costOutDataSubmit () {
      if (!this.costOutDataValidate()) {
        this.notifyError("验证未通过");
        return;
      }
      this.costOutDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.costOutData));
      if (this.costOutDialogParam.action == 'new') {
        doCostOutNewPass(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getCostOutArray(postData.proId, response.data.data);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.costOutDialogParam.action == 'modify') {
        doCostOutModify(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.getCostOutArray(postData.proId, response.data.data);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
    },
    //-----------------------其它费用功能---------------------------
    getCostOutArray (selectProId, updateData) {
      let param = { proId: selectProId }
      getCostOutByProId(param).then(response => {
        if (response.data.success) {
          this.costOutArray = response.data.data;
          this.echartInitCostOut('costOutChart'); //刷新图表;
          this.$emit('resultUpdate', updateData.outOtherRmb);//传入父组件
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
  },
  watch: {
    projectData: function () {

      /**得到其它费用,及图表统计信息 */
      getCostOutByProId({ proId: this.projectData.id }).then(response => {
        if (response.data.success) {
          this.costOutArray = response.data.data;
          this.echartInitCostOut('costOutChart');
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });

    }


  },
  mounted () {
    this.myCharts = this.$echarts.init(document.getElementById('costOutChart'));
    this.costOutRawData = JSON.parse(JSON.stringify(this.costOutData)); //深拷贝一份原始数据到formRawData
  },
}
</script>