<!--其它费用记录-->
<template>
  <div>

    <el-dialog v-dialogDrag class="customFileListCss" :title="resultInfoParam.title" :visible.sync="resultInfoParam.visible" :width="resultInfoParam.width"
      :top="resultInfoParam.top" :close-on-click-modal='false'>

      <el-form ref="resultInfoFormRef" :model="resultInfo" :rules="resultInfoRules" label-width="100px" size="small">

        <el-row :gutter="20" style="padding-bottom: 0px;">
          <el-col :span="12">
            <el-form-item label="证书颁发" prop="startDate">
              <el-date-picker v-model="resultInfo.startDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择证书颁发日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="认证确定" prop="checkDate">
              <el-date-picker v-model="resultInfo.checkDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择认证确定时间"
                @change="changeCheckDate"></el-date-picker>
            </el-form-item>
            <el-form-item label="审核时间" prop="checkProcessor">
              <el-date-picker v-model="resultInfo.checkProcessor" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                @change="changeCheckProcessor">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期至" prop="endDate">
              <el-date-picker v-model="resultInfo.endDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择证书有效期"></el-date-picker>
            </el-form-item>
            <el-form-item label="下一次提醒" prop="nextSendDate">
              <el-date-picker v-model="resultInfo.nextSendDate" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
                placeholder="请选择提醒时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="审核天数" prop="checkDays">
              <el-input-number v-model="resultInfo.checkDays" :precision="1" :step="0.5" :min="0.5"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding-bottom: 0px;">
          <el-col :span="16">
            <el-form-item label="短信提醒">
              <el-tooltip class="item" effect="dark" v-for="(item,index) in this.customerLink" :key="item.key" :content="'客户:'+item.mobile"
                placement="top-start">
                <el-checkbox :label="item.name" v-model="resultInfo.selectLink['selectLink'+(index+1)]" @change="changeLink(item,$event)"></el-checkbox>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" :content="'业务:'+this.employee.empWorkPhone" placement="top-start">
                <el-checkbox :label="this.employee.empName" v-model="resultInfo.selectLink['selectLink'+0]"
                  @change="changeEmpLink(employee,$event)"></el-checkbox>
              </el-tooltip>

            </el-form-item>
          </el-col>
          <el-col :span="8"><el-form-item label="提醒时间">{{ resultInfo.nextSendDate }}</el-form-item></el-col>
        </el-row>
        <el-row style="padding-bottom: 0px;">
          <el-col :span="22">
            <el-form-item label="短信内容" prop="msgNote">
              <el-input type="textarea" :rows="3" resize="none" placeholder="请输入详细备注" v-model="resultInfo.msgNote" readOnly>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2"><el-tooltip content="由于短信接口管控严格,只能按模板方式发送,所以短信不可编辑,敬请知晓." placement="top" effect="light"><i class="el-icon-info"
                style="color:#E6A23C;margin-left:10px;"></i></el-tooltip></el-col>
        </el-row>
        <el-row style="padding-bottom: 0px;">
          <el-col :span="16">
            <el-form-item label="邮件提醒">
              <el-tooltip class="item" effect="dark" v-for="(item,index) in this.customerLink" :key="item.key" :content="'客户:'+item.email"
                placement="top-start">
                <el-checkbox :label="item.name" v-model="resultInfo.selectMail['selectMail'+(index+1)]" @change="changeMail(item,$event)"></el-checkbox>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" :content="'业务:'+this.employee.empEmail" placement="top-start">
                <el-checkbox :label="this.employee.empName" v-model="resultInfo.selectMail['selectMail'+0]"
                  @change="changeEmpMail(employee,$event)"></el-checkbox>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8"><el-form-item label="提醒时间">{{ resultInfo.nextSendDate }}</el-form-item></el-col>
        </el-row>
        <el-form-item label="邮件内容" prop="mailNote">
          <el-input type="textarea" :rows="8" resize="none" placeholder="请输入详细备注" v-model="resultInfo.mailNote">
          </el-input>
        </el-form-item>
        <el-form-item label="项目跟进" prop="isNeed">
          <el-checkbox v-model="resultInfo.isNeed">加入到项目跟进,排期计划通知</el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resultInfoParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="resultInfoSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script> 
import dayjs from 'dayjs';
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
import { doModifyResultInfo, getMsgModel, getEmailModel, getEmpAndCusInfo } from '@/api/wbkj/Contract'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响

export default {
  props: ["resultInfoParam", "resultEdit", "empId", "proId", "contractId"],
  data () {
    return {
      msgModel: '',
      emailModel: '',
      employee: {},
      cusName: '',
      customerLink: {},
      resultRawInfo: {},
      resultInfo: //执行结果信息
      {
        contractId: 0,
        startDate: new Date(),
        endDate: new Date(),//证书有效期 
        checkDate: new Date(),//认证确定 
        nextSendDate: new Date(),//下一次提醒时间
        checkDays: 0.5,
        checkProcessor: '',
        isNeed: true,
        selectLink: {
          selectLink0: false,
          selectLink1: false,
          selectLink2: false,
          selectLink3: false,
          selectLink4: false,
          selectLink5: false,
          selectLink6: false,
          selectLink7: false,
          selectLink8: false,
          selectLink9: false,
        },
        msgNote: '',
        selectMail: {
          selectMail0: false,
          selectMail1: false,
          selectMail2: false,
          selectMail3: false,
          selectMail4: false,
          selectMail5: false,
          selectMail6: false,
          selectMail7: false,
          selectMail8: false,
          selectMail9: false,
        },
        pushData: [],
        mailNote: '',
        result: '',
      },
      resultInfoRules: //执行结果约束
      {
        startDate: [required],
        endDate: [required],
        checkDate: [required],
        nextSendDate: [required],
        checkDays: [required],
        checkProcessor: [required],
      },
    };
  },
  methods: {
    changeCheckDate: function (value) {
      this.resultInfo.nextSendDate = dayjs(value).add(9, 'month').format('YYYY-MM-DD hh:mm');
      this.changeNoteInfo();
    },
    changeCheckProcessor: function (value) {
      const date1 = dayjs(value[0]);
      const date2 = dayjs(value[1]);
      // 计算两个日期之间的天数
      const daysDiff = date2.diff(date1, 'day') + 1;
      this.resultInfo.checkDays = daysDiff;
    },

    changeLink: function (cusLink, isChecked) {
      let cusKey = cusLink.key;
      let noteDate = dayjs(this.resultInfo.checkDate).add(1, 'year').format('YYYY-MM-DD');
      let pushRecord = { key: cusKey, noteType: 'msg', mobile: cusLink.mobile, cusName: this.cusName, checkDate: noteDate, empName: this.employee.empName, empWorkPhone: this.employee.empWorkPhone, noteTime: this.resultInfo.nextSendDate };
      if (isChecked) { //表示选中
        let index = this.resultInfo.pushData.findIndex((item) => item.key === pushRecord.key);
        if (index > -1)//表示找到了
        {
          this.resultInfo.pushData.splice(index, 1, this.pushRecord);
        }
        else {
          this.resultInfo.pushData.push(pushRecord);//插到最后面
        }
      }
      else {
        let index = this.resultInfo.pushData.indexOf(cusKey);//查出文件在哪
        this.resultInfo.pushData.splice(index, 1);
      }
    },
    changeEmpLink: function (emp, isChecked) {
      let empKey = emp.empWorkPhone;
      let noteDate = dayjs(this.resultInfo.checkDate).add(1, 'year').format('YYYY-MM-DD');
      let pushRecord = { key: empKey, noteType: 'msg', mobile: emp.empWorkPhone, cusName: this.cusName, checkDate: noteDate, empName: this.employee.empName, empWorkPhone: this.employee.empWorkPhone, noteTime: this.resultInfo.nextSendDate };
      if (isChecked) { //表示选中
        let index = this.resultInfo.pushData.findIndex((item) => item.key === pushRecord.key);
        if (index > -1)//表示找到了
        {
          this.resultInfo.pushData.splice(index, 1, this.pushRecord);
        }
        else {
          this.resultInfo.pushData.push(pushRecord);//插到最后面
        }
      }
      else {
        let index = this.resultInfo.pushData.indexOf(empKey);//查出文件在哪
        this.resultInfo.pushData.splice(index, 1);
      }
    },
    changeMail: function (cusLink, isChecked) {
      let cusKey = cusLink.key + '_mail';
      let noteDate = dayjs(this.resultInfo.checkDate).add(1, 'year').format('YYYY-MM-DD');
      let pushRecord = { key: cusKey, noteType: 'email', email: cusLink.email, cusName: this.cusName, checkDate: noteDate, empName: this.employee.empName, empWorkPhone: this.employee.empWorkPhone, noteTime: this.resultInfo.nextSendDate };
      if (isChecked) { //表示选中
        let index = this.resultInfo.pushData.findIndex((item) => item.key === pushRecord.key);
        if (index > -1)//表示找到了
        {
          this.resultInfo.pushData.splice(index, 1, this.pushRecord);
        }
        else {
          this.resultInfo.pushData.push(pushRecord);//插到最后面
        }
      }
      else {
        let index = this.resultInfo.pushData.indexOf(cusKey);//查出文件在哪
        this.resultInfo.pushData.splice(index, 1);
      }
    },

    changeEmpMail: function (emp, isChecked) {
      let empKey = emp.empWorkPhone + '_mail';;
      let noteDate = dayjs(this.resultInfo.checkDate).add(1, 'year').format('YYYY-MM-DD');
      let pushRecord = { key: empKey, noteType: 'email', email: emp.empEmail, cusName: this.cusName, checkDate: noteDate, empName: this.employee.empName, empWorkPhone: this.employee.empWorkPhone, noteTime: this.resultInfo.nextSendDate };
      if (isChecked) { //表示选中
        let index = this.resultInfo.pushData.findIndex((item) => item.key === pushRecord.key);
        if (index > -1)//表示找到了
        {
          this.resultInfo.pushData.splice(index, 1, this.pushRecord);
        }
        else {
          this.resultInfo.pushData.push(pushRecord);//插到最后面
        }
      }
      else {
        let index = this.resultInfo.pushData.indexOf(empKey);//查出文件在哪
        this.resultInfo.pushData.splice(index, 1);
      }
    },
    resultInfoValidate () {
      let result = true;
      this.$refs.resultInfoFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    resultInfoSubmit () {
      if (!this.resultInfoValidate()) return;
      this.resultInfoParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = { id: this.contractId, proId: this.proId, resultInfo: JSON.stringify(this.resultInfo) };
      doModifyResultInfo(postData).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.$emit('success', this.resultInfo);
        }
        else {
          this.notifyWarning(response.data.msg);
          this.resultInfoParam.visible = true;
        }
      })
    },
    changeNoteInfo () {
      let noteDate = dayjs(this.resultInfo.checkDate).add(1, 'year').format('YYYY-MM-DD');
      this.resultInfo.msgNote = this.msgModel;
      this.resultInfo.mailNote = this.emailModel;
      this.resultInfo.msgNote = this.resultInfo.msgNote.replaceAll("<empName>", this.employee.empName);
      this.resultInfo.msgNote = this.resultInfo.msgNote.replaceAll("<empWorkPhone>", this.employee.empWorkPhone);
      this.resultInfo.msgNote = this.resultInfo.msgNote.replaceAll("<cusName>", this.cusName);
      this.resultInfo.msgNote = this.resultInfo.msgNote.replaceAll("<checkDate>", noteDate);

      this.resultInfo.mailNote = this.resultInfo.mailNote.replaceAll("<empName>", this.employee.empName);
      this.resultInfo.mailNote = this.resultInfo.mailNote.replaceAll("<empWorkPhone>", this.employee.empWorkPhone);
      this.resultInfo.mailNote = this.resultInfo.mailNote.replaceAll("<cusName>", this.cusName);
      this.resultInfo.mailNote = this.resultInfo.mailNote.replaceAll("<checkDate>", noteDate);
    }

  },

  watch: {
    contractId: function () {
      this.resultInfo.msgNote = this.msgModel;
      this.resultInfo.mailNote = this.emailModel;
      if (this.contractId > 0) {
        /*得到项目的员工 */
        let param = { empId: this.empId, proId: this.proId }
        getEmpAndCusInfo(param).then(response => {
          if (response.data.success) {
            this.employee = response.data.data.emp;
            this.cusName = response.data.data.cus.cmpCnName;
            this.customerLink = response.data.data.cus.linkMan;
            if (JSON.stringify(this.resultEdit).length > 30) {
              console.log(1);
              this.resultInfo = JSON.parse(JSON.stringify(this.resultEdit));
            }
            else {
              console.log(2);
              this.resultInfo = JSON.parse(JSON.stringify(this.resultRawInfo));
              this.resultInfo.contractId = this.contractId;
              this.resultInfo.endDate = dayjs().add(3, 'year').format('YYYY-MM-DD');
              this.resultInfo.nextSendDate = dayjs().add(9, 'month').format('YYYY-MM-DD hh:mm');
              this.changeNoteInfo();
            }
            this.$forceUpdate();

          }
          else {
            this.notifyWarning(response.data.msg);
          }
        });
      }

    }
  },
  mounted () {
    this.resultRawInfo = JSON.parse(JSON.stringify(this.resultInfo));
    this.resultInfo.contractId = this.contractId;
    getMsgModel().then(response => {
      if (response.data.success) {
        this.msgModel = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getEmailModel().then(response => {
      if (response.data.success) {
        this.emailModel = response.data.data;
        //this.changeNoteInfo();
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
  },
}
</script>