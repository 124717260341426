<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="checkCusRight(direcParam.id,'allData')?7:13">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="6">
        <el-date-picker v-model="selectYear" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份"
          @change="tableChangeSearchYear" :picker-options="pickerOptions" format="yyyy-MM-dd" style="width:100%;">
        </el-date-picker>

      </el-col>
      <el-col :span="6" v-if="checkCusRight(direcParam.id,'allData')">
        <el-select v-model="tableParam.createId" filterable placeholder="请选择" style="width:100%;" @change="tableChangeSearchSale">
          <el-option label="全部销售或审核人员" :value="0"></el-option>
          <el-option v-for="item in this.allOnPosEmp" :key="'allOnPosEmp'+item.id" :label="item.cmpName+'-'+item.depName+'-'+item.empName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%;" @change="tableChangeSearchKey" clearable>
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row
      v-if="checkCusRight(direcParam.id,'new')||checkCusRight(direcParam.id,'modify')||checkCusRight(direcParam.id,'checkOk')||checkCusRight(direcParam.id,'delete')||checkCusRight(direcParam.id,'outExcel')||checkCusRight(direcParam.id,'track')">
      <el-col :span="10">
        <el-button-group>
          <el-button @click="formDataNew" type="primary" v-if="checkCusRight(direcParam.id,'new')">新建</el-button>
          <el-button @click="formDataModify" type="primary" v-if="checkCusRight(direcParam.id,'modify')">修改</el-button>
          <el-button @click="formDataDelete" type="primary" v-if="checkCusRight(direcParam.id,'delete')">删除</el-button>
          <el-button @click="resultInfoEditor" type="success" v-if="checkCusRight(direcParam.id,'checkOk')">确定执行结果</el-button>
          <el-button @click="tableOutExcel" type="warning" v-if="checkCusRight(direcParam.id,'outExcel')">导出Excel</el-button>
          <el-button @click="formDataMark" type="primary" v-if="checkCusRight(direcParam.id,'track')">跟踪/取消</el-button>
        </el-button-group>
      </el-col>
      <el-col :span="14">
        <el-tag effect="plain" v-if="this.$store.getters.getContractOperateData.length>0">历史操作</el-tag><el-tag
          v-for="(item,index) in this.$store.getters.getContractOperateData" :key="index" @click="tableChangeSearchKey(item)"
          style="cursor: pointer;margin-left:10px;">{{item}}</el-tag>
      </el-col>

    </el-row>

    <el-table ref="contractTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40">
        <template slot-scope="scope">
          <i v-if="scope.row.track" title="正在跟踪" style="color:#67C23A;font-weight:bold" class="el-icon-s-flag"></i>
          <span v-else>{{ scope.$index+1 }}</span>
        </template>

      </el-table-column>
      <el-table-column header-align="center" prop="projectName" label="所属项目" show-overflow-tooltip>
        <template slot-scope="scope">
          <router-link :to="{name: 'projectInfo', query: {proId:scope.row.proId}}">
            <span v-html="tableLightByKey(scope.row.projectName)"></span></router-link>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="contractCode" label="合同编号" width="260">
        <template slot-scope="scope">

          <el-link :underline="false" v-if="scope.row.colorMark" :style="'padding:3px;color:white;backgroundColor:'+scope.row.colorMark">
            <span v-html="tableLightByKey(scope.row.contractCode)"></span></el-link>
          <el-link :underline="false" v-else>
            <span v-html="tableLightByKey(scope.row.contractCode)"></span></el-link>
          <el-dropdown trigger="click" size="small" @command="formDoColorMark" v-if="checkCusRight(direcParam.id,'mark')">
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{id:scope.row.id,color:'',isMark:false}">默认
                &nbsp;<i v-if="!scope.row.colorMark" class="el-icon-check"></i>
              </el-dropdown-item>
              <el-dropdown-item :command="{id:scope.row.id,color:'#409EFF',isMark:true}">
                <el-tag effect="dark" size="mini">&nbsp;&nbsp;</el-tag>
                &nbsp;<i v-if="scope.row.colorMark=='#409EFF'" class="el-icon-check"></i>
              </el-dropdown-item>
              <el-dropdown-item :command="{id:scope.row.id,color:'#67c23a',isMark:true}">
                <el-tag effect="dark" size="mini" type="success">&nbsp;&nbsp;</el-tag>
                &nbsp;<i v-if="scope.row.colorMark=='#67c23a'" class="el-icon-check"></i>
              </el-dropdown-item>
              <el-dropdown-item :command="{id:scope.row.id,color:'#909399',isMark:true}">
                <el-tag effect="dark" size="mini" type="info">&nbsp;&nbsp;</el-tag>
                &nbsp;<i v-if="scope.row.colorMark=='#909399'" class="el-icon-check"></i>
              </el-dropdown-item>
              <el-dropdown-item :command="{id:scope.row.id,color:'#F56C6C',isMark:true}">
                <el-tag effect="dark" size="mini" type="danger">&nbsp;&nbsp;</el-tag>
                &nbsp;<i v-if="scope.row.colorMark=='#F56C6C'" class="el-icon-check"></i>
              </el-dropdown-item>
              <el-dropdown-item :command="{id:scope.row.id,color:'#e6a23c',isMark:true}">
                <el-tag effect="dark" size="mini" type="warning">&nbsp;&nbsp;</el-tag>
                &nbsp;<i v-if="scope.row.colorMark=='#e6a23c'" class="el-icon-check"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-tag @click="noteTrack(scope.row)" v-if="checkCusRight(direcParam.id,'list')" effect="dark" size="mini"
            style="margin-left: 10px;cursor: pointer;float:right"><i class="el-icon-tickets"></i>
            明细</el-tag>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="signName" label="执行" width="60" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.resultInfo.checkDate">
            <el-tooltip class="item" effect="light" content="结果已确认,提醒短信和邮件已排期" placement="top-start">
              <i class="el-icon-finished" style="font-size:20px;color:#409EFF"></i>
            </el-tooltip>
          </span>
          <span v-else>
            <el-tooltip class="item" effect="light" content="结果未确认" placement="top-start">
              <i class="el-icon-edit" style="font-size:20px;color:#E6A23C"></i>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="signName" label="签约公司" width="120" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top" :content="scope.row.signName"><span>{{scope.row.signName|cmpFilter}}</span></el-tooltip>
        </template>
      </el-table-column>

      <el-table-column header-align="center" prop="cusOrProxyCmp" label="客户|代理公司" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cusOrProxyCmp)"></span>
          <el-tag @click="linkTrack(scope.row)" effect="dark" size="mini" type='success' v-if="checkCusRight(direcParam.id,'trackList')"
            style="margin-left: 10px;cursor: pointer;float:right"><i class="el-icon-phone-outline"></i>
            跟踪</el-tag>
        </template>
      </el-table-column>

      <el-table-column header-align="center" prop="signDate" label="签订日期" width="100"></el-table-column>

      <el-table-column header-align="center" align="right" prop="factRmb" label="合同金额" width="100">
        <template slot-scope="scope">
          <el-tooltip placement="top" :content="scope.row.factRmb+''">
            <el-tag>{{scope.row.factRmb|numFilter}}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="checkMan" label="审核" width="100" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top" :content="scope.row.checkMan" v-if="scope.row.checkMan">
            <el-tag>{{scope.row.checkMan|saleNameFilter}}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" label="未收款" width="100">
        <template slot-scope="scope">
          <el-tooltip placement="top" v-if="(scope.row.factRmb-scope.row.inRmb)>1"
            :content="scope.row.factRmb+'-'+scope.row.inRmb+'='+(scope.row.factRmb-scope.row.inRmb).toFixed(2)+''">
            <el-tag type='danger'>{{(scope.row.factRmb-scope.row.inRmb)|numFilter}}</el-tag>
          </el-tooltip>
          <span style="color: #67C23A;" v-else>已结清</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="right" label="支出" width="100">
        <template slot-scope="scope">
          <el-tooltip placement="top" v-if="(scope.row.outRmb)>1" :content="scope.row.outRmb.toFixed(2)+''">
            <el-tag type='danger'>{{(scope.row.outRmb)|numFilter}}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column header-align="center" label="附件和备注" width="100">
        <template slot-scope="scope">

          <span v-if="scope.row.note">
            <el-tooltip class="item" effect="light" :content="'备注:'+scope.row.note" placement="top-start">
              <i class="el-icon-magic-stick" style="font-size:20px;color:#409EFF"></i>
            </el-tooltip></span>
          <file-view :fileList="scope.row.addFile"> </file-view>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>
    <el-drawer :title="noteTrackParam.title" :visible.sync="noteTrackParam.drawer" :direction="noteTrackParam.direction" size="40%" :before-close="listClose">
      <div style="padding-left:10px">
        <div style="font-size:14px;"><i class="el-icon-copy-document" style="color:#409EFF;" title="收款条件"> 合同条款</i></div>
        <contract-clause :contractData="this.formData" :contractId="this.formData.id" :allowOperate="checkCusRight(direcParam.id,'list')"></contract-clause>
        <div style="margin-top:10px;font-size:14px;"><i class="el-icon-money" style="color:#67C23A;" title="收款记录"> 收款记录</i></div>
        <contract-in :contractData="this.formData" :contractId="this.formData.id" :allowOperate="checkCusRight(direcParam.id,'list')"></contract-in>
        <div style="margin-top:10px;font-size:14px;"><i class="el-icon-postcard" style="color:#E6A23C" title="开票记录"> 开票记录</i></div>
        <contract-invoice :contractData="this.formData" :contractId="formData.id" :allowOperate="checkCusRight(direcParam.id,'list')"></contract-invoice>
        <div style="margin-top:10px;font-size:14px;"><i class="el-icon-postcard" style="color:#ef0000" title="支出记录"> 支出记录</i></div>
        <contract-out :contractData="this.formData" :contractId="formData.id" :allowOperate="checkCusRight(direcParam.id,'checkOut')"></contract-out>
      </div>
    </el-drawer>
    <el-drawer :title="linkTrackParam.title" :visible.sync="linkTrackParam.drawer" :direction="linkTrackParam.direction" :before-close="linkTrackClose"
      size="40%">
      <json-array-track-array :jsonArray="this.trackArray" @success="linkTrackSuccess"></json-array-track-array>
    </el-drawer>
    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>

      <el-form ref="contractFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-row :gutter="20" style="padding-bottom: 0px;">
          <el-col :span="12">
            <el-form-item label="所属项目" prop="proId">
              <el-select v-model="formData.proId" filterable placeholder="请选择所属项目" style="width:100%;" ref="selectSale" @change="changeProject">
                <el-option v-for="item in this.allProject" :key="'allProject'+item.id"
                  :label="item.projectCode+(item.markCode?item.markCode:'')+'-' +item.projectName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签约公司" prop="signName">
              <el-input v-model="formData.signName" placeholder="请输入签约公司"></el-input></el-form-item>
            <el-form-item label="合同编号" prop="contractCode">
              <el-input v-model="formData.contractCode" placeholder="请输入合同编号"></el-input>
            </el-form-item>
            <el-form-item label="签订日期" prop="signDate">
              <el-date-picker v-model="formData.signDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择签订日期"></el-date-picker>
              <span style="margin-left:10px;" v-if="formData.projectYear">项目年份:{{formData.projectYear}}</span>
              <el-tooltip content="合同签订年必须大等于项目的年份" placement="top" effect="light"><i class="el-icon-info"
                  style="color:#409EFF;margin-left:10px;"></i></el-tooltip>
            </el-form-item>
            <el-form-item label="备注" prop="note">
              <el-input type="textarea" :rows="3" resize="none" placeholder="请输入详细备注" v-model="formData.note">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同所属" prop="cmpName">{{formData.cmpName}}
            </el-form-item>
            <el-form-item label="交易对手" prop="cusOrProxyCmp">
              <el-input v-model="formData.cusOrProxyCmp" placeholder="客户方或客户指定的代理公司"></el-input>
            </el-form-item>
            <el-form-item label="合同金额" prop="factRmb">
              <el-row style="padding:0px;">
                <el-col :span="8">
                  <el-input-number v-model="formData.factRmb" controls-position="right" :precision="2" :step="1" :min="0" :max="2147483647.00"
                    placeholder="请输入合同金额"></el-input-number> </el-col>
                <el-col :span="16">
                  {{ formData.factRmb|numFilter }}
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="审核组长" prop="checkId">
              <el-select v-model="formData.checkId" ref="selectCheckMan" filterable placeholder="请选择审核组长" style="width:100%;" @change="changeCheckMan">
                <el-option label="未指定" :value="0"></el-option>
                <el-option v-for="item in this.allOnPosEmp" :key="'allOnPosEmp'+item.id" :label="item.cmpName+'-'+item.depName+'-'+item.empName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="合同附件" prop="addFile">
              <upload-json-file :inputArray="formData.addFile" label="合同附件" childDir="contract" @success="addFileSuccessUpload"></upload-json-file>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="30%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="contractCode" label="选定的合同编号">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
    <contract-result :resultInfoParam="resultInfoParam" :resultEdit="resultInfoParam.resultInfo" :empId="resultInfoParam.empId" :proId="resultInfoParam.proId"
      :contractId="resultInfoParam.contractId" @success="tableDateRefresh()"></contract-result>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style> 
<script>
import store from '@/store';
import saveAs from 'file-saver'
import dayjs from 'dayjs';
import FileView from '@/components/utils/FileView.vue';
import UploadJsonFile from '@/components/utils/form/UploadJsonFile.vue';
import ContractClause from '@/components/utils/contract/ContractClause.vue';
import ContractIn from '@/components/utils/contract/ContractIn.vue';
import ContractOut from '@/components/utils/contract/ContractOut.vue';

import ContractInvoice from '@/components/utils/contract/ContractInvoice.vue';
import ContractResult from '@/components/utils/contract/ContractResult.vue';
import JsonArrayTrackArray from '@/components/utils/contract/JsonArrayTrackArray.vue';
import { getTableData, doNew, doModify, doDelete, doMark, doColorMark } from '@/api/wbkj/Contract'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import { getAllOnPosEmp } from '@/api/utils/Employee';
import { getAllProject } from '@/api/utils/ProjectInfo';
import { required, contractCode, len } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { FileView, UploadJsonFile, ContractClause, ContractIn, ContractOut, ContractInvoice, ContractResult, JsonArrayTrackArray },
  data () {
    let checkSignDate = (rule, value, callback) => {
      if (value.substring(0, 4) >= this.formData.projectYear) {
        callback();
      }
      else {
        callback(new Error('合同签定的年份必须大等于项目年份'));
      }

    };
    return {
      /*导航栏状态 */
      selectYear: '',
      noteTrackParam: {
        title: '',
        drawer: false,
        direction: 'rtl',
      },
      linkTrackParam: {
        title: '',
        drawer: false,
        direction: 'rtl',
      },
      resultInfoParam: //执行结果信息
      {
        visible: false,
        title: '确定执行结果',
        formDataAction: '',
        width: '50%',
        top: '5vh',
        empId: 0,
        proId: 0,
        contractId: 0,
        resultInfo: {}

      },
      trackArray: [],//跟踪信息
      /*导航栏状态 */
      direcParam: {},
      allProject: [],
      allOnPosEmp: [], //所有的在职公司
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal',
        'startDate': '2020-01-01',
        'endDate': '2024-12-31',
        'createId': 0
      },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick (picker) {

            const start = dayjs().set('date', 1);
            const end = dayjs(start).add(1, 'month');
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '今年至今',
          onClick (picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        },
        {
          text: '近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 3);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近六个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            picker.$emit('pick', [start, end]);
          }
        },
        {
          text: '1990至今',
          onClick (picker) {
            const end = new Date();
            const start = dayjs().set('date', 1).set('year', 1990).set('month', 0);
            picker.$emit('pick', [start, end]);
          }
        }

        ]
      },
      //表格的传参
      /*表格数据 */
      tableData: [],
      downToken: '',
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '60%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        proId: '',
        projectName: '',
        projectYear: '',
        contractCode: '',
        cusOrProxyCmp: '',
        cmpId: 0,
        cmpName: '',
        signName: '',
        signRmb: 0.00,
        factRmb: 0.00,
        signDate: new Date(),
        supplyInfo: [],
        mayRmb: 0.00,
        inRmb: 0.00,
        addFile: [],
        createId: 0,
        createMan: '',
        checkId: 0,
        checkMan: '',
        track: false,
        trackArray: [],
        resultInfo: {},
        note: '',

      },

      formDialogRules:
      {
        proId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        projectName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        contractCode: [required, contractCode, len(20, 8)],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        factRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        signDate: [required, { validator: checkSignDate, trigger: 'blur' }],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        mayRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        inRmb: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        createId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        createMan: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {
    changeCheckMan () {
      this.$nextTick(() => {
        this.formData.checkMan = this.$refs.selectCheckMan.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
    },
    noteTrack (row) {
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.noteTrackParam.title = '认证合同编号:' + this.formData.contractCode;
      this.noteTrackParam.drawer = true;
    },
    linkTrack (row) {
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上      
      this.trackArray = JSON.parse(JSON.stringify(row.trackArray));
      this.linkTrackParam.title = '跟踪 认证合同编号:' + this.formData.contractCode;
      this.linkTrackParam.drawer = true;
    },
    listClose () {
      this.noteTrackParam.drawer = false;
      this.tableDateRefresh();
    },
    linkTrackClose () {
      this.linkTrackParam.drawer = false;
      const postData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
      if (JSON.stringify(postData.trackArray) != JSON.stringify(this.trackArray)) { //一样就不需要修改
        this.$set(postData, "trackArray", JSON.stringify(this.trackArray));//把值改成新值
        postData.supplyInfo = JSON.stringify(postData.supplyInfo);//由于MysqlJson的原因
        postData.addFile = JSON.stringify(postData.addFile);//由于MysqlJson的原因
        postData.resultInfo = JSON.stringify(postData.resultInfo);//由于MysqlJson的原因
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.pushOperateData(JSON.parse(JSON.stringify(this.formData.contractCode))); //更新操作
            this.tableDateRefresh();
            this.linkTrackParam.drawer = false;
          }
          else {
            this.linkTrackParam.drawer = true;
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else {
        this.linkTrackParam.drawer = false;
      }


    },
    linkTrackSuccess (data) { //JsonArray,数据回传trackArray成功,更新数据
      this.trackArray = data;
    },
    upDateSupplyInfo (data) {  //上传supplyInfo,更新数据
      this.formData.supplyInfo = data;
    },
    changeProject (val) {

      let selectProject = {};
      selectProject = this.allProject.find(item => item.id == val)
      this.formData.signName = selectProject.cmpName;
      this.formData.projectName = selectProject.projectName;
      this.formData.contractCode = selectProject.projectCode;
      if (selectProject.cusProxyCmp && selectProject.cusProxyCmp.length > 3) {
        this.formData.cusOrProxyCmp = selectProject.cusProxyCmp;
      }
      else {
        this.formData.cusOrProxyCmp = selectProject.cusName;
      }
      this.formData.cmpId = selectProject.cmpId;
      this.formData.cmpName = selectProject.cmpName;
      this.formData.factRmb = selectProject.factRmb;
      this.formData.signRmb = selectProject.signRmb;
      this.formData.createId = selectProject.saleId;
      this.formData.createMan = selectProject.saleName;
      this.formData.projectYear = selectProject.projectYear;
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formData.projectYear = this.formData.signDate.substring(0, 4);
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }
    },
    resultInfoEditor () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能确定执行结果");
      }
      else {
        let row = JSON.parse(JSON.stringify(this.tableMultipleSelection[0]));
        this.resultInfoParam.resultInfo = JSON.parse(JSON.stringify(row.resultInfo));
        this.resultInfoParam.resultInfo.contractId = JSON.stringify(row.id);
        this.resultInfoParam.empId = row.createId;
        this.resultInfoParam.proId = row.proId;
        this.resultInfoParam.contractId = row.id;
        this.resultInfoParam.visible = true;
      }
    },

    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDoColorMark (colorMark) {
      const data = new FormData()
      data.append('id', colorMark.id);
      data.append('isMark', colorMark.isMark);
      data.append('color', colorMark.color);
      doColorMark(data).then(response => {
        if (response.data.success) {
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    formDataMark () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能标记跟踪");
        return;
      }
      else {
        this.formChooseDialogParam.title = "将选定的记录标记为"
        this.formChooseDialogParam.formChooseAction = 'mark';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '取消跟踪';
        this.formChooseDialogParam.action2 = true;
        this.formChooseDialogParam.action2View = '跟 踪';
        this.formChooseDialogParam.visible = true;
      }
    },
    /**历史记录操作功能 */
    pushOperateData (value) {
      const operateData = this.$store.getters.getContractOperateData;
      if (!operateData.includes(value)) {
        if (operateData.length > 2) {
          operateData.splice(0, 1)
        }
        operateData.push(value);
        this.$store.commit("setContractOperateData", operateData);
      }
    },


    /*表单(form)-发布示例 */
    /*formDataPublic () {
        if (this.tableMultipleSelection.length < 1) {
            this.notifyInfo("您必须最少选择一条记录,才能发布");
        }
        else {
            this.formChooseDialogParam.title = "将选定的记录,发布/取消发布"
            this.formChooseDialogParam.formChooseAction = 'public';
            this.formChooseDialogParam.action1 = true;
            this.formChooseDialogParam.action1View = '取消发布';
            this.formChooseDialogParam.action2 = true;
            this.formChooseDialogParam.action2View = '发 布';
            this.formChooseDialogParam.visible = true;
        }
    },*/
    formDataValidate () {
      let result = true;
      this.$refs.contractFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.supplyInfo = JSON.stringify(this.formData.supplyInfo);//由于MysqlJson的原因
      postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      postData.trackArray = JSON.stringify(this.formData.trackArray);//由于MysqlJson的原因
      postData.resultInfo = JSON.stringify(this.formData.resultInfo);//由于MysqlJson的原因
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.pushOperateData(JSON.parse(JSON.stringify(this.formData.contractCode))); //更新操作
            this.tableDateRefresh();
          }
          else {
            this.formDialogParam.visible = true;
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.pushOperateData(JSON.parse(JSON.stringify(this.formData.contractCode))); //更新操作
            this.tableDateRefresh();
          }
          else {
            this.formDialogParam.visible = true;
            this.notifyWarning(response.data.msg);
          }
        })
      }
    },
    formChooseSubmit (isTrueOrFalse) {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        let proId = 0;
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
            proId = this.tableMultipleSelection[i].proId;
          }
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('proId', proId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
      else if (this.formChooseDialogParam.formChooseAction == 'mark') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          canId.push(this.tableMultipleSelection[i].id);
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('track', isTrueOrFalse);
        doMark(data).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.contractTableRef.clearSelection(); //清除选中
      this.$refs.contractTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();
    },
    tableChangeSearchYear (valDate) {
      if (valDate) {
        this.tableParam.startDate = dayjs(valDate[0]).format('YYYY-MM-DD');
        this.tableParam.endDate = dayjs(valDate[1]).format('YYYY-MM-DD');
      }
      else {
        this.tableParam.startDate = '';
        this.tableParam.endDate = '';
      }
      this.tableParam.page = 1;
      this.tableDateRefresh();
    },
    tableChangeSearchSale () {
      this.tableParam.page = 1;
      this.tableDateRefresh();
    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
        this.tableParam.createId = store.getters.getLoginUser().empId;
      }
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
        this.$store.commit("setContractSearchData", this.tableParam);
      })
    },



    /*表格-导出Excel */
    tableOutExcel () {
      if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
        this.tableParam.createId = store.getters.getLoginUser().empId;
      }
      const queryString = Object.keys(this.tableParam)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.tableParam[key]))
        .join('&');
      saveAs('/wbkj/contract/outExcel?downToken=' + this.downToken + '&' + queryString, '认证合同' + new Date().toLocaleDateString() + '.xlsx');

    },
    addFileSuccessUpload (data) { //上传附件成功,更新数据
      this.formData.addFile = data;
    },


  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    let param = {};
    if (!this.checkCusRight(this.direcParam.id, "allData")) { //如果不存在,加入限制条件
      param.saleId = store.getters.getLoginUser().empId;
    }
    getAllProject(param).then(response => {
      if (response.data.success) {
        this.allProject = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getAllOnPosEmp(param).then(response => {
      if (response.data.success) {
        this.allOnPosEmp = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    if (this.$store.getters.getContractSearchData) {
      this.tableParam = this.$store.getters.getContractSearchData;
    }
    this.tableDateRefresh();
    this.downToken = store.getters.getToken();
  }
}
</script>
