<!-- 首页  -->
<template>
  <div>
    <div>
      <el-row>
        <el-col :span="21">
          <h3><i class="el-icon-data-analysis" style="color:#67C23A"></i> 项目概况</h3>
        </el-col>
        <el-col :span="3">
          <el-select placeholder="年份选择" v-model="selectYear" style="width:100%" @change="getCountData">
            <el-option v-for="item in years" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <div>
        </div>
        <el-carousel :interval="10000" height="300px">
          <el-carousel-item v-for="projectInfo in this.allProject" :key="'project_'+projectInfo.id" style="padding:0px;">
            <project-view :projectData="projectInfo" :allowRoute="true"></project-view>
          </el-carousel-item>
        </el-carousel>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <dir-info></dir-info>
        </el-col>
        <el-col :span="8">
          <java-info></java-info>
        </el-col>
        <el-col :span="8">
          <cer-info></cer-info>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<style>
.my-label {
  background-color: #f56c6c;
  padding: 0 3px;
  color: #fff;
  font-size: 16px;
}
</style>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script> 
import store from '@/store';
import ProjectView from '@/components/utils/pro/ProjectView.vue';
import JavaInfo from '@/components/utils/JavaInfo.vue';
import CerInfo from '@/components/utils/CerInfo.vue';
import DiskInfo from '@/components/utils/DiskInfo.vue';
import DirInfo from '@/components/utils/DirInfo.vue';
import { getOwnerProject } from '@/api/utils/ProjectInfo';
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { ProjectView, DiskInfo, CerInfo, JavaInfo, DirInfo },
  data () {
    //这里存放数据
    return {
      selectYear: '2023',
      years: [],
      allProject: [], //项目数据
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},

  //方法集合
  methods: {
    /*特殊事件-下载文件 */
    getCountData () {
      let param = { year: this.selectYear };
      let clockIndexId = 52;//后端已锁定是的自定义项目模块
      if (!this.checkCusRight(clockIndexId, "allData")) { //如果不存在,加入限制条件
        param.saleId = store.getters.getLoginUser().empId;
      }
      getOwnerProject(param).then(response => {
        if (response.data.success) {
          this.allProject = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    getYears () {
      const currentYear = new Date().getFullYear()
      this.selectYear = currentYear.toString();
      this.years.push({ value: '0', label: '全部' });
      for (let year = currentYear + 1; year >= 2000; year--) {
        this.years.push({ value: year.toString(), label: year.toString() + '年' });
      }
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getYears();
    this.getCountData();
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
