import { httpForm, httpJson } from '@/utils/adminRequest'
/*认证合同收款,新建修改,删除,更新列表 */
export const doContractInNew = (formData) => {
  return httpForm.post('/contractIn/new', formData)
}
export const doContractInModify = (formData) => {
  return httpForm.post('/contractIn/modify', formData)
}
export const doContractInDelete = (formData) => {
  return httpForm.post('/contractIn/delete', formData)
}
export const getInByContractId = (params) => {
  return httpJson.get('/contractIn/allByContractId', { 'params': params })
}