import { httpForm, httpJson } from '@/utils/adminRequest'
export const doCostOutNewPass = (formData) => {
  return httpForm.post('/costOut/newPass', formData)
}
export const doCostOutModify = (formData) => {
  return httpForm.post('/costOut/modify', formData)
}
export const doCostOutDelete = (formData) => {
  return httpForm.post('/costOut/delete', formData)
}
export const getCostOutByProId = (params) => {
  return httpJson.get('/costOut/byProId', { 'params': params })
}
