import { httpForm, httpJson } from '@/utils/adminRequest'

/*认证合同条款,新建修改,删除 */
export const doContractClauseNew = (formData) => {
  return httpForm.post('/contractClause/new', formData)
}
export const doContractClauseModify = (formData) => {

  return httpForm.post('/contractClause/modify', formData)
}
export const doContractClauseDelete = (formData) => {

  return httpForm.post('/contractClause/delete', formData)
}
export const getClauseByContractId = (params) => {

  return httpJson.get('/contractClause/allByContractId', { 'params': params })

}